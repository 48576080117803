import React, { Component } from "react";
import store from "store/store";
import queryString from "query-string";
import DashboardBoxes from "components/common/dashboard-boxes";
import CurrencyFormat from "utils/currencyFormat";
import Pagination from "components/common/pagination";
import Select from "react-select";
import pluralize from "utils/pluralize";
import Dropdown from "utils/dropdown";
import { MonthName, DateTimeFormat } from "utils/dateFormat";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Download from "components/common/download";
import SearchFilter from "components/common/filter";
import { connect } from "react-redux";
import { customizeSVG } from "utils/customizeSVG.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import { ReactComponent as IconWallet } from "assets/images/icons/project-icons/Wallet.svg";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import * as withdrawals from "store/entities/withdrawals/action";
import * as employers from "store/entities/employers/action";
import * as integrations from "store/entities/integrations/action";
import TransactionsPageRouter from "components/__new/common/TransactionsPageRouter";

const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);

const columns = [
	{ label: "Date Withdrawn", key: "date" },
	{ label: "Date Approved", key: "updatedAt" },
	{ label: "First Name", key: "employeeId.firstName" },
	{ label: "Last Name", key: "employeeId.lastName" },
	{ label: "Job Title", key: "employeeId.jobTitle" },
	{ label: "Email Address", key: "employeeId.email" },
	{ label: "Phone Number", key: "employeeId.phoneNumber" },
	{ label: "Employer", key: "companyInfo.companyName" },
	{ label: "Bank Name", key: "employeeId.bankName" },
	{ label: "Recipient ID", key: "employeeId.recipientId" },
	{ label: "Account Name", key: "employeeId.bankAccountName" },
	{ label: "Account Number", key: "employeeId.bankAccountNumber" },
	{ label: "Amount Aggregated", key: "payBack" },
	{ label: "Amount Requested", key: "amountRequested" },
	{ label: "Actual Paid", key: "actualPaid" },
	{ label: "Fee", key: "fee" },
	{ label: "Status", key: "status" },
	{ label: "Platform", key: "platform" },
];

class List extends Component {
	constructor(props) {
		super(props);
		this.csvLinkEl = React.createRef();
		this.completeButtonRef = React.createRef(null);
	}

	state = {
		getAllWithdrawalsParams: {
			page: 1,
			limit: 20,
			status: "",
			year: "",
			month: "",
			download: false,
			employerId: "",
		},
		filter: {
			monthSelect: "",
			showMonthSelect: false,
		},
		dashboardProps1: {
			iconBg: "bg-blue-200",
			icon: (
				<IconWallet className="customizeSVG text-blue-600 m-auto w-7 h-7" />
			),
			titleText: "Total Withdrawals",
			mainText: "-",
		},
		dashboardProps2: {
			iconBg: "bg-gray-200",
			icon: (
				<IconWallet className="customizeSVG text-gray-600 m-auto w-7 h-7" />
			),
			titleText: "Withdrawals Count",
			mainText: "-",
		},
		dashboardProps5: {
			iconBg: "bg-red-200",
			icon: <IconWallet className="customizeSVG text-red-600 m-auto w-7 h-7" />,
			titleText: "Paystack Balance",
			mainText: "-",
		},
		dataDownload: [],
		storeUnsubscribe: "",
		fileName: "All Withdrawals.csv",
		toggleModalDetails: false,
		withdrawalsDetails: "",
		employerFilterOptions: [],
		employerFilter: "",
	};

	formChange = (e) => {
		const formValue = { ...this.state.filter };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ filter: formValue });
	};

	getAllEmployers = () => {
		const getAllEmployersParams = {
			page: 1,
			limit: 1000,
			download: false,
			status: "",
			name: "",
			keyword: "",
			registeredFrom: "",
			registeredTo: "",
			features: "",
			disabled: ""
		};
		this.props.getAllEmployers(getAllEmployersParams);
	};

	employerFilterValue = (employerId) => {
		// console.log(employerId);
		this.setState({ employerFilter: employerId });
		this.gotoUrlQuery({ employerId: employerId.value });
	};

	clearEmployerFilterOptions = () => {
		this.setState({ employerFilter: "" });
		this.gotoUrlQuery({ employerId: "" });
	};

	selectEmployerFilter = () => {
		const qs = queryString.parse(this.props.location.search);
		const { employerFilterOptions } = this.state;
		let employerName = "";

		if (
			typeof qs.employerId !== undefined &&
			qs.employerId !== undefined &&
			qs.employerId !== null
		) {
			let employerFilter = { value: qs.employerId, label: "" };
			// console.log(employerFilterOptions);

			if (Object.keys(employerFilterOptions).length > 0) {
				Object.keys(employerFilterOptions).forEach((employer) => {
					if (employerFilterOptions[employer].value === qs.employerId) {
						employerName = employerFilterOptions[employer].label;
					}
				});

				if (employerName !== "") {
					employerFilter = { ...employerFilter, label: employerName };
				} else {
					toast.error("Invalid employee firm selected");
				}
			}
			this.setState({ employerFilter });
		}
	};

	getParams = () => {
		const qs = queryString.parse(this.props.location.search);
		let { getAllWithdrawalsParams } = this.state;
		let month = "";
		let year = "";

		if (
			typeof qs.month !== undefined &&
			qs.month !== undefined &&
			qs.month !== null
		) {
			month = qs.month;
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, month: qs.month };
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, month: "" };
		}

		if (
			typeof qs.year !== undefined &&
			qs.year !== undefined &&
			qs.year !== null
		) {
			year = qs.year;
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, year: qs.year };
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, year: "" };
		}

		if (
			typeof qs.employerId !== undefined &&
			qs.employerId !== undefined &&
			qs.employerId !== null
		) {
			getAllWithdrawalsParams = {
				...getAllWithdrawalsParams,
				employerId: qs.employerId,
			};
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, employerId: "" };
		}

		if (qs.status !== undefined && qs.status !== null) {
			getAllWithdrawalsParams = {
				...getAllWithdrawalsParams,
				status: qs.status,
			};
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, status: "" };
		}

		if (qs.from !== undefined && qs.from !== null) {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, from: qs.from };
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, from: "" };
		}

		if (qs.to !== undefined && qs.to !== null) {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, to: qs.to };
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, to: "" };
		}

		if (qs.salaryFrom !== undefined && qs.salaryFrom !== null) {
			getAllWithdrawalsParams = {
				...getAllWithdrawalsParams,
				salaryFrom: parseInt(qs.salaryFrom),
			};
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, salaryFrom: "" };
		}

		if (qs.salaryTo !== undefined && qs.salaryTo !== null) {
			getAllWithdrawalsParams = {
				...getAllWithdrawalsParams,
				salaryTo: parseInt(qs.salaryTo),
			};
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, salaryTo: "" };
		}

		if (qs.platform !== undefined && qs.platform !== null) {
			getAllWithdrawalsParams = {
				...getAllWithdrawalsParams,
				platform: qs.platform,
			};
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, platform: "" };
		}

		if (qs.page !== undefined && !isNaN(qs.page) && qs.page > 0) {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, page: qs.page };
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, page: 1 };
		}

		if (qs.limit !== undefined && !isNaN(qs.limit) && qs.limit > 0) {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, limit: qs.limit };
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, limit: 20 };
		}

		if(qs?.search?.length > 0) {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, search: qs.search };
		} else {
			getAllWithdrawalsParams = { ...getAllWithdrawalsParams, search: "" };
		}

		return { getAllWithdrawalsParams, month, year };
	};

	getAllWithdrawals = () => {
		let { dashboardProps1, dashboardProps2, dashboardProps3, dashboardProps4 } =
			this.state;
		let { getAllWithdrawalsParams, month, year } = this.getParams();

		const monthYearDisplay = month !== "" ? month + ", " + year : "";
		dashboardProps1 = {
			...dashboardProps1,
			subText2: (
				<span className="text-ep-blue capitalize">{monthYearDisplay}</span>
			),
		};
		dashboardProps2 = {
			...dashboardProps2,
			subText2: (
				<span className="text-ep-blue capitalize">{monthYearDisplay}</span>
			),
		};
		dashboardProps3 = {
			...dashboardProps3,
			subText2: (
				<span className="text-ep-blue capitalize">{monthYearDisplay}</span>
			),
		};
		dashboardProps4 = {
			...dashboardProps4,
			subText2: (
				<span className="text-ep-blue capitalize">{monthYearDisplay}</span>
			),
		};

		this.setState({
			getAllWithdrawalsParams,
			dashboardProps1,
			dashboardProps2,
			dashboardProps3,
			dashboardProps4,
		});
		this.props.getAllWithdrawals(getAllWithdrawalsParams);
	};

	getAllWithdrawalsCount = () => {
		let { getAllWithdrawalsParams } = this.getParams();
		this.props.getAllWithdrawalsCount(getAllWithdrawalsParams);
	};

	getDownloadFilename = () => {
		let fileName = "All Withdrawals.csv";
		const qs = queryString.parse(this.props.location.search);
		const allFileNames = {
			paid: "Paid Withdrawals.csv",
			pending: "Pending Withdrawals.csv",
		};
		if (
			qs.status !== undefined &&
			qs.status !== null &&
			allFileNames[qs.status]
		) {
			fileName = allFileNames[qs.status];
		}
		this.setState({ fileName });
	};

	viewDetails = (withdrawal = {}) => {
		// console.log(withdrawal);
		const toggleModalDetails = this.state.toggleModalDetails;
		this.setState({
			toggleModalDetails: !toggleModalDetails,
			withdrawalsDetails: withdrawal,
		});
	};

	closeModal = (action) => {
		this.setState({ toggleModalDetails: action });
	};

	retryWithdrawal = (withdrawal_id) => {
		this.props.retryWithdrawal(withdrawal_id);
		this.setState({ toggleModalDetails: false });
	};

	showMonthSelect = () => {
		const filter = {
			...this.state.filter,
			showMonthSelect: !this.state.filter.showMonthSelect,
		};
		this.setState({ filter });
	};

	toggleMonthSelect = (e) => {
		e.preventDefault();
		const { monthSelect } = this.state.filter;
		if (monthSelect !== "") {
			let d = new Date(monthSelect),
				month = "" + (d.getMonth() + 1),
				year = "" + d.getFullYear();
			month = MonthName(month);
			const filter = { ...this.state.filter, showMonthSelect: false };
			this.setState({ filter });
			this.gotoUrlQuery({ month: month, year: year });
		}
	};

	gotoUrlQuery = (params) => {
		const qs = queryString.parse(this.props.location.search);

		Object.keys(params).forEach((paramName) => {
			// console.log("qs", qs[paramName]);
			delete qs[paramName];
		});

		let qsToUrl = new URLSearchParams(qs).toString();
		qsToUrl =
			this.props.location.pathname +
			"?" +
			(qsToUrl !== "" ? qsToUrl + "&" : "");

		Object.keys(params).forEach((paramName) => {
			if (params[paramName] !== "") {
				qsToUrl += paramName + "=" + params[paramName] + "&";
			}
		});

		qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
		this.props.history.push(qsToUrl);
	};

	componentDidMount() {
		this.getAllWithdrawals();
		this.getAllWithdrawalsCount();
		this.getDownloadFilename();
		this.props.getPaystackBalance();
		this.selectEmployerFilter();
		this.getAllEmployers();

		this.unsubscribe = store.subscribe(() => {
			if (this.props.allEmployersLoading === false) {
				this.unsubscribe();
				const allEmployers = this.props.allEmployers?.data?.employers;
				let employerFilterOptions = [];
				if (allEmployers !== undefined && allEmployers.length !== 0) {
					Object.keys(allEmployers).forEach(function (key) {
						if(allEmployers[key].company) {
							employerFilterOptions.push({
								value: allEmployers[key]._id,
								label: allEmployers[key].company.companyName,
							});
						}
					});
				}
				this.setState({ employerFilterOptions });
				this.selectEmployerFilter();
			}
		});
		this.setState({ storeUnsubscribe: this.unsubscribe });
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.getAllWithdrawals();
			this.getAllWithdrawalsCount();
			this.getDownloadFilename();
			this.selectEmployerFilter();
		}
		if (this.props.reloadPage === true) {
			setTimeout(() => {
				this.props.resetReloadPage();
				this.props.history.replace(
					this.props.location.pathname + this.props.location.search
				);
			}, 1000);
		}
	}

	componentWillUnmount() {
		const storeUnsubscribe = this.state.storeUnsubscribe;
		if (
			storeUnsubscribe !== "" &&
			storeUnsubscribe !== undefined &&
			storeUnsubscribe !== null
		) {
			storeUnsubscribe();
		}
	}

	render() {
		customizeSVG();
		const {
			allWithdrawals: data,
			allEmployersLoading,
			withdrawalsCountLoading,
			withdrawalsCount,
			loadingPaystackBalance,
			paystackBalance,
		} = this.props;
		const {
			getAllWithdrawalsParams,
			fileName,
			employerFilter,
			employerFilterOptions,
			dashboardProps1,
			dashboardProps2,
			dashboardProps5,
			toggleModalDetails,
			withdrawalsDetails,
		} = this.state;

		const displayReasonText = (number, customReason) => {
			let text = "Others";
			if (customReason) return customReason;
			else if (number !== null) {
				switch (number) {
					case 1:
						text = "Bills";
						break;
					case 2:
						text = "Charity";
						break;
					case 3:
						text = "Clothing";
						break;
					case 4:
						text = "Education";
						break;
					case 5:
						text = "Entertainment";
						break;
					case 6:
						text = "Food";
						break;
					case 7:
						text = "Friends and Family";
						break;
					case 8:
						text = "Groceries";
						break;
					case 9:
						text = "Loans";
						break;
					case 10:
						text = "Transportation";
						break;
					case 11:
						text = "Utilities";
						break;
					case 12:
						text = "Others";
						break;

					default:
						return text;
				}
			} else {
				return "Others";
			}

			return text;
		};

		const getFullName = (withdrawal) => {
			if(withdrawal.employeeId) {
				if(withdrawal?.employeeId?.firstName?.length > 0 &&
					withdrawal?.employeeId?.lastName?.length > 0) {
					return `${withdrawal?.employeeId?.firstName} ${withdrawal?.employeeId?.lastName}`
				} else if (!withdrawal?.employeeId?.firstName?.length &&
					!withdrawal?.employeeId?.lastName?.length) {
						return withdrawal?.employeeId?.fullName
					}
			} else {
				return ""
			}
		}

		return (
			<div>
				<div
					className={
						"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
						(toggleModalDetails ? "right-0" : "-right-full")
					}>
					<div className="w-full h-full p-5 pb-0 relative overflow-auto overscroll-contain">
						{Object.keys(withdrawalsDetails)?.length > 0 && (
							<div>
								<div>
									<div className="flex justify-between">
										<div className="font-recoleta font-semibold text-2xl">
											Payment Details
										</div>
										<div
											onClick={() => this.closeModal(false)}
											className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
											<Xclose className="customizeSVG group-hover:text-white m-auto" />
										</div>
									</div>
									<div className="text-fade text-sm">
										{CurrencyFormat(withdrawalsDetails?.amountRequested) ?? "-"}
										&nbsp;to&nbsp;
										<span className="capitalize">
											{withdrawalsDetails?.employeeId?.firstName ?? "-"} {withdrawalsDetails?.employeeId?.lastName ?? ""}
										</span>
									</div>
									<div className="mt-2 flex space-x-4">
										{withdrawalsDetails.status === "pending" && (
											<div className="label label-yellow uppercase">
												Pending
											</div>
										)}
										{withdrawalsDetails.status === "paid" && (
											<div className="label label-green uppercase">Paid</div>
										)}
										{withdrawalsDetails.status === "approved" && (
											<div className="label label-blue uppercase">Approved</div>
										)}
										{withdrawalsDetails.status === "rejected" && (
											<div className="label label-red uppercase">Rejected</div>
										)}
										{withdrawalsDetails.status === "cancelled" && (
											<div className="label label-gray uppercase">
												Cancelled
											</div>
										)}
										{withdrawalsDetails.status === "failed-credit" && (
											<div className="label label-red uppercase">
												Failed Credit
											</div>
										)}
										{withdrawalsDetails.status === "cut-off" && (
											<div className="label label-red uppercase">Cut-Off</div>
										)}
										{withdrawalsDetails.status === "processing" && (
											<div className="label label-yellow uppercase">
												Processing
											</div>
										)}
									</div>
								</div>

								<div className="w-full h-px mt-8 bg-gray-200"></div>

								<div className="my-8">
									<div className="font-semibold text-sm">Sent To</div>

									<div className="mt-5 grid grid-cols-2 gap-x-4">
										<div className="space-y-5">
											<div className="my-auto">
												<div className="font-semibold text-xs">Name</div>
												<div className="mt-1 font-medium text-xs capitalize">
													{getFullName(withdrawalsDetails)}
												</div>
											</div>

											<div className="w-full my-auto">
												<div className="font-semibold text-xs">Job Title</div>
												<div className="mt-1 font-medium text-xs break-words">
													{withdrawalsDetails?.employeeId?.jobTitle}
												</div>
											</div>

											<div className="w-full my-auto">
												<div className="font-semibold text-xs">
													Company Name
												</div>
												<div className="mt-1 font-medium text-xs break-words">
													{withdrawalsDetails?.companyInfo
														? withdrawalsDetails?.companyInfo?.companyName
														: ""}
												</div>
											</div>
										</div>
										<div className="space-y-5">
											<div className="my-auto">
												<div className="font-semibold text-xs">Email</div>
												<div className="mt-1 font-medium text-xs">
													{withdrawalsDetails?.employeeId?.email}
												</div>
											</div>

											<div className="my-auto">
												<div className="font-semibold text-xs">
													Phone Number
												</div>
												<div className="mt-1 font-medium text-xs">
													{withdrawalsDetails?.employeeId?.phoneNumber}
												</div>
											</div>
										</div>
									</div>

									<div className="w-full h-px mt-8 bg-gray-200"></div>
									<div className="mt-6 font-semibold text-sm">
										Payment Details
									</div>

									<div className="mt-5 grid grid-cols-2 gap-x-4">
										<div className="space-y-5">
											<div className="my-auto">
												<div className="font-semibold text-xs">
													Amount Requested
												</div>
												<div className="mt-1 font-medium text-xs">
													{CurrencyFormat(withdrawalsDetails?.amountRequested)}
												</div>
											</div>

											<div className="my-auto">
												<div className="font-semibold text-xs">Amount Paid</div>
												<div className="mt-1 font-medium text-xs">
													{CurrencyFormat(withdrawalsDetails?.actualPaid)}
												</div>
											</div>

											<div className="my-auto">
												<div className="font-semibold text-xs">
													Payment Reference
												</div>
												<div className="mt-1 font-medium text-xs">
													{withdrawalsDetails?.reference ?? "-"}
												</div>
											</div>
										</div>
										<div className="space-y-5">
											<div className="w-full my-auto">
												<div className="font-semibold text-xs">
													Transaction Fee
												</div>
												<div className="mt-1 font-medium text-xs break-words">
													{CurrencyFormat(withdrawalsDetails?.fee) ?? "-"}
												</div>
											</div>

											<div className="w-full my-auto">
												<div className="font-semibold text-xs">
													Transaction Date
												</div>
												<div className="mt-1 font-medium text-xs break-words">
													{DateTimeFormat(withdrawalsDetails?.date)}
												</div>
											</div>

											<div className="w-full my-auto">
												<div className="font-semibold text-xs">Description</div>
												<div className="mt-1 font-medium text-xs break-words">
													{withdrawalsDetails?.transferResponse?.reason}
												</div>
											</div>
										</div>
									</div>
									<div className="w-full h-px mt-8 bg-gray-200"></div>
									<div className="mt-6 font-semibold text-sm">Bank Details</div>

									<div className="mt-5 grid grid-cols-2 gap-x-4">
										<div className="space-y-5">
											<div className="my-auto">
												<div className="font-semibold text-xs">Bank Name</div>
												<div className="mt-1 font-medium text-xs">
													{withdrawalsDetails?.employeeId.bankName}
												</div>
											</div>

											<div className="w-full my-auto">
												<div className="font-semibold text-xs">
													Account Number
												</div>
												<div className="mt-1 font-medium text-xs break-words">
													{withdrawalsDetails?.employeeId?.bankAccountNumber}
												</div>
											</div>
										</div>
										<div className="space-y-5"></div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>

				<div>
					<TransactionsPageRouter />

					<div className="mt-6 lg:flex">
						<div className="w-full lg:w-1/2">
							<div className="page-title">Withdrawals</div>
						</div>
						<div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
							<div>
								<Download
									tooltip="Download withdrawals list"
									format="csv"
									filename={fileName}
									columns={columns}
									ref={(instance) => {
										this.Download = instance;
									}}
									click={() => {
										this.props
											.downloadGetAllWithdrawals({
												...getAllWithdrawalsParams,
												page: 1,
												limit: 10000,
												download: true,
											})
											.then(() => {
												// this.Download.processDownload();
												toast.success(
													"The requested document has been forwarded to your email"
												);
											});
									}}
									loading={this.props.downloadLoading}
									response={this.props.downloadAllWithdrawals}
								/>
							</div>

							<div>
								<SearchFilter
									search={{
										name: "search",
										placeholder: "Keyword search",
									}}
									filters={[
										{
											title: "Status",
											name: "status",
											dataType: "radio",
											options: [
												{
													display: "All",
													payload: "",
												},
												{
													display: "Paid",
													payload: "paid",
												},
												{
													display: "Pending",
													payload: "pending",
												},
												{
													display: "Cancelled",
													payload: "cancelled",
												},
												{
													display: "Failed Credit",
													payload: "failed-credit",
												},
											],
										},
										{
											title: "Platform",
											name: "platform",
											dataType: "radio",
											options: [
												{
													display: "All",
													payload: "",
												},
												{
													display: "Web",
													payload: "web",
												},
												{
													display: "Mobile",
													payload: "mobile",
												},
												// {
												// 	display: "USSD",
												// 	payload: "ussd",
												// },
											],
										},
										{
											title: "Month & Year",
											dataType: "month-year",
											option: {
												name: "month-year",
												placeholder: "Month & Year",
											},
										},
										{
											title: "Withdrawal Date",
											dataType: "date-range",
											options: {
												from: {
													name: "from",
													placeholder: "Start Date",
												},
												to: {
													name: "to",
													placeholder: "End Date",
												},
											},
										},
										{
											title: "Salary Amount",
											dataType: "number-range",
											options: {
												from: {
													name: "salaryFrom",
													placeholder: "₦0.00",
												},
												to: {
													name: "salaryTo",
													placeholder: "₦0.00",
												},
											},
										},
									]}
								/>
							</div>
						</div>
					</div>

					<div className="mt-6">
						<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
							<DashboardBoxes
								data={dashboardProps1}
								loading={withdrawalsCountLoading}
								result={CurrencyFormat(withdrawalsCount?.totalAmount)}
							/>
							<DashboardBoxes
								data={dashboardProps2}
								loading={withdrawalsCountLoading}
								result={
									<>
										<span>{withdrawalsCount?.totalCount}&nbsp;</span>
										<span className="text-red-500">
											({withdrawalsCount?.totalPendingCount})
										</span>
									</>
								}
							/>
							<DashboardBoxes
								data={dashboardProps5}
								loading={loadingPaystackBalance}
								result={
									CurrencyFormat(paystackBalance?.balance / 100, "", "none") +
									" (" +
									paystackBalance?.currency +
									")"
								}
							/>
						</div>
					</div>

					<div className="w-full lg:flex mt-6 mb-4 sm:mb-0">
						<div className="w-full lg:w-1/2 flex space-x-3">
							<div>
								{allEmployersLoading === true && (
									<div className="w-full px-3 py-2 border border-gray-200 rounded">
										<FontAwesomeIcon icon="spinner" spin className="mr-1.5" />
										Loading
									</div>
								)}
								{allEmployersLoading === false && (
									<div className="w-full">
										<Select
											options={employerFilterOptions}
											placeholder="Search Employees Firm"
											value={employerFilter}
											onChange={this.employerFilterValue}
										/>
									</div>
								)}
							</div>
							<div className="lg:col-span-2 flex">
								{allEmployersLoading === false && employerFilter !== "" && (
									<div className="mx-auto sm:mx-0 my-auto flex text-sm">
										<div className="hidden mr-6">
											{data?.totalDocs} {pluralize("result", data?.totalDocs)}{" "}
											found
										</div>
										<div
											onClick={this.clearEmployerFilterOptions}
											className="cursor-pointer text-red-500 border-b-2 border-transparent hover:border-red-500">
											<FontAwesomeIcon icon="times" className="mr-1" />
											Clear Filter
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="w-full lg:w-1/2 lg:flex lg:justify-end mt-1 lg:mt-0">
							<div className="w-full lg:w-auto inline-block box-border-only overflow-auto">
								<div className="h-10 flex">
									<div
										onClick={() => this.gotoUrlQuery({ status: "" })}
										className={
											"cursor-pointer page-nav " +
											(getAllWithdrawalsParams?.status === "" ? "active" : "")
										}>
										<div className="whitespace-nowrap">All Withdrawals</div>
									</div>
									<div
										onClick={() => this.gotoUrlQuery({ status: "paid" })}
										className={
											"cursor-pointer page-nav " +
											(getAllWithdrawalsParams?.status === "paid"
												? "active"
												: "")
										}>
										<div className="whitespace-nowrap">Paid Withdrawals</div>
									</div>
									<div
										onClick={() => this.gotoUrlQuery({ status: "pending" })}
										className={
											"cursor-pointer page-nav " +
											(getAllWithdrawalsParams?.status === "pending"
												? "active"
												: "")
										}>
										<div className="whitespace-nowrap">Pending Withdrawals</div>
									</div>
									<div
										onClick={() =>
											this.gotoUrlQuery({ status: "failed-credit" })
										}
										className={
											"cursor-pointer page-nav " +
											(getAllWithdrawalsParams?.status === "failed-credit"
												? "active"
												: "")
										}>
										<div className="whitespace-nowrap">
											Failed-Credit Withdrawals
										</div>
									</div>
									<div
										onClick={() => this.gotoUrlQuery({ status: "cancelled" })}
										className={
											"cursor-pointer page-nav " +
											(getAllWithdrawalsParams?.status === "cancelled"
												? "active"
												: "")
										}>
										<div className="whitespace-nowrap">
											Cancelled Withdrawals
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-3">
						<div className="">
							<div className="table-container">
								<table className="table table-auto table-rounded table-border table-align-top">
									<thead>
										<tr>
											<th>Date Requested</th>
											<th>Date Approved</th>
											<th>Name</th>
											<th>Platform</th>
											<th>Reason</th>
											<th>Amount</th>
											<th>Fee</th>
											<th>Status</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{this.props.loading === true && (
											<tr>
												<td colSpan="9">
													<div className="table-info">
														<FontAwesomeIcon icon="spinner" spin />
														<div className="font-bold uppercase">Loading</div>
													</div>
												</td>
											</tr>
										)}

										{this.props.loading === false && !data && (
											<tr>
												<td colSpan="9">
													<div className="table-info">
														<FontAwesomeIcon icon="unlink" />
														<div className="font-bold uppercase">
															An error occurred
															<br />
															Please try again later.
														</div>
													</div>
												</td>
											</tr>
										)}

										{this.props.loading === false &&
											data &&
											data?.docs &&
											data?.docs?.length <= 0 && (
												<tr>
													<td colSpan="9">
														{data.page <= data.totalPages && (
															<div className="table-info">
																<FontAwesomeIcon icon="list" />
																<div className="font-bold uppercase">
																	No results found
																</div>
															</div>
														)}

														{data.page > data.totalPages && (
															<div className="table-info">
																<FontAwesomeIcon icon="exclamation-triangle" />
																<div className="font-bold uppercase">
																	Invalid Page Number
																</div>
																<div className="mt-2">
																	<Link
																		to="?page=1"
																		className="btn btn-ep-blue btn-sm mx-auto">
																		Goto Page 1
																	</Link>
																</div>
															</div>
														)}
													</td>
												</tr>
											)}

										{this.props.loading === false &&
											data &&
											data?.docs &&
											data?.docs?.length > 0 &&
											data?.docs?.map((withdrawal, index) => {

												return (
													<tr key={index}>
														<td>{DateTimeFormat(withdrawal?.date)}</td>
														<td>{DateTimeFormat(withdrawal?.updatedAt)}</td>
														<td>
															<div className="capitalize">
																{getFullName(withdrawal)}
															</div>
															<div className="text-fade">
																{withdrawal?.companyInfo
																	? withdrawal?.companyInfo?.companyName
																	: ""}
															</div>
														</td>
														<td className="capitalize">
															{withdrawal?.platform ? withdrawal?.platform : "-"}
														</td>
														<td>
															{displayReasonText(
																withdrawal?.reason,
																withdrawal?.userReason
															)}
														</td>
														<td>{CurrencyFormat(withdrawal?.amountRequested)}</td>
														<td>
															<div>{CurrencyFormat(withdrawal?.fee)}</div>
															<div className="text-xs whitespace-nowrap">
																{withdrawal?.feePayer === "shared" && <>Shared</>}
																{withdrawal?.feePayer === "employee" && (
																	<>By Employee</>
																)}
																{withdrawal.feePayer === "employer" && (
																	<>By Employer</>
																)}
															</div>
														</td>
														<td>
															{withdrawal.status === "pending" && (
																<div className="label label-yellow uppercase">
																	Pending
																</div>
															)}
															{withdrawal.status === "paid" && (
																<div className="label label-green uppercase">
																	Paid
																</div>
															)}
															{withdrawal.status === "approved" && (
																<div className="label label-blue uppercase">
																	Approved
																</div>
															)}
															{withdrawal.status === "rejected" && (
																<div className="label label-red uppercase">
																	Rejected
																</div>
															)}
															{withdrawal.status === "cancelled" && (
																<div className="label label-gray uppercase">
																	Cancelled
																</div>
															)}
															{withdrawal.status === "failed-credit" && (
																<div className="label label-red uppercase">
																	Failed Credit
																</div>
															)}
															{withdrawal.status === "cut-off" && (
																<div className="label label-red uppercase">
																	Cut-Off
																</div>
															)}
															{withdrawal.status === "processing" && (
																<div className="label label-yellow uppercase">
																	Processing
																</div>
															)}
														</td>
														<td>
															<div className="table-dropdown-align-top">
																<Dropdown
																	icon={dropdownTableIcon}
																	menu={[
																		{
																			type: "div",
																			text: "View Details",
																			click: () => this.viewDetails(withdrawal),
																		},
																		withdrawal.status === "failed-credit"
																			? {
																					type: "div",
																					text: "Retry Payment",
																					click: () =>
																						this.retryWithdrawal(withdrawal._id),
																			}
																			: "",
																	]}
																/>
															</div>
														</td>
													</tr>
												)
											}
										)}
									</tbody>
								</table>
							</div>

							{this.props.loading === false &&
								data &&
								data?.docs &&
								data?.docs?.length > 0 && (
									<div>
										<Pagination
											data={data}
											url={this.props.location.search}
											limit={getAllWithdrawalsParams?.limit}
										/>
									</div>
								)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.entities.withdrawals.loading,
	reloadPage: state.entities.withdrawals.reloadPage,
	withdrawalsResponse: state.entities.withdrawals.allWithdrawals,
	allWithdrawals: state.entities.withdrawals.allWithdrawals.withdrawals,
	downloadLoading: state.entities.withdrawals.downloadLoading,
	downloadAllWithdrawals: state.entities.withdrawals.downloadAllWithdrawals,
	withdrawalsCount: state.entities.withdrawals.withdrawalsCount,
	withdrawalsCountLoading: state.entities.withdrawals.withdrawalsCountLoading,

	allEmployersLoading: state.entities.employers.loading,
	allEmployers: state.entities.employers.allEmployers,
	loadingPaystackBalance: state.entities.integrations.loadingPaystackBalance,
	paystackBalance: state.entities.integrations.paystackBalance,
});

const mapDispatchToProps = (dispatch) => ({
	getAllWithdrawals: (params) =>
		dispatch(withdrawals.getAllWithdrawals(params)),
	getAllWithdrawalsCount: (params) =>
		dispatch(withdrawals.getAllWithdrawalsCount(params)),
	downloadGetAllWithdrawals: (params) =>
		dispatch(withdrawals.downloadGetAllWithdrawals(params)),
	retryWithdrawal: (withdrawal_id) =>
		dispatch(withdrawals.retryWithdrawal(withdrawal_id)),
	resetReloadPage: () => dispatch(withdrawals.resetReloadPage()),

	getAllEmployers: (params) => dispatch(employers.getAllEmployers(params)),
	getPaystackBalance: () => dispatch(integrations.getPaystackBalance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
