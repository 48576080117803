
export const documentLabel = {
    "cac": "CAC Document",
    'utility-bill': "Utility Bill",
    "driver's-license": "Driver’s License",
    'international-passport': "International Passport",
    'national-id': "National ID Card or NIN Slip",
    "voter's-card": "Voter’s Card",
    "particulars-of-directors": "Particulars of Directors",
    'lease-agreement': "Lease of Tenancy Agreement",
    'others': "Reciept showing your Business Address",
};
