
const ModalDetailsContent = (props) => (
    <div className={(props.width ?? "w-1/2") + " pr-6 mb-6"}>
        <div className="font-semibold text-xs">
            {props.label}
        </div>
        <div className="mt-1 font-medium text-sm">
            {props.value}
        </div>
    </div>
)

export default ModalDetailsContent
