import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import DateFormat, { DateTimeFormat } from "utils/dateFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "components/__new/common/Table";
import processParams from "utils/processParams";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import useUrlNavigation from "hooks/useUrlNavigation";
import { TableContent } from "components/__new/common/TableContent";
import DashboardBox from "components/__new/common/DashboardBox";
import { ReactComponent as IconPlus } from "assets/images/icons/project-icons/Plus-bordered.svg";
import { ReactComponent as IconDownload } from "assets/images/icons/project-icons/download-04.svg";
import { ReactComponent as IconFile } from "assets/images/icons/project-icons/Document-File.svg";
import { ReactComponent as IconComment } from "assets/images/icons/project-icons/comment.svg";
import { ReactComponent as IconApproved } from "assets/images/icons/project-icons/onboarding-approved.svg";
import { ReactComponent as IconRejected } from "assets/images/icons/project-icons/onboarding-rejected.svg";
import SearchFilter from "components/common/filter";
import Download from "components/common/download";
import Pagination from "components/common/pagination";
import { renderStatusColor } from "utils/statusColor";
import FormDrawer from "components/__new/common/form-drawer";
import ModalDetailsContent from "components/__new/common/ModalDetailsContent";
import Maybe from "components/__new/common/Maybe";
import PageContent from "components/__new/common/PageContent";
import { documentLabel } from "data/documentLabel";
import numberOrdinalSuffix from "utils/numberOrdinalSuffix";
import { ButtonLoader, Textarea } from "components/__new/common/form";
import DialogBox from "utils/dialogBox";
import { toast } from "react-toastify";
import validate from "utils/validate";
import ContentLoading from "components/__new/common/ContentLoading";
import * as complianceActions from "store/entities/compliance/action";


const List = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {urlQuery} = useUrlNavigation();
    const {urlQueryParams} = useUrlQueryParams();
    const { businessMetricsLoading, businessMetrics, businessesLoading, businesses, businessesDownloadLoading, businessesDownload } = useSelector((s) => s.entities.compliance);
    
    const downloadRef = useRef();
    const [isOpenViewDetails, setIsOpenViewDetails] = useState(false);
    const [business, setBusiness] = useState({});
    const [listParams, setListParams] = useState({
        page: 1,
        limit: 20,
        status: "",
    });


    const getBusinessMetrics = () => {
        dispatch(complianceActions.getBusinessMetrics());
    }


    const getBusinesses = () => {
        let params = {...listParams};

        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 20, "limit");
        params = processParams(urlQueryParams, params, "status", "string", "", "status");
        params = processParams(urlQueryParams, params, "search", "string", "", "keyword");
        params = processParams(urlQueryParams, params, "companyType", "string", "", "companyType");
        params = processParams(urlQueryParams, params, "updatedFrom", "string", "", "updatedFrom");
        params = processParams(urlQueryParams, params, "updatedTo", "string", "", "updatedTo");
        
        setListParams(params);
        dispatch(complianceActions.getBusinesses(params));
    }


    const transformDownloadResponse = (businesses) => {
        const data = businesses?.map((business) => {
            return {
                ...business,
                companyName: getCompanyName(business),
            }
        });
        return data;
    }


    const viewDetails = (e) => {
        setBusiness(e);
        setIsOpenViewDetails(true);
    }
    

    const refetchAll = () => {
        getBusinessMetrics();
        getBusinesses();
    }
    

    const companyTypeLabel = {
        "registered business": "Registered Business",
        "freelance business (not registered)": "Unregistered Business",
    }
    

    useEffect(() => {
        getBusinessMetrics();
        // eslint-disable-next-line
    }, []);
    

    useEffect(() => {
        getBusinesses();
        // eslint-disable-next-line
    }, [location]);


    return (
        <div>

            <BusinessDetails
                isOpen={isOpenViewDetails}
                setIsOpen={setIsOpenViewDetails}
                business={business}
                refetchAll={refetchAll}
            />

            <div>
                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title capitalize">
                            Compliance
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        <div>
                            <Download
                                tooltip="Download businesses list"
                                filename="All Businesses.csv"
                                format="csv"
                                columns={[
                                    { label: "Employer ID", key: "_id" },
                                    { label: "Date Joined", key: "createdAt" },
                                    { label: "Date Updated", key: "updatedAt" },
                                    { label: "Business Name", key: "companyName" },
                                    { label: "First Name", key: "firstName" },
                                    { label: "Last Name", key: "lastName" },
                                    { label: "Email Address", key: "email" },
                                    { label: "Phone Number", key: "phoneNumber" },
                                    { label: "Job Title", key: "jobTitle" },
                                    { label: "Status", key: "status" },
                                    { label: "Active", key: "isActive" },
                                    { label: "Compliant", key: "isCompliant" },
                                    { label: "Email Verified", key: "isEmailVerified" },
                                    { label: "Disabled", key: "disabled" },
                                    { label: "User Type", key: "userType" },
                                    { label: "Wallet ID", key: "walletId" },
                                ]}
                                ref={downloadRef}
                                click={() => {
                                    dispatch(complianceActions.downloadBusinesses({...listParams, page: 1, limit: 10000, download: true})).then(() => {
                                        downloadRef.current?.processDownload();
                                    });
                                }}
                                loading={businessesDownloadLoading}
                                response={transformDownloadResponse(businessesDownload?.data?.employers)}
                            />
                        </div>
                        <div>
                            
                            <SearchFilter
                                search={{
                                    name: "search",
                                    placeholder: "Keyword search",
                                }}
                                filters={[
                                    {
                                        title: "Status",
                                        name: "status",
                                        dataType: "radio",
                                        options: [
                                            {
                                                display: "All",
                                                payload: "",
                                            },
                                            {
                                                display: "Pending",
                                                payload: "pending",
                                            },
                                            {
                                                display: "In-Review",
                                                payload: "review",
                                            },
                                            {
                                                display: "Approved",
                                                payload: "approved",
                                            },
                                            {
                                                display: "Rejected",
                                                payload: "rejected",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Company Type",
                                        name: "companyType",
                                        dataType: "radio",
                                        options: [
                                            {
                                                display: "All",
                                                payload: "",
                                            },
                                            {
                                                display: "Registered",
                                                payload: "registered",
                                            },
                                            {
                                                display: "Unregistered",
                                                payload: "unregistered",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Date Updated",
                                        dataType: "date-range",
                                        options: {
                                            from: {
                                                name: "updatedFrom",
                                                placeholder: "Start Date",
                                            },
                                            to: {
                                                name: "updatedTo",
                                                placeholder: "End Date",
                                            },
                                        },
                                    },
                                ]}
                            />

                        </div>
                    </div>
                </div>


                <div className="mt-6">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        <DashboardBox
                            title="Total Businesses"
                            value={businessMetrics?.data?.totalBusinesses ?? "-"}
                            loading={businessMetricsLoading}
                            icon={<IconPlus className="customizeSVG text-blue-600 m-auto w-7 h-7" />}
                            iconBackground="bg-blue-200"
                        />
                        <DashboardBox
                            title="Approved Businesses"
                            value={businessMetrics?.data?.activeBusinesses ?? "-"}
                            loading={businessMetricsLoading}
                            icon={<IconPlus className="customizeSVG text-green-600 m-auto w-7 h-7" />}
                            iconBackground="bg-green-200"
                        />
                        <DashboardBox
                            title="Rejected Businesses"
                            value={businessMetrics?.data?.rejectedBusinesses ?? "-"}
                            loading={businessMetricsLoading}
                            icon={<IconPlus className="customizeSVG text-red-600 m-auto w-7 h-7" />}
                            iconBackground="bg-red-200"
                        />
                    </div>
                </div>


                <div className="w-full mt-6 lg:flex">
                    <div className="w-full lg:w-1/2 flex">
                        <div className="page-title-mini my-auto">
                            Business Details
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 lg:flex lg:justify-end">
                        <div className="w-full lg:w-auto inline-block box-border-only overflow-auto">
                            <div className="h-10 flex">
                                <Link to={urlQuery("status=")} className={"cursor-pointer page-nav " + ((listParams.status === "" || listParams.status === undefined) ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        All
                                        (<ContentLoading loading={businessMetricsLoading} data={businessMetrics?.data?.totalBusinesses ?? "-"} />)
                                    </div>
                                </Link>
                                <Link to={urlQuery("status=pending")} className={"cursor-pointer page-nav " + ((listParams.status === "pending") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        Pending 
                                        (<ContentLoading loading={businessMetricsLoading} data={businessMetrics?.data?.pendingBusinesses ?? "-"} />)
                                    </div>
                                </Link>
                                <Link to={urlQuery("status=review")} className={"cursor-pointer page-nav " + ((listParams.status === "review") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        In-Review 
                                        (<ContentLoading loading={businessMetricsLoading} data={businessMetrics?.data?.inReviewBusinesses ?? "-"} />)
                                    </div>
                                </Link>
                                <Link to={urlQuery("status=approved")} className={"cursor-pointer page-nav " + ((listParams.status === "approved") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        Approved
                                        (<ContentLoading loading={businessMetricsLoading} data={businessMetrics?.data?.activeBusinesses ?? "-"} />)
                                    </div>
                                </Link>
                                <Link to={urlQuery("status=rejected")} className={"cursor-pointer page-nav " + ((listParams.status === "rejected") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        Rejected
                                        (<ContentLoading loading={businessMetricsLoading} data={businessMetrics?.data?.rejectedBusinesses ?? "-"} />)
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="mt-2">
                    <div className="">

                        <Table>
                            <Table.Head>
                                <th>Date Joined</th>
                                <th>Date Updated</th>
                                <th>Business Name</th>
                                <th>Email</th>
                                <th>Business Phone</th>
                                <th>Business Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </Table.Head>
                            <Table.Body>

                                <TableContent
                                    loading={businessesLoading}
                                    loadingText="Loading businesses"
                                    data={businesses?.data?.employers}
                                    colspan={8}
                                />

                                {((businessesLoading === false) && (businesses?.data?.employers) && (businesses?.data?.employers.length > 0)) && businesses?.data?.employers.map((business, index) =>
                                    <tr key={index}>
                                        <td>
                                            {DateFormat(business.createdAt?.split("T")[0])}
                                        </td>
                                        <td>
                                            {DateFormat(business.updatedAt?.split("T")[0])}
                                        </td>
                                        <td>
                                            <div onClick={() => {viewDetails(business)}} className="font-bold text-ep-blue capitalize whitespace-pre-wrap hover:underline cursor-pointer" style={{"minWidth":"150px"}}>
                                                {getCompanyName(business)}
                                            </div>
                                        </td>
                                        <td>
                                            {business?.email ?? "-"}
                                        </td>
                                        <td>
                                            {business?.phoneNumber ?? "-"}
                                        </td>
                                        <td>
                                            <span className="capitalize">
                                                {companyTypeLabel[business?.company?.companyType] ?? "-"}
                                            </span>
                                        </td>
                                        <td>
                                            <div className={"label capitalize " + (business?.status === "pending" ? "bg-gray-200" : renderStatusColor(business?.status))}>
                                                {business?.status ?? "-"}
                                            </div>
                                        </td>
                                        <td>
                                            <div onClick={() => {viewDetails(business)}} className="btn btn-transparent-black">
                                                View
                                            </div>
                                        </td>
                                    </tr>
                                )}

                            </Table.Body>
                        </Table>

                        {((businessesLoading === false) && (businesses?.data?.employers) && (businesses?.data?.employers.length > 0)) && 
                            <div>
                                <Pagination data={businesses?.data} limit={listParams.limit} />
                            </div>
                        }

                    </div>
                </div>


            </div>
            
        </div>
    )
}



export const BusinessDetails = (props) => {
    const { isOpen, setIsOpen, business: businessDetails, refetchAll } = props;
    const { businessLoading, business, complianceApprovalLoading, complianceApproval, complianceRejectionLoading, complianceRejection } = useSelector((s) => s.entities.compliance);
    const dispatch = useDispatch();
    
    
    const employeeGroups = ["0", "1-10", "11-50", "51-200", "201-500", "500+"];
    const statusUI = {
        "pending": {
            color: "bg-gray-200",
            text: "Pending",
        },
        "review": {
            color: "bg-[#F49D1B]",
            text: "Under review",
        },
        "approved": {
            color: "bg-[#398D94]",
            text: "Approved",
        },
        "rejected": {
            color: "bg-red-300",
            text: "Rejected",
        },
    }
    

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [ isOpenRejectionForm, setIsOpenRejectionForm] = useState(false);
    const [ form, setForm] = useState({
        reason: "",
    });


    const checkFormValidation = (data = form, appendKey, appendValue) => {
        let params = {...data};
        if (appendKey){
            params = {...params, [appendKey]: appendValue};
        }
        const errors = validate(params);
        if (errors){
            setIsFormValidated(false);
            // console.log("checkFormValidation", errors);
        }
        else{
            setIsFormValidated(true);
        }
    }


    const approveCompliance = async () => {
		const result = await DialogBox();
		if (result) {
            dispatch(complianceActions.approveCompliance(business?.employer?._id));
		}
	};

    const submitRejectComplianceForm = async (e) => {
        e?.preventDefault();
        const params = {
            rejectComment: form.reason,
        }
        dispatch(complianceActions.rejectCompliance(business?.employer?._id, params));
	};


    useEffect(() => {
        if (isOpen === true){
            dispatch(complianceActions.getBusiness(businessDetails._id));
        }
        // eslint-disable-next-line
    }, [isOpen]);


    useEffect(() => {
        if (complianceApproval?.status === "success"){
            toast.success(complianceApproval?.message);
            setIsOpen(false);
            dispatch(complianceActions.resetApproveCompliance());
            refetchAll();
        }
        // eslint-disable-next-line
    }, [complianceApproval]);


    useEffect(() => {
        if (complianceRejection?.status === "success"){
            toast.success("Compliance rejected");
            setIsOpen(false);
            setIsOpenRejectionForm(false);
            dispatch(complianceActions.resetRejectCompliance());
            refetchAll();
        }
        // eslint-disable-next-line
    }, [complianceRejection]);


    return (
        <>

            <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="pb-16">
                    <Maybe condition={businessLoading === true}>
                        <PageContent loading={businessLoading} loadingText="Loading details" />
                    </Maybe>

                    <Maybe condition={businessLoading === false && business?.status === "success"}>
                        <div className="p-6 text-sm">
                            <div className="flex justify-between">
                                <div className="font-recoleta font-semibold text-2xl capitalize">
                                    {getCompanyName(business?.employer)}
                                </div>
                                <div onClick={() => setIsOpen(false)} className="modal-close group">
                                    <FontAwesomeIcon icon="times" className="group-hover:text-white m-auto" />
                                </div>
                            </div>
                            <div className="text-fade text-sm">
                                {business?.employer?.email}
                            </div>
                            <div className="mt-1 flex space-x-4 text-fade">
                                Business Type:&nbsp;
                                <span className="text-ep-primary">
                                    {
                                        business?.employer?.company?.companyType !== undefined ? 
                                        business?.employer?.company?.companyType.includes("freelance") ? "Unregistered" : "Registered"
                                        : "-"
                                    }
                                </span>
                            </div>
                            <div className="mt-4 flex space-x-4">
                                <div className="flex space-x-2 items-center">
                                    <div className={"w-3 h-3 mr-2 font-semibold rounded-full " + (statusUI[business?.employer?.status]?.color)}></div>
                                    {statusUI[business?.employer?.status]?.text ?? "-"}
                                </div>
                            </div>
                        </div>

                        <div className="w-full h-px my-2 bg-gray-200"></div>
                        
                        <div className="p-6 flex flex-row flex-wrap">
                            <div className="w-full mb-6 font-semibold text-lg">
                                Business Details
                            </div>

                            <ModalDetailsContent label="Business Name" value={business?.employer?.company?.companyName ?? "-"} />
                            <ModalDetailsContent label="Business Registration Number" value={<span className="text-ep-primary">{business?.employer?.company?.registrationNumber ?? "-"}</span>} />
                            <ModalDetailsContent label="Trading Name" value={business?.employer?.company?.businessAlias ?? "-"} />
                            <ModalDetailsContent label="Business Phone Number" value={business?.employer?.phoneNumber ?? "-"} />
                            <ModalDetailsContent label="Number of Employees" value={employeeGroups[(business?.employer?.company?.companySize)] || "-"} />
                            <ModalDetailsContent label="Registration Type" value={<span className="capitalize">{business?.employer?.company?.registrationType ?? "-"}</span>} />
                            <ModalDetailsContent label="Business Description" value={business?.employer?.company?.companyDescription ?? "-"} width="w-full" />
                            <ModalDetailsContent label="Business Address" value={business?.employer?.company?.address ?? "-"} width="w-full" />
                            
                            <div className="w-full h-px mt-4 mb-8 bg-gray-200"></div>
                            
                            <div className="w-full mb-6 font-semibold text-lg">
                                Business Document
                            </div>

                            <ModalDetailsContent label="Full Name" value={<span className="capitalize">{business?.employer?.firstName} {business?.employer?.lastName}</span>} />
                            <ModalDetailsContent label="Country" value={<span className="capitalize">{business?.employer?.company?.country ?? "-"}</span>} />
                            <Maybe condition={business?.employer?.company?.companyType !== "registered business"}>
                                <ModalDetailsContent label="Date of Birth" value={DateFormat(business?.employer?.company?.dob) ?? "-"} />
                                <ModalDetailsContent label="BVN" value={business?.employer?.company?.bvn ?? "-"} />
                            </Maybe>

                            <Maybe condition={business?.employer?.company?.businessDocuments?.length > 0}>
                                {business?.employer?.company?.businessDocuments?.map((document, index) =>
                                    <div key={index} className="w-full mb-6">
                                        <ModalDetailsContent label="Document Type" value={documentLabel[document?.name] ?? "-"} />
                                        {document?.fileUrl && <DocumentPreview name={document?.name ?? "-"} file={document?.fileUrl ?? ""} />}
                                    </div>
                                )}
                            </Maybe>
                            
                            <Maybe condition={business?.employer?.company?.businessDirectors?.length > 0}>
                                {business?.employer?.company?.businessDirectors?.map((director, index) =>
                                    <div key={index} className="w-full">
                                        <div className="w-full h-px mt-4 mb-8 bg-gray-200"></div>

                                        <div className="w-full mb-6 font-semibold text-lg">
                                            Director {index + 1}
                                        </div>

                                        <ModalDetailsContent label="Full Name" value={<span className="capitalize">{director?.directorFirstName ?? "-"} {director?.directorLastName}</span>} />
                                        <ModalDetailsContent label="Date of Birth" value={DateFormat(director?.directorDob) ?? "-"} />
                                        <ModalDetailsContent label="Country" value={<span className="capitalize">{director?.nationality ?? "-"}</span>} />
                                        <ModalDetailsContent label="BVN" value={director?.bvn ?? "-"} />
                                        <ModalDetailsContent label="Document Type" value={documentLabel[director?.documentType] ?? "-"} />
                                        {director?.documentType && <DocumentPreview name={director?.documentType ?? "-"} file={director?.documentFile ?? ""} />}
                                    </div>
                                )}
                            </Maybe>
                            
                            <div className="w-full h-px mt-4 mb-8 bg-gray-200"></div>

                            <div className="w-full mb-6 font-semibold text-lg">
                                Social Media Links
                            </div>

                            <ModalDetailsContent label="Facebook" value={business?.employer?.company?.socials?.facebook ?? "-"} />
                            <ModalDetailsContent label="Twitter" value={business?.employer?.company?.socials?.twitter ?? "-"} />
                            <ModalDetailsContent label="Website" value={business?.employer?.company?.socials?.website ?? "-"} />
                            <ModalDetailsContent label="Others" value={business?.employer?.company?.socials?.others ?? "-"} />

                            <Maybe condition={business?.employer?.company?.bankStatementFile?.length > 0}>
                                <div className="w-full h-px mt-4 mb-8 bg-gray-200"></div>

                                <div className="w-full mb-6 font-semibold text-lg">
                                    On-Demand Details
                                </div>

                                <ModalDetailsContent label="Salary Details" value={<>{numberOrdinalSuffix(business?.employer?.company?.payrollCutOff) ?? "-"} of every month</>} />
                                <ModalDetailsContent label="Withdrawal Fee paid by" value={<span className="capitalize">{business?.employer?.company?.paymentFee ?? "-"}</span>} />
                                {business?.employer?.company?.bankStatementFile && <DocumentPreview name="Bank Statement File" file={business?.employer?.company?.bankStatementFile} />}
                            </Maybe>
                            
                            <Maybe condition={business?.employer?.approvedAt?.length > 0 || business?.employer?.rejectComment?.length > 0}>
                                <div className="w-full h-px mt-8 mb-8 bg-gray-200"></div>
                            </Maybe>

                            <Maybe condition={business?.employer?.rejectComment?.length > 0}>
                                {business?.employer?.rejectComment?.map((comment) => (
                                    <StatusTimeline
                                        key={comment?._id}
                                        action="rejected"
                                        email={comment?.commentBy ?? "-"}
                                        datetime={comment?.timestamp ?? "-"}
                                        comment={comment?.comment ?? "-"}
                                    />    
                                ))}
                            </Maybe>
                            
                            <Maybe condition={business?.employer?.approvedAt?.length > 0}>
                                <StatusTimeline
                                    action="approved"
                                    email={business?.employer?.approvedBy}
                                    datetime={business?.employer?.approvedAt}
                                />
                            </Maybe>
                        </div>

                        <Maybe condition={business?.employer?.status === "review"}>
                            <div className="w-full p-4 fixed bottom-0 bg-white flex justify-end border-t border-gray-200">
                                <button type="button" onClick={() => setIsOpenRejectionForm(true)} className="btn btn-md border border-red-500 text-red-500 mr-4">
                                    Reject
                                </button>
                                <ButtonLoader type="button" loading={complianceApprovalLoading} onClick={approveCompliance} className="btn btn-md btn-ep-primary">
                                    Approve
                                </ButtonLoader>
                            </div>
                        </Maybe>

                    </Maybe>
                </div>
            </FormDrawer>


            <FormDrawer display="center" size="sm" isOpen={isOpenRejectionForm} setIsOpen={setIsOpenRejectionForm}>
                <form onSubmit={submitRejectComplianceForm} className="p-6 max-w-sm">
                    <div className="flex items-center justify-between">
                        <div className="page-title-mini capitalize">
                            Reject Compliance
                        </div>
                        <div onClick={() => setIsOpenRejectionForm(false)} className="modal-close group">
                            <FontAwesomeIcon icon="times" className="group-hover:text-white m-auto" />
                        </div>
                    </div>
                    
                    <div className="mt-10">
                        <Textarea label="Reason For Rejection" placeholder="Please enter a reason for rejection..." required={true} styles={{height: "100px", resize: "none"}} value={form.reason} onChange={(e) => setForm({...form, reason: e.target.value})} validationName="reason" checkFormValidation={checkFormValidation} />
                    </div>
                    
                    <div className="mt-10 flex space-x-4">
                        <button type="button" onClick={() => setIsOpenRejectionForm(false)} className="btn btn-md btn-block btn-white">
                            Cancel
                        </button>
                        <ButtonLoader type="submit" loading={complianceRejectionLoading} className="btn btn-md btn-block btn-red" disabled={!isFormValidated}>
                            Reject
                        </ButtonLoader>
                    </div>
                </form>
            </FormDrawer>

        </>
    )
}



const getCompanyName = (business) => {
    if(business?.companyDetails?.length > 0) {
        return business?.companyDetails[0]?.companyName;
    }
    else if(business?.company?.companyName?.length > 0) {
        return business?.company?.companyName;
    }
    else if(business?.companyName?.length > 0) {
        return business?.companyName;
    }
    else {
        return "-";
    }
}



const DocumentPreview = ({name, file}) => {
    const previewableFiles = ["jpg","jpeg","png","gif"];
    const fileExtension = file?.split(".")?.pop();

    return (
        <div className="box !w-80 !p-0">
            <div className="h-36 bg-gray-200 flex items-center justify-center">
                <Maybe condition={previewableFiles.includes(fileExtension)}>
                    <img src={file} alt={name} className="object-cover opacity-30" />
                </Maybe>
                <Maybe condition={!previewableFiles.includes(fileExtension)}>
                    <IconFile className="w-48 h-20 svg-outline-aaa" />
                </Maybe>
            </div>
            <div className="p-4 flex space-x-4 justify-between border-t border-gray-200">
                <Link to={{ pathname: file }} target="_blank" className="btn btn-block btn-md btn-transparent-primary">
                    View Document
                </Link>
                <a href={file} target="_blank" rel="noreferrer" download className="btn btn-block btn-md btn-transparent-primary">
                    Download
                    <IconDownload className="ml-2" />
                </a>
            </div>
        </div>
    )
}


const StatusTimeline = (props) => {
    // const {email, timestamp, text, isApproved, isActive, toggleCommentView} = props;
    const {action, email, datetime, comment} = props;
    const [isCommentVisible, setIsCommentVisible] = useState(false);
	
	return (
		<div className="w-full min-h-[5rem] mb-2 ml-5 pl-7 pb-5 relative border-l-2 border-[#E5E7EB]">
			<div className="w-10 h-8 bg-white absolute top-0 -left-5 flex items-start justify-center overflow-hidden">
                <Maybe condition={action === "approved"}>
                    <IconApproved className="w-6 h-6" />
                </Maybe>
                <Maybe condition={action === "rejected"}>
                    <IconRejected className="w-[1.625rem] h-[1.625rem]" />
                </Maybe>
			</div>
			
            <div>
                <Maybe condition={action === "approved"}>
                    Approved by
                </Maybe>
                <Maybe condition={action === "rejected"}>
                    Rejected by
                </Maybe>
				&nbsp;<span className="text-ep-primary-500">{email}</span>
			</div>

			<div className="mt-0.5 text-sm">
				{DateTimeFormat(datetime)}
			</div>
			
			<Maybe condition={action === "rejected"}>
				<div onClick={() => setIsCommentVisible(!isCommentVisible)} className="w-max mt-1 text-ep-primary-500 text-sm flex items-center hover:underline cursor-pointer">
					<IconComment className="mr-1" />
					{isCommentVisible ? "Hide" : "See"} comment
				</div>
				
				<Maybe condition={isCommentVisible}>
					<div className="p-2 mt-2 text-justify text-sm bg-gray-100 rounded-sm">
						{comment}
					</div>
				</Maybe>
			</Maybe>
		</div>
	)
}



export default List;