export const APPROVE_EMPLOYER_ODP_START = "APPROVE_EMPLOYER_ODP_START";
export const APPROVE_EMPLOYER_ODP_DONE = "APPROVE_EMPLOYER_ODP_DONE";
export const APPROVE_EMPLOYER_ODP_FAILED = "APPROVE_EMPLOYER_ODP_FAILED";

export const REJECT_EMPLOYER_ODP_START = "REJECT_EMPLOYER_ODP_START";
export const REJECT_EMPLOYER_ODP_DONE = "REJECT_EMPLOYER_ODP_DONE";
export const REJECT_EMPLOYER_ODP_FAILED = "REJECT_EMPLOYER_ODP_FAILED";

export const SELECT_EMPLOYER = "SELECT_EMPLOYER";

export const DOWNLOAD_EMPLOYER_TRANSACTIONS_START = "DOWNLOAD_EMPLOYER_TRANSACTIONS_START";
export const DOWNLOAD_EMPLOYER_TRANSACTIONS_DONE = "DOWNLOAD_EMPLOYER_TRANSACTIONS_DONE";
export const DOWNLOAD_EMPLOYER_TRANSACTIONS_FAILED = "DOWNLOAD_EMPLOYER_TRANSACTIONS_FAILED";

export const GET_EMPLOYER_TRANSACTIONS_METRICS_START = "GET_EMPLOYER_TRANSACTIONS_METRICS_START";
export const GET_EMPLOYER_TRANSACTIONS_METRICS_DONE = "GET_EMPLOYER_TRANSACTIONS_METRICS_DONE";
export const GET_EMPLOYER_TRANSACTIONS_METRICS_FAILED = "GET_EMPLOYER_TRANSACTIONS_METRICS_FAILED";

export const GET_EMPLOYER_TRANSACTIONS_START = "GET_EMPLOYER_TRANSACTIONS_START";
export const GET_EMPLOYER_TRANSACTIONS_DONE = "GET_EMPLOYER_TRANSACTIONS_DONE";
export const GET_EMPLOYER_TRANSACTIONS_FAILED = "GET_EMPLOYER_TRANSACTIONS_FAILED";

export const GET_EMPLOYER = "GET_EMPLOYER";
export const GET_EMPLOYER_START = "GET_EMPLOYER_START";
export const GET_EMPLOYER_FAILED = "GET_EMPLOYER_FAILED";

export const GET_ALL_EMPLOYERS = "GET_ALL_EMPLOYERS";
export const GET_ALL_EMPLOYERS_START = "GET_ALL_EMPLOYERS_START";
export const GET_ALL_EMPLOYERS_FAILED = "GET_ALL_EMPLOYERS_FAILED";

export const GET_ALL_EMPLOYER_ADMIN = "GET_ALL_EMPLOYER_ADMIN";
export const GET_ALL_EMPLOYER_ADMIN_START = "GET_ALL_EMPLOYER_ADMIN_START";
export const GET_ALL_EMPLOYER_ADMIN_FAILED = "GET_ALL_EMPLOYER_ADMIN_FAILED";

export const DOWNLOAD_EMPLOYER_ADMIN = "DOWNLOAD_EMPLOYER_ADMIN";
export const DOWNLOAD_EMPLOYER_ADMIN_START = "DOWNLOAD_EMPLOYER_ADMIN_START";
export const DOWNLOAD_EMPLOYER_ADMIN_FAILED = "DOWNLOAD_EMPLOYER_ADMIN_FAILED";

export const GET_ALL_EMPLOYERS_COUNT = "GET_ALL_EMPLOYERS_COUNT";
export const GET_ALL_EMPLOYERS_COUNT_START = "GET_ALL_EMPLOYERS_COUNT_START";
export const GET_ALL_EMPLOYERS_COUNT_FAILED = "GET_ALL_EMPLOYERS_COUNT_FAILED";

export const GET_ALL_EMPLOYER_EMPLOYER = "GET_ALL_EMPLOYER_EMPLOYER";
export const GET_ALL_EMPLOYER_EMPLOYER_START =
	"GET_ALL_EMPLOYER_EMPLOYER_START";
export const GET_ALL_EMPLOYER_EMPLOYER_FAILED =
	"GET_ALL_EMPLOYER_EMPLOYER_FAILED";

export const GET_ACCEPTED_EMPLOYER_EMPLOYER = "GET_ACCEPTED_EMPLOYER_EMPLOYER";
export const GET_ACCEPTED_EMPLOYER_EMPLOYER_START =
	"GET_ACCEPTED_EMPLOYER_EMPLOYER_START";
export const GET_ACCEPTED_EMPLOYER_EMPLOYER_FAILED =
	"GET_ACCEPTED_EMPLOYER_EMPLOYER_FAILED";

export const DOWNLOAD_GET_ALL_EMPLOYERS = "DOWNLOAD_GET_ALL_EMPLOYERS";
export const DOWNLOAD_GET_ALL_EMPLOYERS_START =
	"DOWNLOAD_GET_ALL_EMPLOYERS_START";
export const DOWNLOAD_GET_ALL_EMPLOYERS_FAILED =
	"DOWNLOAD_GET_ALL_EMPLOYERS_FAILED";

export const DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER =
	"DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER";
export const DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER_START =
	"DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER_START";
export const DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER_FAILED =
	"DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER_FAILED";

export const GET_EMPLOYER_TOKEN = "GET_EMPLOYER_TOKEN";
export const GET_EMPLOYER_TOKEN_START = "GET_EMPLOYER_TOKEN_START";
export const GET_EMPLOYER_TOKEN_FAILED = "GET_EMPLOYER_TOKEN_FAILED";

export const APPROVE_EMPLOYER = "APPROVE_EMPLOYER";
export const APPROVE_EMPLOYER_START = "APPROVE_EMPLOYER_START";
export const APPROVE_EMPLOYER_FAILED = "APPROVE_EMPLOYER_FAILED";

export const REJECT_EMPLOYER_ONBOARDING_START = "REJECT_EMPLOYER_ONBOARDING_START";
export const REJECT_EMPLOYER_ONBOARDING_DONE = "REJECT_EMPLOYER_ONBOARDING_DONE";
export const REJECT_EMPLOYER_ONBOARDING_FAILED = "REJECT_EMPLOYER_ONBOARDING_FAILED";

export const RESET_ONBOARDING_REJECTION_RESPONSE = "RESET_ONBOARDING_REJECTION_RESPONSE";

export const ENABLE_EMPLOYER = "ENABLE_EMPLOYER";
export const ENABLE_EMPLOYER_START = "ENABLE_EMPLOYER_START";
export const ENABLE_EMPLOYER_FAILED = "ENABLE_EMPLOYER_FAILED";

export const DISABLE_EMPLOYER = "DISABLE_EMPLOYER";
export const DISABLE_EMPLOYER_START = "DISABLE_EMPLOYER_START";
export const DISABLE_EMPLOYER_FAILED = "DISABLE_EMPLOYER_FAILED";

export const TOGGLE_ROLLOVER_START = "TOGGLE_ROLLOVER_START";
export const TOGGLE_ROLLOVER_DONE = "TOGGLE_ROLLOVER_DONE";
export const TOGGLE_ROLLOVER_FAILED = "TOGGLE_ROLLOVER_FAILED";

export const TOGGLE_AUTO_WDL_START = "TOGGLE_AUTO_WDL_START";
export const TOGGLE_AUTO_WDL_DONE = "TOGGLE_AUTO_WDL_DONE";
export const TOGGLE_AUTO_WDL_FAILED = "TOGGLE_AUTO_WDL_FAILED";

export const SET_EARNINGS_START = "SET_EARNINGS_START";
export const SET_EARNINGS_DONE = "SET_EARNINGS_DONE";
export const SET_EARNINGS_FAILED = "SET_EARNINGS_FAILED";

export const STOP_EMPLOYEE_WITHDRAWALS = "STOP_EMPLOYEE_WITHDRAWALS";
export const STOP_EMPLOYEE_WITHDRAWALS_START =
	"STOP_EMPLOYEE_WITHDRAWALS_START";
export const STOP_EMPLOYEE_WITHDRAWALS_FAILED =
	"STOP_EMPLOYEE_WITHDRAWALS_FAILED";

export const RESUME_EMPLOYEE_WITHDRAWALS = "RESUME_EMPLOYEE_WITHDRAWALS";
export const RESUME_EMPLOYEE_WITHDRAWALS_START =
	"RESUME_EMPLOYEE_WITHDRAWALS_START";
export const RESUME_EMPLOYEE_WITHDRAWALS_FAILED =
	"RESUME_EMPLOYEE_WITHDRAWALS_FAILED";

export const ENABLE_EMPLOYER_EMPLOYEE = "ENABLE_EMPLOYER_EMPLOYEE";
export const ENABLE_EMPLOYER_EMPLOYEE_START = "ENABLE_EMPLOYER_EMPLOYEE_START";
export const ENABLE_EMPLOYER_EMPLOYEE_FAILED =
	"ENABLE_EMPLOYER_EMPLOYEE_FAILED";

export const DISABLE_EMPLOYER_EMPLOYEE = "DISABLE_EMPLOYER_EMPLOYEE";
export const DISABLE_EMPLOYER_EMPLOYEE_START =
	"DISABLE_EMPLOYER_EMPLOYEE_START";
export const DISABLE_EMPLOYER_EMPLOYEE_FAILED =
	"DISABLE_EMPLOYER_EMPLOYEE_FAILED";

export const RESEND_INVITE = "RESEND_INVITE";
export const RESEND_INVITE_START = "RESEND_INVITE_START";
export const RESEND_INVITE_FAILED = "RESEND_INVITE_FAILED";

export const RESEND_UNENROLLED_EMPLOYEES_INVITE =
	"RESEND_UNENROLLED_EMPLOYEES_INVITE";
export const RESEND_UNENROLLED_EMPLOYEES_INVITE_START =
	"RESEND_UNENROLLED_EMPLOYEES_INVITE_START";
export const RESEND_UNENROLLED_EMPLOYEES_INVITE_FAILED =
	"RESEND_UNENROLLED_EMPLOYEES_INVITE_FAILED";

export const GET_PERFORMANCE_EMPLOYEES = "GET_PERFORMANCE_EMPLOYEES";
export const GET_PERFORMANCE_EMPLOYEES_START =
	"GET_PERFORMANCE_EMPLOYEES_START";
export const GET_PERFORMANCE_EMPLOYEES_FAILED =
	"GET_PERFORMANCE_EMPLOYEES_FAILED";

export const GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS =
	"GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS";
export const GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS_START =
	"GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS_START";
export const GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS_FAILED =
	"GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS_FAILED";

export const GENERATE_ERA = "GENERATE_ERA";
export const GENERATE_ERA_START = "GENERATE_ERA_START";
export const GENERATE_ERA_FAILED = "GENERATE_ERA_FAILED";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";

export const TOGGLE_EMP_EARNINGS_MODAL = "TOGGLE_EMP_EARNINGS_MODAL";
