import React, { Component } from 'react';
import store from "store/store";
import Joi from "joi-browser";
import queryString from 'query-string';
import DashboardBoxes from 'components/common/dashboard-boxes';
import Pagination from 'components/common/pagination';
import Select from 'react-select'
import pluralize from "utils/pluralize";
import CurrencyFormat from "utils/currencyFormat";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Download from 'components/common/download';
import SearchFilter from 'components/common/filter';
import { connect } from "react-redux";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconChart } from "assets/images/icons/project-icons/ChartPie.svg";
import * as payroll from "store/entities/payroll/action";
import * as employers from "store/entities/employers/action";


const columns = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Employee ID", key: "employeeId" },
    { label: "Amount Withdrawn", key: "totalAmountWithdrawn" },
    { label: "Fee", key: "" },
    { label: "Salary Balance", key: "salaryBalance" },
];


class List extends Component {
    constructor(props) {
        super(props);
        this.csvLinkEl = React.createRef();
    }

    state = {
        getAllPayrollParams: {
            page: 1,
            limit: 20,
            employerId: "",
            download: false,
        },
        dashboardProps1: {
            iconBg: "bg-blue-200",
            icon: <IconChart className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
            titleText: "Total Payroll",
            mainText: "-"
        },
        dashboardProps2: {
            iconBg: "bg-gray-200",
            icon: <IconChart className="customizeSVG text-gray-600 m-auto w-7 h-7" />,
            titleText: "Total Withdrawals",
            mainText: "-"
        },
        dashboardProps3: {
            iconBg: "bg-yellow-200",
            icon: <IconChart className="customizeSVG text-yellow-600 m-auto w-7 h-7" />,
            titleText: "Repayment Balance",
            mainText: "-"
        },
        search: {
            searchPayroll: "",
        },
        searchErrors: "",
        dataDownload: [],
        storeUnsubscribe: "",
        employerFilterOptions: [],
        employerFilter: "",
    }


    getAllPayroll = () => {
        const qs = queryString.parse(this.props.location.search);
        let getAllPayrollParams = {...this.state.getAllPayrollParams};
        let searchQuery = "";
        
        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            getAllPayrollParams = {...getAllPayrollParams, page: qs.page};
        }
        else{
            getAllPayrollParams = {...getAllPayrollParams, page: 1};
        }

        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            getAllPayrollParams = {...getAllPayrollParams, limit: qs.limit};
        }
        else{
            getAllPayrollParams = {...getAllPayrollParams, limit: 20};
        }

        if ((qs.employerId !== undefined) && (qs.employerId !== null)){
            getAllPayrollParams = {...getAllPayrollParams, employerId: qs.employerId};
        }
        else{
            getAllPayrollParams = {...getAllPayrollParams, employerId: ""};
        }
        
        if ((qs.keyword !== undefined) && (qs.keyword !== null)){
            searchQuery = qs.keyword;
            getAllPayrollParams = {...getAllPayrollParams, keyword: qs.keyword};
        }
        else{
            getAllPayrollParams = {...getAllPayrollParams, keyword: ""};
        }
        
        if ((qs.status !== undefined) && (qs.status !== null)){
            getAllPayrollParams = {...getAllPayrollParams, status: qs.status};
        }
        else{
            getAllPayrollParams = {...getAllPayrollParams, status: ""};
        }

        const searchParams = {
            ...this.state.search,
            searchPayroll: searchQuery,
        }
        
        this.setState({getAllPayrollParams, search: searchParams});
        this.props.getAllPayroll(getAllPayrollParams);
    }


    formChange = (e) => {
        const formValue = {...this.state.search};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({search: formValue});
    }


    formChange = (e) => {
        const formValue = {...this.state.filter};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({filter: formValue});
    }


    selectEmployerFilter = () => {
        const qs = queryString.parse(this.props.location.search);
        const { employerFilterOptions } = this.state;
        let employerName = "";
        
        if ((typeof qs.employerId !== undefined) && (qs.employerId !== undefined) && (qs.employerId !== null)){
            let employerFilter = {value: qs.employerId, label: ""};
            // console.log(employerFilterOptions);
            
            if (Object.keys(employerFilterOptions).length > 0){
                Object.keys(employerFilterOptions).forEach((employer) => {
                    if (employerFilterOptions[employer].value === qs.employerId){
                        employerName = employerFilterOptions[employer].label;
                    }
                });

                if (employerName !== ""){
                    employerFilter = {...employerFilter, label: employerName};
                }
                else{
                    toast.error("Invalid employee firm selected");
                }
            }
            this.setState({employerFilter});
        }
    }


    getAllEmployers = () => {
        const getAllEmployersParams = {
            page: 1,
            limit: 1000,
            status: "active",
            name: "",
            download: false,
        }
        this.props.getAllEmployers(getAllEmployersParams);
    }


    employerFilterValue = (employerId) => {
        // console.log(employerId);
        this.setState({employerFilter: employerId});
        this.gotoUrlQuery({"employerId": employerId.value});
    }

    
    clearEmployerFilterOptions = () => {
        this.setState({employerFilter: ""});
        this.gotoUrlQuery({"employerId": ""});
    }


    gotoUrlQuery = (params) => {
        const qs = queryString.parse(this.props.location.search);

        Object.keys(params).forEach((paramName) => {
            // console.log("qs", qs[paramName]);
            delete qs[paramName];
        });

        let qsToUrl = new URLSearchParams(qs).toString();
        qsToUrl = this.props.location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        Object.keys(params).forEach((paramName) => {
            if (params[paramName] !== ""){
                qsToUrl += paramName + "=" + params[paramName] + "&";
            }
        });

        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        this.props.history.push(qsToUrl);
    }
    

    schema = {
        searchPayroll: Joi.string().allow(null,'').label("Search Payroll"),
    }

    
    validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.search, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    searchPayroll = async(e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({searchErrors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            const searchUrl = encodeURIComponent(this.state.search.searchPayroll);
            if (searchUrl !== ""){
                this.props.history.push("?search="+searchUrl);
            }
            else{
                this.props.history.push(this.props.location.pathname);
            }
        }
    }
    
    
    componentDidMount() {
        this.getAllPayroll();
        this.selectEmployerFilter();

        this.getAllEmployers();
        this.unsubscribe = store.subscribe(() => {
            if (this.props.allEmployersLoading === false){
                this.unsubscribe();
                const allEmployers = this.props.allEmployers.docs;
                let employerFilterOptions = [];
                if ((allEmployers !== undefined) && (allEmployers !== null)){
                    Object.keys(allEmployers).forEach(function(key) {
                        employerFilterOptions.push(
                            { value: allEmployers[key]._id, label: allEmployers[key].companyName }
                        );
                    });
                }
                this.setState({employerFilterOptions});
                this.selectEmployerFilter();
            }
        });
        this.setState({storeUnsubscribe: this.unsubscribe});
    }
    
    
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getAllPayroll();
            this.selectEmployerFilter();
        }
        if (this.props.reloadPage === true) {
            setTimeout(()=>{
                this.props.resetReloadPage();
                this.props.history.replace(this.props.location.pathname + this.props.location.search);
            },1000);
        }
    }


    componentWillUnmount(){
        const storeUnsubscribe = this.state.storeUnsubscribe;
        if ((storeUnsubscribe !== "") && (storeUnsubscribe !== undefined) && (storeUnsubscribe !== null)){
            storeUnsubscribe();
        }
    }


    render() { 
        customizeSVG();
        const { allPayroll: payrollResponse, allEmployersLoading } = this.props;
        const data = payrollResponse.payrolls;
        const { getAllPayrollParams, employerFilter, employerFilterOptions } = this.state;

        return ( 
            <div>

                <div className="">

                    <div className="lg:flex">
                        <div className="w-full lg:w-1/2">
                            <div className="page-title capitalize">
                                Payroll Report
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                            <div>
                                <Download
                                    tooltip="Download payroll list"
                                    format="csv"
                                    filename="All Payroll.csv"
                                    columns={columns}
                                    ref={instance => { this.Download = instance; }}
                                    click={() => {
                                        this.props.downloadGetAllPayroll({...getAllPayrollParams, page: 1, limit: 10000}).then(() => {
                                            this.Download.processDownload();
                                        });
                                    }}
                                    loading={this.props.downloadLoading}
                                    response={this.props.downloadAllPayroll}
                                />
                            </div>
                            <div>
                                <SearchFilter
                                    search={{
                                        name: "keyword",
                                        placeholder: "Keyword search",
                                    }}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="mt-6">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            <DashboardBoxes data={this.state.dashboardProps1} loading={this.props.loading} result={CurrencyFormat(payrollResponse.totalPayollAmount)} />
                            <DashboardBoxes data={this.state.dashboardProps2} loading={this.props.loading} result={CurrencyFormat(payrollResponse.totalAmountWithdrawn)} />
                            <DashboardBoxes data={this.state.dashboardProps3} loading={this.props.loading} result={CurrencyFormat(payrollResponse.totalPayrollbalance)} />
                        </div>
                    </div>


                    <div className="mt-6 mb-4 sm:mb-0 grid sm:grid-cols-2 lg:grid-cols-3 gap-y-2 gap-x-6">
                        <div>
                            <div className="text-sm hidden">
                                Filter Employers
                            </div>
                            {(allEmployersLoading === true) && 
                                <div className="w-full px-3 py-2 border border-gray-200 rounded">
                                    <FontAwesomeIcon icon="spinner" spin className="mr-1.5" />
                                    Loading
                                </div>
                            }
                            {(allEmployersLoading === false) && 
                                <Select options={employerFilterOptions} placeholder="Search Employees Firm" value={employerFilter} onChange={this.employerFilterValue} />
                            }
                        </div>
                        <div className="lg:col-span-2 flex">
                            {(allEmployersLoading === false) && (employerFilter !== "") &&
                                <div className="mx-auto sm:mx-0 my-auto flex space-x-6 text-sm">
                                    <div>
                                        {(data) ? data.totalDocs : ""} {pluralize("result", (data) ? data.totalDocs : "")} found
                                    </div>
                                    <div onClick={this.clearEmployerFilterOptions} className="cursor-pointer text-red-500 border-b-2 border-transparent hover:border-red-500">
                                        <FontAwesomeIcon icon="times" className="mr-1" />
                                        Clear Filter
                                    </div>
                                </div>
                            }
                        </div>
                    </div>


                    <div className="mt-3">
                        <div className="">

                            <div className="table-container">
                                <table className="table table-auto table-rounded table-border">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Employee ID</th>
                                            <th>Amount Withdrawn</th>
                                            <th>Fee</th>
                                            <th>Salary Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {(this.props.loading === true) && 
                                            <tr>
                                                <td colSpan="5">
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="spinner" spin />
                                                        <div className="font-bold uppercase">
                                                            Loading
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }

                                        {((this.props.loading === false) && (data) && (!data.docs)) && 
                                            <tr>
                                                <td colSpan="5">
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="unlink" />
                                                        <div className="font-bold uppercase">
                                                            An error occurred
                                                            <br />
                                                            Please try again later.
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        
                                        {((this.props.loading === false) && (data) && (data.docs) && (data.docs.length <= 0)) && 
                                            <tr>
                                                <td colSpan="5">

                                                    {(data.page <= data.totalPages) &&
                                                        <div className="table-info">
                                                            <FontAwesomeIcon icon="list" />
                                                            <div className="font-bold uppercase">
                                                                No results found
                                                            </div>
                                                        </div>
                                                    }
                                                    
                                                    {(data.page > data.totalPages) &&
                                                        <div className="table-info">
                                                            <FontAwesomeIcon icon="exclamation-triangle" />
                                                            <div className="font-bold uppercase">
                                                                Invalid Page Number
                                                            </div>
                                                            <div className="mt-2">
                                                                <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                                            </div>
                                                        </div>
                                                    }

                                                </td>
                                            </tr>
                                        }
                                        
                                        {((this.props.loading === false) && (data) && (data.docs) && (data.docs.length > 0)) && 
                                            data.docs.map((payroll, index) =>
                                                <tr key={index}>
                                                    <td className="capitalize">
                                                        {payroll.firstName ?? "-"} {payroll.lastName}
                                                    </td>
                                                    <td>
                                                        {payroll.employeeId ?? "-"}
                                                    </td>
                                                    <td>
                                                        {CurrencyFormat(payroll.totalAmountWithdrawn) ?? "-"}
                                                    </td>
                                                    <td>
                                                        -
                                                    </td>
                                                    <td>
                                                        {CurrencyFormat(payroll.salaryBalance) ?? "-"}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>

                            {((this.props.loading === false) && (data) && (data.docs) && (data.docs.length > 0)) && 
                                <div>
                                    <Pagination data={data} url={this.props.location.search} limit={getAllPayrollParams.limit} />
                                </div>
                            }

                        </div>
                    </div>


                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    loading: state.entities.payroll.loading,
    allPayroll: state.entities.payroll.allPayroll,
    downloadLoading: state.entities.payroll.downloadLoading,
    downloadAllPayroll: state.entities.payroll.downloadAllPayroll,
    allEmployersLoading: state.entities.employers.loading,
    allEmployers: state.entities.employers.allEmployers,
});

const mapDispatchToProps = (dispatch) => ({
    getAllPayroll: (params) => dispatch(payroll.getAllPayroll(params)),
    downloadGetAllPayroll: (params) => dispatch(payroll.downloadGetAllPayroll(params)),
    getAllEmployers: (params) => dispatch(employers.getAllEmployers(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);