import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { toast } from "react-toastify";
import { decryptPayload } from "utils/encrypt_decrypt";

const initialState = {
	creditMetricsLoading: false,
	creditMetricsData: [],

	creditRequestsLoading: false,
	creditRequestsData: {},

	selectedCreditItem: null,

	creditDetailsLoading: false,
	creditDetailsData: {},

	downloadCreditItemsLoading: false,
	downloadCreditItemsData: {},

	creditApplicationLoading: false,
	creditApplicationData: {},

	creditViewerLoading: false,
	creditViewerData: {},

	updateLoanLimitLoading: false,

	isApprovalRequestOpen: false,
	approvalRequestLoading: false,

	isApprovalConfirmationOpen: false,
	approvalConfirmationLoading: false,

	isUpdateUserBankOpen: false,
	updateUserBankLoading: false,

	isRejectionRequestOpen: false,
	rejectionRequestLoading: false,

	isRejectionConfirmationOpen: false,
	rejectionConfirmationLoading: false,

	isResetConfirmationOpen: false,
	resetConfirmationLoading: false,

	isLimitUpdateRequestOpen: false,
	requestLimitUpdateLoading: false,

	approveLimitUpdateOpen: false,
	approveLimitUpdateLoading: false,

	rejectLiimitUpdateOpen: false,
	rejectLimitUpdateLoading: false,

	allLoansPayload: {},
	allLoansLoading: false,

	makeLoanPaymentPayload: {},
	makeLoanPaymentLoading: false,

	creditLimitIds: [],
	bulkUpdateCreditLimitPayload: {},
	bulkUpdateCreditLimitLoading: false,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {

		case actions.SELECT_CREDIT_ITEM:
			state = {
				...state,
				selectedCreditItem: action.payload,
			};
			return state;

		case actions.GET_CREDIT_METRICS_START:
			state = {
				...state,
				creditMetricsLoading: true,
				creditMetricsData: [],
			};
			return state;

		case actions.GET_CREDIT_METRICS_DONE:
			state = {
				...state,
				creditMetricsLoading: false,
				creditMetricsData: action.payload,
			};
			return state;

		case actions.GET_CREDIT_METRICS_FAILED:

		case actions.GET_CREDIT_APPLICATION_METRICS_START:
			state = {
				...state,
				creditApplicationMetricsLoading: true,
				creditApplicationMetricsData: {},
			};
			return state;

		case actions.GET_CREDIT_APPLICATION_METRICS_DONE:
			state = {
				...state,
				creditApplicationMetricsLoading: false,
				creditApplicationMetricsData: action.payload,
			};
			return state;

		case actions.GET_CREDIT_APPLICATION_METRICS_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				creditApplicationMetricsLoading: false,
				creditApplicationMetricsData: {},
			};
			return state;

		case actions.GET_CREDIT_REQUESTS_START:
			state = {
				...state,
				creditRequestsLoading: true,
				creditRequestsData: {},
			};
			return state;

		case actions.GET_CREDIT_REQUESTS_DONE:
			state = {
				...state,
				creditRequestsLoading: false,
				creditRequestsData: action.payload,
			};
			return state;

		case actions.GET_CREDIT_REQUESTS_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				creditRequestsLoading: false,
				creditRequestsData: {},
			};
			return state;

		case actions.DOWNLOAD_CREDIT_ITEMS_START:
			state = {
				...state,
				downloadCreditItemsLoading: true,
				downloadCreditItemsData: {},
			};
			return state;

		case actions.DOWNLOAD_CREDIT_ITEMS_DONE:
			state = {
				...state,
				downloadCreditItemsLoading: false,
				downloadCreditItemsData: action.payload,
			};
			return state;

		case actions.DOWNLOAD_CREDIT_ITEMS_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				downloadCreditItemsLoading: false,
				downloadCreditItemsData: {},
			};
			return state;

		case actions.GET_CREDIT_DETAILS_START:
			state = {
				...state,
				creditDetailsLoading: true,
				creditDetailsData: {},
			};
			return state;

		case actions.GET_CREDIT_DETAILS_DONE:
			state = {
				...state,
				creditDetailsLoading: false,
				creditDetailsData: action.payload,
			};
			return state;

		case actions.GET_CREDIT_DETAILS_FAILED:

		case actions.GET_CREDIT_APPLICATION_REQUESTS_START:
			state = {
				...state,
				creditApplicationLoading: true,
				creditApplicationData: {},
			};
			return state;

		case actions.GET_CREDIT_APPLICATION_REQUESTS_DONE:
			state = {
				...state,
				creditApplicationLoading: false,
				creditApplicationData: action.payload,
			};
			return state;

		case actions.GET_CREDIT_APPLICATION_REQUESTS_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				creditApplicationLoading: false,
				creditApplicationData: {},
			};
			return state;

		case actions.GET_CREDIT_VIEWER_START:
			state = {
				...state,
				creditViewerLoading: true,
				creditViewerData: {},
			};
			return state;

		case actions.GET_CREDIT_VIEWER_DONE:
			state = {
				...state,
				creditViewerLoading: false,
				creditViewerData: action.payload,
			};
			return state;

		case actions.GET_CREDIT_VIEWER_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				creditViewerLoading: false,
				creditViewerData: {},
			};
			return state;

		case actions.UPDATE_LOAN_LIMIT_START:
			state = {
				...state,
				updateLoanLimitLoading: true,
			};
			return state;

		case actions.UPDATE_LOAN_LIMIT_DONE:
			toast.success("Loan Limit Status Updated Successfully")
			state = {
				...state,
				updateLoanLimitLoading: false,
			};
			return state;

		case actions.UPDATE_LOAN_LIMIT_FAILED:
			if (action?.payload?.validationErrors?.length > 0) {
				action?.payload?.validationErrors?.forEach((error) => errorReducer(error));
			} else {
				errorReducer(action.payload.message);
			}
			state = {
				...state,
				updateLoanLimitLoading: false,
			};
			return state;

		case actions.UPDATE_BANK_START:
			state = {
				...state,
				updateUserBankLoading: true,
			};
			return state;

		case actions.UPDATE_BANK_DONE:
			toast.success("User bank updated successfully")
			state = {
				...state,
				updateUserBankLoading: false,
			};
			return state;

		case actions.UPDATE_BANK_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				updateUserBankLoading: false,
			};
			return state;

		case actions.TOGGLE_LOAN_APPROVAL_REQUEST:
			state = {
				...state,
				isApprovalRequestOpen: !state.isApprovalRequestOpen,
			};
			return state;

		case actions.REQUEST_LOAN_APPROVAL_START:
			state = {
				...state,
				approvalRequestLoading: true,
			};
			return state;

		case actions.REQUEST_LOAN_APPROVAL_DONE:
			state = {
				...state,
				approvalRequestLoading: false,
			};
			return state;

		case actions.REQUEST_LOAN_APPROVAL_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				approvalRequestLoading: false,
			};
			return state;

		case actions.TOGGLE_LOAN_APPROVAL_CONFIRMATION:
			state = {
				...state,
				isApprovalConfirmationOpen: !state.isApprovalConfirmationOpen,
			};
			return state;

		case actions.APPROVE_LOAN_LIMIT_START:
			state = {
				...state,
				approvalConfirmationLoading: true,
			};
			return state;

		case actions.APPROVE_LOAN_LIMIT_DONE:
			toast.success("Loan Limit Status Updated Successfully")
			state = {
				...state,
				approvalConfirmationLoading: false,
			};
			return state;

		case actions.APPROVE_LOAN_LIMIT_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				approvalConfirmationLoading: false,
			};
			return state;

		case actions.TOGGLE_LOAN_REJECTION_REQUEST:
			state = {
				...state,
				isRejectionRequestOpen: !state.isRejectionRequestOpen,
			};
			return state;

		case actions.REQUEST_LOAN_REJECTION_START:
			state = {
				...state,
				rejectionRequestLoading: true,
			};
			return state;

		case actions.REQUEST_LOAN_REJECTION_DONE:
			toast.success("Rejected Loan Limit Successfully")
			state = {
				...state,
				rejectionRequestLoading: false,
			};
			return state;

		case actions.REQUEST_LOAN_REJECTION_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				rejectionRequestLoading: false,
			};
			return state;

		case actions.TOGGLE_LOAN_REJECTION_CONFIRMATION:
			state = {
				...state,
				isRejectionConfirmationOpen: !state.isRejectionConfirmationOpen,
			};
			return state;

		case actions.UPDATE_BANK_DETAILS_CREDIT_USER:
			state = {
				...state,
				isUpdateUserBankOpen: !state.isUpdateUserBankOpen,
			};
			return state;

		case actions.REQUEST_LOAN_RESET_START:
			state = {
				...state,
				resetRequestLoading: true,
			};
			return state;

		case actions.REQUEST_LOAN_RESET_DONE:
			toast.success("Reset Loan Limit Successful")
			state = {
				...state,
				resetRequestLoading: false,
			};
			return state;

		case actions.REQUEST_LOAN_RESET_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				resetRequestLoading: false,
			};
			return state;

		case actions.TOGGLE_LOAN_RESET_CONFIRMATION:
			state = {
				...state,
				isResetConfirmationOpen: !state.isResetConfirmationOpen,
			};
			return state;

		case actions.REJECT_LOAN_LIMIT_START:
			state = {
				...state,
				rejectionConfirmationLoading: true,
			};
			return state;

		case actions.REJECT_LOAN_LIMIT_DONE:
			state = {
				...state,
				rejectionConfirmationLoading: false,
			};
			return state;

		case actions.REJECT_LOAN_LIMIT_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				rejectionConfirmationLoading: false,
			};
			return state;

		case actions.TOGGLE_LOAN_UPDATE_REQUEST:
			state = {
				...state,
				isLimitUpdateRequestOpen: !state.isLimitUpdateRequestOpen,
			};
			return state;

		case actions.REQUEST_LOAN_LIMIT_UPDATE_START:
			state = {
				...state,
				requestLimitUpdateLoading: true,
			};
			return state;

		case actions.REQUEST_LOAN_LIMIT_UPDATE_DONE:
			state = {
				...state,
				requestLimitUpdateLoading: false,
			};
			return state;

		case actions.REQUEST_LOAN_LIMIT_UPDATE_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				requestLimitUpdateLoading: false,
			};
			return state;

		case actions.TOGGLE_LOAN_LIMIT_UPDATE_APPROVAL:
			state = {
				...state,
				approveLimitUpdateOpen: !state.approveLimitUpdateOpen,
			};
			return state;

		case actions.APPROVE_LOAN_LIMIT_UPDATE_START:
			state = {
				...state,
				approveLimitUpdateLoading: true,
			};
			return state;

		case actions.APPROVE_LOAN_LIMIT_UPDATE_DONE:
			state = {
				...state,
				approveLimitUpdateLoading: false,
			};
			return state;

		case actions.APPROVE_LOAN_LIMIT_UPDATE_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				approveLimitUpdateLoading: false,
			};
			return state;

		case actions.TOGGLE_LOAN_LIMIT_UPDATE_REJECTION:
			state = {
				...state,
				rejectLiimitUpdateOpen: !state.rejectLiimitUpdateOpen,
			};
			return state;

		case actions.REJECT_LOAN_LIMIT_UPDATE_START:
			state = {
				...state,
				rejectLimitUpdateLoading: true,
			};
			return state;

		case actions.REJECT_LOAN_LIMIT_UPDATE_DONE:
			state = {
				...state,
				rejectLimitUpdateLoading: false,
			};
			return state;

		case actions.REJECT_LOAN_LIMIT_UPDATE_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				rejectLimitUpdateLoading: false,
			};
			return state;

		case actions.GET_ALL_LOANS_START:
			state = {
				...state,
				allLoansLoading: true,
			};
			return state;

		case actions.GET_ALL_LOANS_DONE:
			state = {
				...state,
				allLoansPayload: action.payload,
				allLoansLoading: false,
			};
			return state;

		case actions.GET_ALL_LOANS_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				allLoansLoading: false,
			};
			return state;

		case actions.MAKE_LOAN_PAYMENT_START:
			state = {
				...state,
				makeLoanPaymentLoading: true,
			};
			return state;

		case actions.MAKE_LOAN_PAYMENT_DONE:
			state = {
				...state,
				makeLoanPaymentPayload: action.payload?.data?.makeManualRepayment,
				makeLoanPaymentLoading: false,
			};
			return state;

		case actions.MAKE_LOAN_PAYMENT_FAILED:
			errorReducer(action.payload.message);
			state = {
				...state,
				makeLoanPaymentLoading: false,
				makeLoanPaymentPayload: {
					status: "failed",
				},
			};
			return state;

		case actions.RESET_MAKE_LOAN_PAYMENT:
			state = {
				...state,
				makeLoanPaymentLoading: false,
				makeLoanPaymentPayload: {},
			};
			return state;

		case actions.SET_CREDIT_LIMIT_IDS:
			state = {
				...state,
				creditLimitIds: action.payload,
			};
			return state;

		case actions.BULK_UPDATE_CREDIT_LIMIT_START:
			state = {
				...state,
				bulkUpdateCreditLimitLoading: true,
			};
			return state;

		case actions.BULK_UPDATE_CREDIT_LIMIT_DONE:
			const result = decryptPayload(action.payload);

			state = {
				...state,
				bulkUpdateCreditLimitPayload: result,
				bulkUpdateCreditLimitLoading: false,
			};
			return state;

		case actions.BULK_UPDATE_CREDIT_LIMIT_FAILED:
			const failedResult = decryptPayload(action.payload);
			errorReducer(failedResult.message);

			state = {
				...state,
				bulkUpdateCreditLimitLoading: false,
				bulkUpdateCreditLimitPayload: {
					status: "failed",
				},
			};
			return state;

		case actions.RESET_BULK_UPDATE_CREDIT_LIMIT:
			state = {
				...state,
				bulkUpdateCreditLimitLoading: false,
				bulkUpdateCreditLimitPayload: {},
				creditLimitIds: action.payload?.isError ? state.creditLimitIds : [],
			};
			return state;

		default:
			return state;
	}
}
