import { useState } from "react";
import { TabSettingsWide, TabSettingsMobile } from "components/common/settingsTab";
import PersonalCreditSettings from "./credit-tabs/PersonalCreditSettings";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewLoanTypeModal from "./credit-tabs/NewLoanTypeModal";

function CreditSettings() {
    const [currentTab, setCurrentTab] = useState(0);
    const [isNewLoanOpen, setIsNewLoanOpen] = useState(false);

    const toggleNewLoanModal = () => setIsNewLoanOpen(!isNewLoanOpen);

    const tabs = [
        {   id: 1,
            title: "Personal",
            Component: <PersonalCreditSettings />
        },
        {   id: 2,
            title: "Business",
            Component: null
        },
    ]


	return (
        <>
        
        <NewLoanTypeModal
            isOpen={isNewLoanOpen}
            setIsOpen={setIsNewLoanOpen}
        />

		<div>
			<TabSettingsMobile />

			<div className="box">
				<div className="flex">

					<TabSettingsWide />

					<div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">

						<div className="w-full flex items-center justify-between">
							<div className="page-title">Credit Settings</div>

                            <button onClick={toggleNewLoanModal} className="btn btn-md btn-ep-blue">
                                <FontAwesomeIcon icon="plus" className="text-md" />
                                <span>
                                    Add New Loan Type
                                </span>
                            </button>
						</div>

						<div className="w-full mt-6 pb-3 relative flex items-center space-x-5 border-b border-gray-100">
							{tabs.map((item, index) => (
								<div
									onClick={() => item.Component ? setCurrentTab(index) : toast("Coming soon")}
									key={index}
									className="w-max cursor-pointer relative">
									<p
										style={{ color: currentTab === index ? "" : "#aaa" }}
										className={`setting-tab-item ${
											currentTab === index && "text-ep-blue"
										}`}>
										{item?.title}
									</p>
									{currentTab === index && (
										<div
											style={{
												height: "2.5px",
												width: "100%",
												position: "absolute",
												bottom: "-12px",
											}}
											className="bg-ep-blue-deeper"
										/>
									)}
								</div>
							))}
						</div>
                        
						<div className="mt-8 w-full">
                            {tabs[currentTab].Component}
						</div>

					</div>
				</div>
			</div>
		</div>

        </>
	);
}

export default CreditSettings;
