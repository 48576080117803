
export const TOGGLE_LOAN_STATUS_START = "TOGGLE_LOAN_STATUS_START";
export const TOGGLE_LOAN_STATUS_DONE = "TOGGLE_LOAN_STATUS_DONE";
export const TOGGLE_LOAN_STATUS_FAILED = "TOGGLE_LOAN_STATUS_FAILED";

export const GET_CREDIT_METRICS_START = "GET_CREDIT_METRICS_START";
export const GET_CREDIT_METRICS_DONE = "GET_CREDIT_METRICS_DONE";
export const GET_CREDIT_METRICS_FAILED = "GET_CREDIT_METRICS_FAILED";

export const GET_CREDIT_APPLICATION_METRICS_START = "GET_CREDIT_APPLICATION_METRICS_START";
export const GET_CREDIT_APPLICATION_METRICS_DONE = "GET_CREDIT_APPLICATION_METRICS_DONE";
export const GET_CREDIT_APPLICATION_METRICS_FAILED = "GET_CREDIT_APPLICATION_METRICS_FAILED";

export const GET_CREDIT_REQUESTS_START = "GET_CREDIT_REQUESTS_START";
export const GET_CREDIT_REQUESTS_DONE = "GET_CREDIT_REQUESTS_DONE";
export const GET_CREDIT_REQUESTS_FAILED = "GET_CREDIT_REQUESTS_FAILED";

export const GET_CREDIT_VIEWER_START = "GET_CREDIT_VIEWER_START";
export const GET_CREDIT_VIEWER_DONE = "GET_CREDIT_VIEWER_DONE";
export const GET_CREDIT_VIEWER_FAILED = "GET_CREDIT_VIEWER_FAILED";

export const GET_CREDIT_APPLICATION_REQUESTS_START = "GET_CREDIT_APPLICATION_REQUESTS_START";
export const GET_CREDIT_APPLICATION_REQUESTS_DONE = "GET_CREDIT_APPLICATION_REQUESTS_DONE";
export const GET_CREDIT_APPLICATION_REQUESTS_FAILED = "GET_CREDIT_APPLICATION_REQUESTS_FAILED";

export const DOWNLOAD_CREDIT_ITEMS_START = "DOWNLOAD_CREDIT_ITEMS_START";
export const DOWNLOAD_CREDIT_ITEMS_DONE = "DOWNLOAD_CREDIT_ITEMS_DONE";
export const DOWNLOAD_CREDIT_ITEMS_FAILED = "DOWNLOAD_CREDIT_ITEMS_FAILED";

export const GET_CREDIT_DETAILS_START = "GET_CREDIT_DETAILS_START";
export const GET_CREDIT_DETAILS_DONE = "GET_CREDIT_DETAILS_DONE";
export const GET_CREDIT_DETAILS_FAILED = "GET_CREDIT_DETAILS_FAILED";

export const UPDATE_LOAN_LIMIT_START = "UPDATE_LOAN_LIMIT_START";
export const UPDATE_LOAN_LIMIT_DONE = "UPDATE_LOAN_LIMIT_DONE";
export const UPDATE_LOAN_LIMIT_FAILED = "UPDATE_LOAN_LIMIT_FAILED";

export const SELECT_CREDIT_ITEM = "SELECT_CREDIT_ITEM";

export const TOGGLE_LOAN_APPROVAL_REQUEST = "TOGGLE_LOAN_APPROVAL_REQUEST";

export const REQUEST_LOAN_APPROVAL_START = "REQUEST_LOAN_APPROVAL_START";
export const REQUEST_LOAN_APPROVAL_DONE = "REQUEST_LOAN_APPROVAL_DONE";
export const REQUEST_LOAN_APPROVAL_FAILED = "REQUEST_LOAN_APPROVAL_FAILED";

export const TOGGLE_LOAN_APPROVAL_CONFIRMATION = "TOGGLE_LOAN_APPROVAL_CONFIRMATION";

export const APPROVE_LOAN_LIMIT_START = "APPROVE_LOAN_LIMIT_START";
export const APPROVE_LOAN_LIMIT_DONE = "APPROVE_LOAN_LIMIT_DONE";
export const APPROVE_LOAN_LIMIT_FAILED = "APPROVE_LOAN_LIMIT_FAILED";

export const TOGGLE_LOAN_REJECTION_REQUEST = "TOGGLE_LOAN_REJECTION_REQUEST";

export const REQUEST_LOAN_REJECTION_START = "REQUEST_LOAN_REJECTION_START";
export const REQUEST_LOAN_REJECTION_DONE = "REQUEST_LOAN_REJECTION_DONE";
export const REQUEST_LOAN_REJECTION_FAILED = "REQUEST_LOAN_REJECTION_FAILED";

export const TOGGLE_LOAN_REJECTION_CONFIRMATION = "TOGGLE_LOAN_REJECTION_CONFIRMATION";

export const REQUEST_LOAN_RESET_START = "REQUEST_LOAN_RESET_START";
export const REQUEST_LOAN_RESET_DONE = "REQUEST_LOAN_RESET_DONE";
export const REQUEST_LOAN_RESET_FAILED = "REQUEST_LOAN_RESET_FAILED";
export const TOGGLE_LOAN_RESET_CONFIRMATION = "TOGGLE_LOAN_RESET_CONFIRMATION";

export const REJECT_LOAN_LIMIT_START = "REJECT_LOAN_LIMIT_START";
export const REJECT_LOAN_LIMIT_DONE = "REJECT_LOAN_LIMIT_DONE";
export const REJECT_LOAN_LIMIT_FAILED = "REJECT_LOAN_LIMIT_FAILED";

export const TOGGLE_LOAN_UPDATE_REQUEST = "TOGGLE_LOAN_UPDATE_REQUEST";

export const REQUEST_LOAN_LIMIT_UPDATE_START = "REQUEST_LOAN_LIMIT_UPDATE_START";
export const REQUEST_LOAN_LIMIT_UPDATE_DONE = "REQUEST_LOAN_LIMIT_UPDATE_DONE";
export const REQUEST_LOAN_LIMIT_UPDATE_FAILED = "REQUEST_LOAN_LIMIT_UPDATE_FAILED";

export const TOGGLE_LOAN_LIMIT_UPDATE_APPROVAL = "TOGGLE_LOAN_LIMIT_UPDATE_APPROVAL";

export const APPROVE_LOAN_LIMIT_UPDATE_START = "APPROVE_LOAN_LIMIT_UPDATE_START";
export const APPROVE_LOAN_LIMIT_UPDATE_DONE = "APPROVE_LOAN_LIMIT_UPDATE_DONE";
export const APPROVE_LOAN_LIMIT_UPDATE_FAILED = "APPROVE_LOAN_LIMIT_UPDATE_FAILED";

export const TOGGLE_LOAN_LIMIT_UPDATE_REJECTION = "TOGGLE_LOAN_LIMIT_UPDATE_REJECTION";

export const REJECT_LOAN_LIMIT_UPDATE_START = "REJECT_LOAN_LIMIT_UPDATE_START";
export const REJECT_LOAN_LIMIT_UPDATE_DONE = "REJECT_LOAN_LIMIT_UPDATE_DONE";
export const REJECT_LOAN_LIMIT_UPDATE_FAILED = "REJECT_LOAN_LIMIT_UPDATE_FAILED";

export const UPDATE_BANK_START = "UPDATE_BANK_START";
export const UPDATE_BANK_DONE = "UPDATE_BANK_DONE";
export const UPDATE_BANK_FAILED = "UPDATE_BANK_FAILED";

export const UPDATE_BANK_DETAILS_CREDIT_USER = "UPDATE_BANK_DETAILS_CREDIT_USER";

export const GET_ALL_LOANS_START = "GET_ALL_LOANS_START";
export const GET_ALL_LOANS_DONE = "GET_ALL_LOANS_DONE";
export const GET_ALL_LOANS_FAILED = "GET_ALL_LOANS_FAILED";

export const MAKE_LOAN_PAYMENT_START = "MAKE_LOAN_PAYMENT_START";
export const MAKE_LOAN_PAYMENT_DONE = "MAKE_LOAN_PAYMENT_DONE";
export const MAKE_LOAN_PAYMENT_FAILED = "MAKE_LOAN_PAYMENT_FAILED";
export const RESET_MAKE_LOAN_PAYMENT = "RESET_MAKE_LOAN_PAYMENT";

export const SET_CREDIT_LIMIT_IDS = "SET_CREDIT_LIMIT_IDS";
export const BULK_UPDATE_CREDIT_LIMIT_START = "BULK_UPDATE_CREDIT_LIMIT_START";
export const BULK_UPDATE_CREDIT_LIMIT_DONE = "BULK_UPDATE_CREDIT_LIMIT_DONE";
export const BULK_UPDATE_CREDIT_LIMIT_FAILED = "BULK_UPDATE_CREDIT_LIMIT_FAILED";
export const RESET_BULK_UPDATE_CREDIT_LIMIT = "RESET_BULK_UPDATE_CREDIT_LIMIT";