import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from 'utils/ObjectToQueryString';


export function toggleLoanStatus(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiToggleLoanStatus + `${params?.id}`,
            method: "post",
            data: { limitStatus: params?.status },
            onStart: actions.TOGGLE_LOAN_STATUS_START,
            onSuccess: actions.TOGGLE_LOAN_STATUS_DONE,
            onError: actions.TOGGLE_LOAN_STATUS_FAILED,
        },
    }
}

export function getCreditMetrics() {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiGetCreditMetrics,
            method: "get",
            data: {},
            onStart: actions.GET_CREDIT_METRICS_START,
            onSuccess: actions.GET_CREDIT_METRICS_DONE,
            onError: actions.GET_CREDIT_METRICS_FAILED,
        },
    }
}

export function getCreditApplicationMetrics() {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiGetDirectDebitMetrics,
            method: "get",
            data: {},
            onStart: actions.GET_CREDIT_APPLICATION_METRICS_START,
            onSuccess: actions.GET_CREDIT_APPLICATION_METRICS_DONE,
            onError: actions.GET_CREDIT_APPLICATION_METRICS_FAILED,
        },
    }
}

export function getCreditViewerRequests(userId) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiGetCreditViewerRequest + '/' + userId,
            method: "get",
            data: {},
            onStart: actions.GET_CREDIT_VIEWER_START,
            onSuccess: actions.GET_CREDIT_VIEWER_DONE,
            onError: actions.GET_CREDIT_VIEWER_FAILED,
        },
    }
}

export function getCreditRequestDetails(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiGetCreditRequestDetails + `/${params}`,
            method: "get",
            data: {},
            onStart: actions.GET_CREDIT_DETAILS_START,
            onSuccess: actions.GET_CREDIT_DETAILS_DONE,
            onError: actions.GET_CREDIT_DETAILS_FAILED,
        },
    }
}

export function getCreditApplicationRequests(params) {
    console.log('params', params)
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiGetDirectDebitRequest,
            // url: config.apiGetDirectDebitRequest + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_CREDIT_APPLICATION_REQUESTS_START,
            onSuccess: actions.GET_CREDIT_APPLICATION_REQUESTS_DONE,
            onError: actions.GET_CREDIT_APPLICATION_REQUESTS_FAILED,
        },
    }
}

export function getCreditRequests(params) {
    console.log('params', params)
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiGetCreditRequests + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_CREDIT_REQUESTS_START,
            onSuccess: actions.GET_CREDIT_REQUESTS_DONE,
            onError: actions.GET_CREDIT_REQUESTS_FAILED,
        },
    }
}

export function updateLoanLimit(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiToggleLoanStatus + params.id,
            method: "post",
            data: params.data,
            onStart: actions.UPDATE_LOAN_LIMIT_START,
            onSuccess: actions.UPDATE_LOAN_LIMIT_DONE,
            onError: actions.UPDATE_LOAN_LIMIT_FAILED,
        },
    }
}

export function resetLoanLimit(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiResetLoanLimit + params.id,
            method: "get",
            data: {},
            onStart: actions.REQUEST_LOAN_RESET_START,
            onSuccess: actions.REQUEST_LOAN_RESET_DONE,
            onError: actions.REQUEST_LOAN_RESET_FAILED,
        },
    }
}

export function updateBankDetails(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiUpdateUserBank,
            method: "post",
            data: params,
            onStart: actions.UPDATE_BANK_START,
            onSuccess: actions.UPDATE_BANK_DONE,
            onError: actions.UPDATE_BANK_FAILED,
        },
    }
}

export function getAllLoans(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiGetAllLoans + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_ALL_LOANS_START,
            onSuccess: actions.GET_ALL_LOANS_DONE,
            onError: actions.GET_ALL_LOANS_FAILED,
        },
    }
}

export function makeLoanPayment(payload) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: `${config.apiMakeLoanPayment}/${payload.id}`,
            method: "patch",
            data: payload.data,
            onStart: actions.MAKE_LOAN_PAYMENT_START,
            onSuccess: actions.MAKE_LOAN_PAYMENT_DONE,
            onError: actions.MAKE_LOAN_PAYMENT_FAILED,
        },
    }
}

export function bulkUpdateCreditLimit(data) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiCreditBaseUrl,
            url: config.apiBulkLimitStatusUpdate,
            method: "post",
            data,
            onStart: actions.BULK_UPDATE_CREDIT_LIMIT_START,
            onSuccess: actions.BULK_UPDATE_CREDIT_LIMIT_DONE,
            onError: actions.BULK_UPDATE_CREDIT_LIMIT_FAILED,
        },
    }
}

export function setCreditLimitIds(payload) {
    return {
        type: actions.SET_CREDIT_LIMIT_IDS,
        payload,
    }
}

export function selectCreditItem(payload) {
    return {
        type: actions.SELECT_CREDIT_ITEM,
        payload,
    }
}

export function toggleLoanApprovalRequest() {
    return {
        type: actions.TOGGLE_LOAN_APPROVAL_REQUEST,
    }
}

export function toggleLoanApprovalConfirmation() {
    return {
        type: actions.TOGGLE_LOAN_APPROVAL_CONFIRMATION,
    }
}

export function toggleLoanRejectionRequest() {
    return {
        type: actions.TOGGLE_LOAN_REJECTION_REQUEST,
    }
}

export function toggleLoanRejectionConfirmation() {
    return {
        type: actions.TOGGLE_LOAN_REJECTION_CONFIRMATION,
    }
}

export function toggleUpdateBankDetails() {
    return {
        type: actions.UPDATE_BANK_DETAILS_CREDIT_USER,
    }
}

export function toggleLoanResetConfirmation() {
    return {
        type: actions.TOGGLE_LOAN_RESET_CONFIRMATION,
    }
}

export function toggleLoanLimitUpdate() {
    return {
        type: actions.TOGGLE_LOAN_UPDATE_REQUEST,
    }
}

export function toggleLoanLimitUpdateApproval() {
    return {
        type: actions.TOGGLE_LOAN_LIMIT_UPDATE_APPROVAL,
    }
}

export function toggleLoanLimitUpdateRejection() {
    return {
        type: actions.TOGGLE_LOAN_LIMIT_UPDATE_REJECTION,
    }
}

export function resetMakeLoanPayment() {
    return {
        type: actions.RESET_MAKE_LOAN_PAYMENT,
        payload: {},
    }
}

export function resetBulkUpdateCreditLimit(isError) {
    return {
        type: actions.RESET_BULK_UPDATE_CREDIT_LIMIT,
        payload: {
            isError
        },
    }
}