import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactToolTip from 'react-tooltip';
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as DownloadIcon } from "assets/images/icons/project-icons/Download.svg";
import { toast } from "react-toastify";


export const DownloadAsCSV = React.forwardRef((props, ref) => {
    const csvLinkEl = useRef();
    useImperativeHandle(ref, () => ({
        downloadData
    }));

    
    const { tooltip, filename, columns, click, loading, response, label } = props;
    const [result, setResult] = useState([]);
    const [requestedLoaded, setRequestedLoaded] = useState(false);
    const [resultLoaded, setResultLoaded] = useState(false);


    const downloadData = () => {
        setRequestedLoaded(true);
        initiateDownload(true);
    }
    

    useEffect(() => {
        initiateDownload();
        // eslint-disable-next-line
    }, [response])


    const initiateDownload = (request) => {
        const requestLoad = requestedLoaded || request;
        const responseData = response?.docs || [];
        if (requestLoad === true && loading === false && response?.docs !== undefined){
            const data = Object.values(responseData);
            setResult(data);
            setResultLoaded(true);
        }
    }


    useEffect(() => {
        if (resultLoaded === true){
            csvLinkEl.current.link.click();
            toast.success("Downloaded: " + filename);
            setResultLoaded(false);
        }
        // eslint-disable-next-line
    }, [resultLoaded])


    return ( 
        <div>
            <CSVLink filename={filename} data={result} headers={columns} ref={csvLinkEl} />
            
            <div onClick={click} data-tip data-for='download' className="w-9 h-9 flex items-center text-sm box-border-only cursor-pointer">
                {loading && <FontAwesomeIcon icon="spinner" spin className="m-auto" /> }
                {!loading && <DownloadIcon className="customizeSVG m-auto" /> }
                {/* {!loading && <span>Download</span> } */}
                {label && <span className="ml-2">{label}</span> }
            </div>

            {tooltip &&
                <ReactToolTip id='download' className='tooptip-size'>
                    {tooltip}
                </ReactToolTip>
            }
        </div>
    )
})
 

export default DownloadAsCSV;