import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import queryString from 'query-string';
import Pagination from 'components/common/pagination';
import { DateTimeFormat } from "utils/dateFormat";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select'
import pluralize from "utils/pluralize";
import SearchFilter from 'components/common/filter';
import Download from 'components/common/download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as auditTrailActions from "store/entities/auditTrail/action";
import * as employerActions from "store/entities/employers/action";


const List = (props) => {
    
    const dispatch = useDispatch();

    const { auditTrailLoading, auditTrail, downloadAuditTrailLoading, downloadAuditTrail, auditTrailActionsListLoading, auditTrailActionsList } = useSelector(s => s.entities.auditTrail);
    const { loading: allEmployersLoading, allEmployers } = useSelector(s => s.entities.employers);

    const [employerFilter, setEmployerFilter] = useState("");
    const [employerFilterOptions, setEmployerFilterOptions] = useState([]);
    const [actionFilter, setActionFilter] = useState("");
    const [actionFilterOptions, setActionFilterOptions] = useState([]);
    const [auditTrailParams, setAuditTrailParams] = useState({
        page: 1,
        limit: 100,
        type: "",
        name: "",
    });
    const columns = [
        { label: "Date", key: "createdAt" },
        { label: "User Id", key: "user" },
        { label: "User Type", key: "type" },
        { label: "Name", key: "name" },
        { label: "Email Address", key: "email" },
        { label: "Employer Id", key: "employer" },
        { label: "Company Name", key: "employer.companyName" },
        { label: "Action", key: "action" },
        { label: "Description", key: "description" },
        { label: "Request Method", key: "method" },
        { label: "URL Path", key: "path" },
        { label: "IP Address", key: "ip" },
    ];
    const downloadRef = useRef();


    const getAllQueryFilters = (params) => {
        const qs = queryString.parse(props.location.search);
        // params = {...params, populate: "employer"};
        
        if ((qs.type !== undefined) && (qs.type !== null)){
            params = {...params, type: qs.type};
        }
        else{
            params = {...params, type: ""};
        }
        
        if ((qs.keyword !== undefined) && (qs.keyword !== null)){
            params = {...params, keyword: qs.keyword};
        }
        else{
            params = {...params, keyword: ""};
        }
        
        if ((qs.startDate !== undefined) && (qs.startDate !== null)){
            params = {...params, startDate: qs.startDate};
        }
        else{
            params = {...params, startDate: ""};
        }

        if ((qs.endDate !== undefined) && (qs.endDate !== null)){
            params = {...params, endDate: qs.endDate};
        }
        else{
            params = {...params, endDate: ""};
        }

        if ((qs.employerId !== undefined) && (qs.employerId !== null)){
            params = {...params, employer: qs.employerId};
        }
        else{
            params = {...params, employer: ""};
        }

        if ((qs.actionId !== undefined) && (qs.actionId !== null)){
            params = {...params, action: qs.actionId};
        }
        else{
            params = {...params, action: ""};
        }

        // ? run delete empty params
        
        return params;
    }


    const getAuditTrail = () => {
        const qs = queryString.parse(props.location.search);
        let params = auditTrailParams;
        
        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            params = {...params, page: qs.page};
        }
        else{
            params = {...params, page: 1};
        }
        
        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            params = {...params, limit: qs.limit};
        }
        else{
            params = {...params, limit: 100};
        }
        
        params = getAllQueryFilters(params);
        
        setAuditTrailParams(params);
        dispatch(auditTrailActions.getAuditTrail(params));
    }

    
    const getEmployers = () => {
        const params = {
            page: 1,
            limit: 0,
            status: "active",
            name: "",
            download: false,
        }
        dispatch(employerActions.getAllEmployers(params));
    }


    const getAuditActions = () => {
        dispatch(auditTrailActions.getAuditActions());
    }


    const selectEmployerFilter = () => {
        const qs = queryString.parse(props.location.search);
        let employerName = "";
        
        if ((typeof qs.employerId !== undefined) && (qs.employerId !== undefined) && (qs.employerId !== null)){
            let employerFilter = {value: qs.employerId, label: ""};
            // console.log(employerFilterOptions);
            
            if (Object.keys(employerFilterOptions).length > 0){
                Object.keys(employerFilterOptions).forEach((employer) => {
                    if (employerFilterOptions[employer].value === qs.employerId){
                        employerName = employerFilterOptions[employer].label;
                    }
                });

                if (employerName !== ""){
                    employerFilter = {...employerFilter, label: employerName};
                }
                else{
                    toast.error("Invalid employee firm selected");
                }
            }
            setEmployerFilter(employerFilter);
        }
    }


    const employerFilterValue = (employerId) => {
        setEmployerFilter(employerId);
        if (auditTrailParams.type && auditTrailParams.type !== ""){
            gotoUrlQuery({"employerId": employerId.value});
        }
        else{
            gotoUrlQuery({"employerId": employerId.value, type: "employer"});
        }
    }


    const clearEmployerFilterOptions = () => {
        setEmployerFilter("");
        gotoUrlQuery({"employerId": ""});
    }






    const selectAuditActionsFilter = () => {
        const qs = queryString.parse(props.location.search);
        if ((typeof qs.actionId !== undefined) && (qs.actionId !== undefined) && (qs.actionId !== null)){
            let actionFilter = {value: qs.actionId, label: qs.actionId};
            setActionFilter(actionFilter);
        }
    }


    const actionFilterValue = (actionId) => {
        setActionFilter(actionId);
        gotoUrlQuery({"actionId": actionId.value});
    }


    const clearActionFilterOptions = () => {
        setActionFilter("");
        gotoUrlQuery({"actionId": ""});
    }






    const gotoUrlQuery = (params) => {
        const qs = queryString.parse(props.location.search);

        Object.keys(params).forEach((paramName) => {
            // console.log("qs", qs[paramName]);
            delete qs[paramName];
        });

        let qsToUrl = new URLSearchParams(qs).toString();
        qsToUrl = props.location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        Object.keys(params).forEach((paramName) => {
            if (params[paramName] !== ""){
                qsToUrl += paramName + "=" + params[paramName] + "&";
            }
        });

        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        props.history.push(qsToUrl);
    }



    useEffect(() => {
        getAuditTrail();

        // eslint-disable-next-line
    }, [props])
    
    
    useEffect(() => {
        getEmployers();
        selectEmployerFilter();
        getAuditActions();
        selectAuditActionsFilter();

        // eslint-disable-next-line
    }, [])

    
    useEffect(() => {
        if (Object.keys(allEmployers).length > 0){
            const {employers: allEmployersList} = allEmployers?.data;
            let employerFilterOptions = [];
            if ((allEmployersList !== undefined) && (allEmployersList !== null)){
                Object.keys(allEmployersList).forEach(function(key) {
                    employerFilterOptions.push(
                        { value: allEmployersList[key]._id, label: allEmployersList[key].company.companyName }
                    );
                });
            }
            setEmployerFilterOptions(employerFilterOptions);
            selectEmployerFilter();
        }

        // eslint-disable-next-line
    }, [allEmployers])

    
    useEffect(() => {
        if (Object.keys(auditTrailActionsList).length > 0){
            let auditFilterOptions = [];
            auditTrailActionsList.data.forEach(function(action) {
                auditFilterOptions.push(
                    { value: action, label: action }
                );
            });
            setActionFilterOptions(auditFilterOptions);
            selectAuditActionsFilter();
        }

        // eslint-disable-next-line
    }, [auditTrailActionsList])


    return (
        <div>


            <div>
                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title capitalize">
                            Audit Trail
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        <div>
                            <Download
                                tooltip="Download auditTrail list"
                                format="csv"
                                filename="Audit Trail.csv"
                                columns={columns}
                                ref={downloadRef}
                                click={() => {
                                    dispatch(auditTrailActions.downloadGetAuditTrail({...auditTrailParams, page: 1, limit: 0})).then(() => {
                                        downloadRef.current.processDownload();
                                    });
                                }}
                                loading={downloadAuditTrailLoading}
                                response={downloadAuditTrail}
                            />
                        </div>
                        <div>
                            <SearchFilter
                                search={{
                                    name: "keyword",
                                    placeholder: "Keyword search",
                                }}
                                filters={[
                                    {
                                        title: "User Type",
                                        name: "type",
                                        dataType: "radio",
                                        options: [
                                            {
                                                display: "All",
                                                payload: "",
                                            },
                                            {
                                                display: "Admin",
                                                payload: "admin",
                                            },
                                            {
                                                display: "Employer",
                                                payload: "employer",
                                            },
                                            {
                                                display: "Employee",
                                                payload: "employee",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Date",
                                        dataType: "date-range",
                                        options: {
                                            from: {
                                                name: "startDate",
                                                placeholder: "Start Date",
                                            },
                                            to: {
                                                name: "endDate",
                                                placeholder: "End Date",
                                            },
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>



                <div className="w-full xl:flex xl:justify-between mt-6 mb-4 sm:mb-0">

                    <div className="flex space-x-3">
                        <div className="flex space-x-3">
                            <div>
                                {(allEmployersLoading === true) && 
                                    <div className="w-full px-3 py-2 border border-gray-200 rounded">
                                        <FontAwesomeIcon icon="spinner" spin className="mr-1.5" />
                                        Loading
                                    </div>
                                }
                                {(allEmployersLoading === false) && 
                                    <div className="w-full">
                                        <Select options={employerFilterOptions} placeholder="Search Employees Firm" value={employerFilter} onChange={employerFilterValue} />
                                    </div>
                                }
                            </div>
                            <div className="lg:col-span-2 flex">
                                {(allEmployersLoading === false) && (employerFilter !== "") &&
                                    <div className="mx-auto sm:mx-0 my-auto flex text-sm">
                                        <div className="hidden mr-6">
                                            {auditTrail.totalDocs} {pluralize("result", auditTrail.totalDocs)} found
                                        </div>
                                        <div onClick={clearEmployerFilterOptions} className="cursor-pointer text-red-500 border-b-2 border-transparent hover:border-red-500">
                                            <FontAwesomeIcon icon="times" className="mr-1" />
                                            Clear Filter
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="flex space-x-3">
                            <div>
                                {(auditTrailActionsListLoading === true) && 
                                    <div className="w-full px-3 py-2 border border-gray-200 rounded">
                                        <FontAwesomeIcon icon="spinner" spin className="mr-1.5" />
                                        Loading
                                    </div>
                                }
                                {(auditTrailActionsListLoading === false) && 
                                    <div className="w-full">
                                        <Select options={actionFilterOptions} placeholder="Search Audit Action" value={actionFilter} onChange={actionFilterValue} />
                                    </div>
                                }
                            </div>
                            <div className="lg:col-span-2 flex">
                                {(auditTrailActionsListLoading === false) && (actionFilter !== "") &&
                                    <div className="mx-auto sm:mx-0 my-auto flex text-sm">
                                        <div className="hidden mr-6">
                                            {auditTrail.totalDocs} {pluralize("result", auditTrail.totalDocs)} found
                                        </div>
                                        <div onClick={clearActionFilterOptions} className="cursor-pointer text-red-500 border-b-2 border-transparent hover:border-red-500">
                                            <FontAwesomeIcon icon="times" className="mr-1" />
                                            Clear Filter
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="xl:flex xl:justify-end mt-1 xl:mt-0">
                        <div className="w-full xl:w-auto inline-block box-border-only overflow-auto">
                            <div className="h-10 flex">
                                <div onClick={() => gotoUrlQuery({"type": ""})} className={"cursor-pointer page-nav " + ((auditTrailParams.type === "") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        All
                                    </div>
                                </div>
                                <div onClick={() => gotoUrlQuery({"type": "admin"})} className={"cursor-pointer page-nav " + ((auditTrailParams.type === "admin") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        Admin
                                    </div>
                                </div>
                                <div onClick={() => gotoUrlQuery({"type": "employer"})} className={"cursor-pointer page-nav " + ((auditTrailParams.type === "employer") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        Employer
                                    </div>
                                </div>
                                <div onClick={() => gotoUrlQuery({"type": "employee"})} className={"cursor-pointer page-nav " + ((auditTrailParams.type === "employee") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        Employee
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="mt-2">
                    <div className="">

                        <div className="table-container">
                            <table className="table table-auto table-rounded table-border">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>User Type</th>
                                        <th>Company</th>
                                        <th>User</th>
                                        <th>Activity</th>
                                        <th>Resource</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {(auditTrailLoading === true) && 
                                        <tr>
                                            <td colSpan="6">
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                    <div className="font-bold uppercase">
                                                        Loading
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    {((auditTrailLoading === false) && (!auditTrail.docs)) && 
                                        <tr>
                                            <td colSpan="6">
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="unlink" />
                                                    <div className="font-bold uppercase">
                                                        An error occurred
                                                        <br />
                                                        Please try again later.
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    
                                    {((auditTrailLoading === false) && (auditTrail.docs) && (auditTrail.docs.length <= 0)) && 
                                        <tr>
                                            <td colSpan="6">

                                                {(auditTrail.page <= auditTrail.totalPages) &&
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="list" />
                                                        <div className="font-bold uppercase">
                                                            No results found
                                                        </div>
                                                    </div>
                                                }
                                                
                                                {(auditTrail.page > auditTrail.totalPages) &&
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="exclamation-triangle" />
                                                        <div className="font-bold uppercase">
                                                            Invalid Page Number
                                                        </div>
                                                        <div className="mt-2">
                                                            <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                                        </div>
                                                    </div>
                                                }

                                            </td>
                                        </tr>
                                    }
                                    
                                    {((auditTrailLoading === false) && (auditTrail.docs) && (auditTrail.docs.length > 0)) && 
                                        auditTrail.docs.map((log, index) =>
                                            <tr key={index}>
                                                <td>
                                                    <div>
                                                        {DateTimeFormat(log.createdAt)}
                                                    </div>
                                                </td>
                                                <td>
                                                    {(() => {
                                                        if (log.type === "admin"){
                                                            return <div className="label label-blue uppercase">Admin</div>
                                                        }
                                                        else if (log.type === "employer"){
                                                            return <div className="label label-green uppercase">Employer</div>
                                                        }
                                                        else if (log.type === "employee"){
                                                            return <div className="label label-yellow uppercase">Employee</div>
                                                        }
                                                        else {
                                                            return <div className="label label-gray uppercase">{log.type}</div>
                                                        }
                                                    })()}
                                                </td>
                                                <td>
                                                    <div>
                                                        {log.employer ? log.employer.companyName : ""}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {log.name}
                                                    </div>
                                                    <div className="text-fade">
                                                        <Link to={{pathname:"mailto:" + log.email}} target="_blank" onClick={(e) => {window.open(e.target.getAttribute("href"), '_blank'); e.preventDefault(); }} className="hover:underline">{log.email}</Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {log.action}
                                                    </div>
                                                    <div className="text-fade">
                                                        {log.description}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {log.method}
                                                    </div>
                                                    <div className="break-all">
                                                        {log.path}
                                                    </div>
                                                    <div className="text-fade capitalize-first-letter">
                                                        {log.ip}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    
                                </tbody>
                            </table>
                        </div>

                        {((auditTrailLoading === false) && (auditTrail.docs) && (auditTrail.docs.length > 0)) && 
                            <div>
                                <Pagination data={auditTrail} url={props.location.search} limit={auditTrailParams.limit} />
                            </div>
                        }

                    </div>
                </div>


            </div>
        </div>
    )
    
    
}

export default List;