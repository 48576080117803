import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from "utils/ObjectToQueryString";


export function getBusinessMetrics() {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetEmployersCount,
			method: "get",
			data: {},
			onStart: actions.GET_BUSINESSES_METRICS_START,
			onSuccess: actions.GET_BUSINESSES_METRICS,
			onError: actions.GET_BUSINESSES_METRICS_FAILED,
		},
	};
}

export function getBusinesses(params) {
	let endpointUrl = config.apiGetEmployers;
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiSearchAndFilterEmployers;
    }
	return {
		type: config.apiRequestStart,
		payload: {
			url: endpointUrl + ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.GET_BUSINESSES_START,
			onSuccess: actions.GET_BUSINESSES,
			onError: actions.GET_BUSINESSES_FAILED,
		},
	};
}

export function downloadBusinesses(params) {
	let endpointUrl = config.apiGetEmployers;
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiSearchAndFilterEmployers;
    }
	return {
		type: config.apiRequestStart,
		payload: {
			url: endpointUrl + ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.DOWNLOAD_GET_BUSINESSES_START,
			onSuccess: actions.DOWNLOAD_GET_BUSINESSES,
			onError: actions.DOWNLOAD_GET_BUSINESSES_FAILED,
		},
	};
}

export function getBusiness(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetEmployer + id,
			method: "get",
			data: {},
			onStart: actions.GET_BUSINESS_START,
			onSuccess: actions.GET_BUSINESS,
			onError: actions.GET_BUSINESS_FAILED,
		},
	};
}


export function approveCompliance(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiApproveEmployer + id,
			method: "post",
			data: {},
			onStart: actions.APPROVE_COMPLIANCE_START,
			onSuccess: actions.APPROVE_COMPLIANCE,
			onError: actions.APPROVE_COMPLIANCE_FAILED,
		},
	};
}

export function resetApproveCompliance(){
    return {
        type: actions.APPROVE_COMPLIANCE_RESET,
        payload: {},
    }
}

export function rejectCompliance(id, params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiRejectEmployerOnboarding + id,
			method: "post",
			data: params,
			onStart: actions.REJECT_COMPLIANCE_START,
			onSuccess: actions.REJECT_COMPLIANCE,
			onError: actions.REJECT_COMPLIANCE_FAILED,
		},
	};
}

export function resetRejectCompliance(){
    return {
        type: actions.REJECT_COMPLIANCE_RESET,
        payload: {},
    }
}
