import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../common/form";
import FormDrawer from "../common/form-drawer--new";
import { ReactComponent as IconCircleQuestion } from "assets/images/icons/project-icons/circle-question.svg";
import { toggleLoanApprovalConfirmation, bulkUpdateCreditLimit } from "store/entities/credit/action";
import { encryptPayload } from "utils/encrypt_decrypt";


const LoanApprovalConfirmation = () => {
	
	const dispatch = useDispatch();
	// eslint-disable-next-line
	const { bulkUpdateCreditLimitLoading: loading, creditLimitIds, isApprovalConfirmationOpen, bulkUpdateCreditLimitPayload } = useSelector((state) => state.entities.credit);

	const toggleFn = () => dispatch(toggleLoanApprovalConfirmation());
	
	const onProceed = () => { 
		const payload = {
			limitIds: creditLimitIds,
			limitStatus: "approved",
			note: "bulk approved"
		}
		const encryptedPayload = encryptPayload(payload);
		dispatch(bulkUpdateCreditLimit(encryptedPayload))
	}

	useEffect(() => {
		if (bulkUpdateCreditLimitPayload?.data?.unresolvedLimits?.length === 0 && isApprovalConfirmationOpen) {
			toggleFn();
		}
	}, [bulkUpdateCreditLimitPayload]);
	
	
	return (
		<FormDrawer display="center" size="sm" isOpen={isApprovalConfirmationOpen} setIsOpen={() => {}}>
			<div className="p-5 max-w-sm">
				<div className="flex items-center justify-center flex-col pb-8">
					<div className="text-center">
						<IconCircleQuestion />
					</div>
					
					<h1 className="mt-5 mb-2 font-semibold">
						Approve Loan Limit
					</h1>
					
					<p className="mb-0 text-center">Are you sure you want to bulk approve {creditLimitIds.length} Loan limit{creditLimitIds.length > 1 ? "s" : ""}?</p>
				</div>
				
				<div className="flex items-center justify-center lg:flex-row pb-2">
					
					<button
						onClick={toggleFn}
						className="btn btn-md border border-ep-primary-500 text-ep-primary-500 focus:ring-0 w-full lg:w-2/4 mr-4"
						disabled={loading}>
						Cancel
					</button>
					
					<ButtonLoader
						loading={loading}
						onClick={onProceed}
						className="z-5 btn btn-md border bg-ep-primary text-white focus:ring-0 w-full lg:w-2/4">
						{loading ? "Submitting..." : "Yes"}
					</ButtonLoader>
				</div>
			</div>
		</FormDrawer>
	)
}

export default LoanApprovalConfirmation