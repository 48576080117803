import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { toast } from "react-toastify";

const initialState = {
	loading: false,
	allEmployees: {},
	allEmployeesCountLoading: false,
	allEmployeesCount: {},
	enrolledEmployeesCountLoading: false,
	enrolledEmployeesCount: {},
	unenrolledEmployeesCountLoading: false,
	unenrolledEmployeesCount: {},
	downloadLoading: false,
	downloadAllEmployees: {},
	reloadPage: false,

	employeesWithdrawOnceOrMoreLoading: false,
	employeesWithdrawOnceOrMore: {},
	downloadEmployeesWithdrawOnceOrMoreLoading: false,
	downloadEmployeesWithdrawOnceOrMore: {},

	employeesDetailsLoading: false,
	employeesDetails: {},
	acceptedEmployeesLoading: false,
	acceptedEmployees: {},
	employeeActivateLoading: false,
	employeeDeactivateLoading: false,
	employeeArchiveLoading: false,

	generatedERALoading: false,
	generatedERA: {},

	withdrawalChargeLoading: false,
	withdrawalChargeStatus: {},
	
	allCustomersLoading: false,
	allCustomersData: {},
	selectedCustomer: {},
	
	singleCustomerLoading: false,
	singleCustomerData: {},
	
	toggleCustomerStatusLoading: false,
	toggleCustomerStatusData: {},
	
	downloadAllCustomersLoading: false,
	downloadAllCustomersData: {},
	
	customerTransactionsLoading: false,
	customerTransactionsData: {},
	
	customerTransactionsMetricsLoading: false,
	customerTransactionsMetricsData: {},
	
	downloadCustomerTransactionsLoading: false,
	downloadCustomerTransactionsData: {},
	toggleCustomerKycLoading: false,
	
	manualUpdateUserDetailsLoading: false,
	manualUpdateUserDetailsData: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case actions.USER_DETAILS_MANUAL_UPDATE_START:
			state = {
				...state,
				manualUpdateUserDetailsLoading: true,
				manualUpdateUserDetailsData: null,
			};
			return state;

		case actions.USER_DETAILS_MANUAL_UPDATE_DONE:
			toast.success(action.payload.message);
			state = {
				...state,
				manualUpdateUserDetailsLoading: false,
				manualUpdateUserDetailsData: action.payload,
			};
			return state;

		case actions.USER_DETAILS_MANUAL_UPDATE_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				manualUpdateUserDetailsLoading: false,
				manualUpdateUserDetailsData: null,
			};
			return state;
		
		case actions.DOWNLOAD_CUSTOMER_TRANSACTIONS_START:
			state = {
				...state,
				downloadCustomerTransactionsLoading: true,
				downloadCustomerTransactionsData: {},
			};
			return state;

		case actions.DOWNLOAD_CUSTOMER_TRANSACTIONS_DONE:
			state = {
				...state,
				downloadCustomerTransactionsLoading: false,
				downloadCustomerTransactionsData: action.payload,
			};
			return state;

		case actions.DOWNLOAD_CUSTOMER_TRANSACTIONS_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				downloadCustomerTransactionsLoading: false,
				downloadCustomerTransactionsData: {},
			};
			return state;
		
		case actions.GET_CUSTOMER_TRANSACTIONS_METRICS_START:
			state = {
				...state,
				customerTransactionsMetricsLoading: true,
				customerTransactionsMetricsData: {},
			};
			return state;

		case actions.GET_CUSTOMER_TRANSACTIONS_METRICS_DONE:
			state = {
				...state,
				customerTransactionsMetricsLoading: false,
				customerTransactionsMetricsData: action.payload,
			};
			return state;

		case actions.GET_CUSTOMER_TRANSACTIONS_METRICS_FAILED:
			if(!action?.payload?.message?.includes("Wallet not found") &&
				!action?.payload?.message?.includes("User not found")) {
				toast.error(action.payload.message);
			}
			state = {
				...state,
				customerTransactionsMetricsLoading: false,
				customerTransactionsMetricsData: {},
			};
			return state;
		
		case actions.GET_CUSTOMER_TRANSACTIONS_START:
			state = {
				...state,
				customerTransactionsLoading: true,
				customerTransactionsData: {},
			};
			return state;

		case actions.GET_CUSTOMER_TRANSACTIONS_DONE:
			state = {
				...state,
				customerTransactionsLoading: false,
				customerTransactionsData: action.payload,
			};
			return state;

		case actions.GET_CUSTOMER_TRANSACTIONS_FAILED:
			if(!action?.payload?.message?.includes("Wallet not found") &&
				!action?.payload?.message?.includes("User not found")) {
				toast.error(action.payload.message);
			}
			state = {
				...state,
				customerTransactionsLoading: false,
				customerTransactionsData: {},
			};
			return state;
		
		case actions.TOGGLE_CUSTOMER_KYC_START:
			state = {
				...state,
				toggleCustomerKycLoading: true,
			};
			return state;

		case actions.TOGGLE_CUSTOMER_KYC_DONE:
			toast.success(action.payload.message);
			state = {
				...state,
				toggleCustomerKycLoading: false,
			};
			return state;

		case actions.TOGGLE_CUSTOMER_KYC_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				toggleCustomerKycLoading: false,
			};
			return state;

		case actions.SELECT_CUSTOMER:
			state = {
				...state,
				selectedCustomer: action.payload,
			};
			return state;

		case actions.DOWNLOAD_ALL_CUSTOMERS_START:
			state = {
				...state,
				downloadAllCustomersLoading: true,
				downloadAllCustomersData: {},
			};
			return state;

		case actions.DOWNLOAD_ALL_CUSTOMERS_DONE:
			const transformedData = {
				...action.payload,
				data: {
					...action.payload.data,
					docs: action.payload?.data?.users?.map((user) => ({
						...user,
						dateJoined: user?.createdAt,
						fullName: `${user?.firstName ?? "-"} ${user?.lastName ?? "-"}`,
						email: user?.email,
						phone: user?.phoneNumber,
						points: user?.points,
						kycStatus: user?.kycStatus,
						userType: user?.userType,
						employeeId: user?.employeeId,
					}))
				}
			}
			
			state = {
				...state,
				downloadAllCustomersLoading: false,
				downloadAllCustomersData: transformedData,
			};
			return state;

		case actions.DOWNLOAD_ALL_CUSTOMERS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				downloadAllCustomersLoading: false,
				downloadAllCustomersData: {},
			};
			return state;

		case actions.GET_SINGLE_CUSTOMER_START:
			state = {
				...state,
				singleCustomerLoading: true,
				singleCustomerData: {},
			};
			return state;

		case actions.GET_SINGLE_CUSTOMER_DONE:
			state = {
				...state,
				singleCustomerLoading: false,
				singleCustomerData: action.payload,
			};
			return state;

		case actions.GET_SINGLE_CUSTOMER_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				singleCustomerLoading: false,
				singleCustomerData: {},
			};
			return state;

		case actions.GET_ALL_CUSTOMERS_START:
			state = {
				...state,
				allCustomersLoading: true,
				allCustomersData: {},
			};
			return state;

		case actions.GET_ALL_CUSTOMERS_DONE:
			state = {
				...state,
				allCustomersLoading: false,
				allCustomersData: action.payload,
			};
			return state;

		case actions.GET_ALL_CUSTOMERS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				allCustomersLoading: false,
				allCustomersData: {},
			};
			return state;

		case actions.TOGGLE_CUSTOMER_STATUS_START:
			toast("Submitting...")
			state = {
				...state,
				toggleCustomerStatusLoading: true,
				toggleCustomerStatusData: {},
			};
			return state;

		case actions.TOGGLE_CUSTOMER_STATUS_DONE:
			toast.success(action.payload.message)
			state = {
				...state,
				toggleCustomerStatusLoading: false,
				toggleCustomerStatusData: action.payload,
			};
			return state;

		case actions.TOGGLE_CUSTOMER_STATUS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				toggleCustomerStatusLoading: false,
				toggleCustomerStatusData: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYEES:
			state = {
				...state,
				loading: false,
				allEmployees: { ...action.payload.employees },
			};
			return state;

		case actions.GET_ALL_EMPLOYEES_START:
			state = {
				...state,
				loading: true,
				allEmployees: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYEES_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				loading: false,
				allEmployees: {},
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_EMPLOYEES:
			state = {
				...state,
				downloadLoading: false,
				downloadAllEmployees: { ...action.payload.employees },
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_EMPLOYEES_START:
			toast("Downloading Data...");
			state = {
				...state,
				downloadLoading: true,
				downloadAllEmployees: {},
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_EMPLOYEES_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				downloadLoading: false,
				downloadAllEmployees: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYEES_COUNT:
			state = {
				...state,
				allEmployeesCountLoading: false,
				allEmployeesCount: { ...action.payload.employeeCount },
			};
			return state;

		case actions.GET_ALL_EMPLOYEES_COUNT_START:
			state = {
				...state,
				allEmployeesCountLoading: true,
				allEmployeesCount: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYEES_COUNT_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				allEmployeesCountLoading: false,
				allEmployeesCount: {},
			};
			return state;

		case actions.GET_ENROLLED_EMPLOYEES_COUNT:
			state = {
				...state,
				enrolledEmployeesCountLoading: false,
				enrolledEmployeesCount: { ...action.payload.employees },
			};
			return state;

		case actions.GET_ENROLLED_EMPLOYEES_COUNT_START:
			state = {
				...state,
				enrolledEmployeesCountLoading: true,
				enrolledEmployeesCount: {},
			};
			return state;

		case actions.GET_ENROLLED_EMPLOYEES_COUNT_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				enrolledEmployeesCountLoading: false,
				enrolledEmployeesCount: {},
			};
			return state;

		case actions.GET_UNENROLLED_EMPLOYEES_COUNT:
			state = {
				...state,
				unenrolledEmployeesCountLoading: false,
				unenrolledEmployeesCount: { ...action.payload.employees },
			};
			return state;

		case actions.GET_UNENROLLED_EMPLOYEES_COUNT_START:
			state = {
				...state,
				unenrolledEmployeesCountLoading: true,
				unenrolledEmployeesCount: {},
			};
			return state;

		case actions.GET_UNENROLLED_EMPLOYEES_COUNT_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				unenrolledEmployeesCountLoading: false,
				unenrolledEmployeesCount: {},
			};
			return state;

		case actions.GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE:
			// console.log("action.payload", action.payload);
			state = {
				...state,
				employeesWithdrawOnceOrMoreLoading: false,
				employeesWithdrawOnceOrMore: { ...action.payload },
			};
			return state;

		case actions.GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_START:
			state = {
				...state,
				employeesWithdrawOnceOrMoreLoading: true,
				employeesWithdrawOnceOrMore: {},
			};
			return state;

		case actions.GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				employeesWithdrawOnceOrMoreLoading: false,
				employeesWithdrawOnceOrMore: {},
			};
			return state;

		case actions.DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE:
			// console.log("download action.payload", action.payload);
			state = {
				...state,
				downloadEmployeesWithdrawOnceOrMoreLoading: false,
				downloadEmployeesWithdrawOnceOrMore: { ...action.payload },
			};
			return state;

		case actions.DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_START:
			state = {
				...state,
				downloadEmployeesWithdrawOnceOrMoreLoading: true,
				downloadEmployeesWithdrawOnceOrMore: {},
			};
			return state;

		case actions.DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				downloadEmployeesWithdrawOnceOrMoreLoading: false,
				downloadEmployeesWithdrawOnceOrMore: {},
			};
			return state;

		case actions.EMPLOYEE_DETAILS:
			// toast.success(action.payload.message);
			state = {
				...state,
				employeesDetailsLoading: false,
				employeesDetails: action.payload.employee,
			};
			return state;

		case actions.EMPLOYEE_DETAILS_START:
			state = {
				...state,
				employeesDetailsLoading: true,
				employeesDetails: {},
			};
			return state;

		case actions.EMPLOYEE_DETAILS_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employeesDetailsLoading: false,
				employeesDetails: {},
			};
			return state;

		case actions.ENABLE_EMPLOYEE:
			toast.success("Employee Activated");
			// toast.success(action.payload.message);
			state = {
				...state,
				employeeActivateLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.ENABLE_EMPLOYEE_START:
			toast("Activating Employee");
			state = {
				...state,
				employeeActivateLoading: true,
			};
			return state;

		case actions.ENABLE_EMPLOYEE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employeeActivateLoading: false,
			};
			return state;

		case actions.DISABLE_EMPLOYEE:
			toast.success("Employee Deactivated");
			// console.log(action.payload);
			state = {
				...state,
				employeeDeactivateLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.DISABLE_EMPLOYEE_START:
			toast("Deactivating Employee");
			state = {
				...state,
				employeeDeactivateLoading: true,
			};
			return state;

		case actions.DISABLE_EMPLOYEE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employeeDeactivateLoading: false,
			};
			return state;

		case actions.ARCHIVE_EMPLOYEE:
			toast.success("Employee Deleted");
			// console.log(action.payload);
			state = {
				...state,
				employeeArchiveLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.ARCHIVE_EMPLOYEE_START:
			toast("Deleting Employee");
			state = {
				...state,
				employeeArchiveLoading: true,
			};
			return state;

		case actions.ARCHIVE_EMPLOYEE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employeeArchiveLoading: false,
			};
			return state;

		// already specified in employee reducer
		/*
        case actions.RESEND_INVITE:
            toast.success("Invite Sent");
            state = {
                ...state,
            }
            return state;


        case actions.RESEND_INVITE_START:
            toast("Resending invite");
            state = {
                ...state,
            }
            return state;
            
        case actions.RESEND_INVITE_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
            }
            return state;
        */

		// already specified in employer reducer
		/*
        case actions.GENERATE_ERA:
            toast.success("Reconciliation Account Generated");
            state = {
                ...state,
                generatedERALoading: false,
                generatedERA: action.payload,
            }
            return state;


        case actions.GENERATE_ERA_START:
            state = {
                ...state,
                generatedERALoading: true,
                generatedERA: {},
            }
            return state;


        case actions.GENERATE_ERA_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                generatedERALoading: false,
                generatedERA: {},
            }
            return state;
        */

		case actions.WITHDRAWAL_CHARGE:
			toast.success("Withdrawal charged successfully");
			state = {
				...state,
				withdrawalChargeLoading: false,
				withdrawalChargeStatus: action.payload,
			};
			return state;

		case actions.WITHDRAWAL_CHARGE_START:
			state = {
				...state,
				withdrawalChargeLoading: true,
				withdrawalChargeStatus: {},
			};
			return state;

		case actions.WITHDRAWAL_CHARGE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				withdrawalChargeLoading: false,
				withdrawalChargeStatus: {},
			};
			return state;

		case actions.RELOAD_PAGE_RESET:
			state = {
				...state,
				reloadPage: false,
			};
			return state;

		default:
			return state;
	}
}
