import FormDrawer from "../common/form-drawer--new";

const RejectAddressVerificationModal = ({isOpen, setIsOpen, onProceed, loading, user}) => {
	
	return (
		<FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={() => {}}>
			<div className="p-5 max-w-md">
				<h1 className="lg:text-lg font-semibold">Reject Address Verification</h1>
				
				<div className="form-group mt-6 mb-10">
					<label>
						Are you sure you want to reject address verification for <span className="font-semibold capitalize"> {user?.firstName} {user?.lastName}?</span>
					</label>
				</div>
				
				<div className="flex items-center justify-center lg:flex-row lg:space-x-2">
					
					<button
						type="button"
						onClick={() => setIsOpen(false)}
						className="btn btn-md border border-ep-primary-500 text-ep-primary-500 w-full lg:w-2/4 focus:ring-0 mr-4"
						disabled={loading}>
						Cancel
					</button>
					
					<button
						disabled={loading}
						type="button" 
						onClick={onProceed}
						className="btn btn-md border bg-ep-primary text-white w-full lg:w-2/4 focus:ring-0">
						{loading ? "Submitting..." : "Yes"}
					</button>
				</div>
			</div>
		</FormDrawer>
	)
}

export default RejectAddressVerificationModal