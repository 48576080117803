import React, { Component, useState, Fragment } from "react";
import Joi from "joi-browser";
import queryString from "query-string";
import DashboardBoxes from "components/common/dashboard-boxes";
import Dropdown from "utils/dropdown";
import store from "store/store";
import Pagination from "components/common/pagination";
import DialogBox from "utils/dialogBox";
import Download from "components/common/download";
import SearchFilter from "components/common/filter";
import { Link } from "react-router-dom";
import DateFormat, { DateTimeFormat } from "utils/dateFormat";
import { customizeSVG } from "utils/customizeSVG.jsx";
import { encrypt } from "utils/encrypt_decrypt";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { renderStatusColor } from "utils/statusColor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconEmployees } from "assets/images/icons/project-icons/employers.svg";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import { ReactComponent as IconFileUpload } from "assets/images/icons/project-icons/file-upload.svg";
import { ReactComponent as IconDownload } from "assets/images/icons/project-icons/download-green.svg";
import { ReactComponent as IconComment } from "assets/images/icons/project-icons/comment.svg";
import { ReactComponent as IconApproved } from "assets/images/icons/project-icons/onboarding-approved.svg";
import { ReactComponent as IconRejected } from "assets/images/icons/project-icons/onboarding-rejected.svg";
import { ReactComponent as IconCheck } from "assets/images/icons/project-icons/circle-check.svg";
import * as employers from "store/entities/employers/action";
import * as settings from "store/entities/settings/action";
import CenterModal from "components/misc/CenterModal";
import SetEarningsModal from "components/payments/SetEarningsModal";
import Maybe from "components/__new/common/Maybe";
import numberOrdinalSuffix from "utils/numberOrdinalSuffix";
import nameToSentenceCase from "utils/nameToSentenceCase";

const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);

const columns = [
	{ label: "Employer ID", key: "_id" },
	{ label: "Date Joined", key: "updatedAt" },
	{ label: "Business Name", key: "company.companyName" },
	{ label: "First Name", key: "firstName" },
	{ label: "Last Name", key: "lastName" },
	{ label: "Email Address", key: "email" },
	{ label: "Phone Number", key: "phoneNumber" },
	{ label: "Job Title", key: "jobTitle" },
	{ label: "Status", key: "status" },
	{ label: "Active", key: "isActive" },
	{ label: "Compliant", key: "isCompliant" },
	{ label: "Email Verified", key: "isEmailVerified" },
	{ label: "Disabled", key: "disabled" },
	{ label: "User Type", key: "userType" },
	{ label: "Wallet ID", key: "walletId" },
];

const truncate = (text) => text?.length > 9 ? text?.substring(0,9) + "..." : text;

class BusinessesList extends Component {
	state = {
		getAllEmployersParams: {
			page: 1,
			limit: 20,
			download: false,
			status: "",
			name: "",
			keyword: "",
			registeredFrom: "",
			registeredTo: "",
		},
		dashboardProps1: {
			iconBg: "bg-blue-200",
			icon: (
				<IconEmployees className="customizeSVG text-blue-600 m-auto w-5 h-5" />
			),
			titleText: "Total Businesses",
			mainText: "-",
		},
		dashboardProps2: {
			iconBg: "bg-green-200",
			icon: (
				<FontAwesomeIcon
					icon="check-circle"
					className="customizeSVG text-green-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Active Businesses",
			mainText: "-",
		},
		dashboardProps3: {
			iconBg: "bg-red-200",
			icon: (
				<FontAwesomeIcon
					icon="times-circle"
					className="customizeSVG text-red-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Approved Employers",
			mainText: "-",
		},
		dashboardProps4: {
			iconBg: "bg-red-200",
			icon: (
				<FontAwesomeIcon
					icon="times-circle"
					className="customizeSVG text-red-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Inactive Businesses",
			mainText: "-",
		},
		dashboardProps5: {
			iconBg: "bg-red-200",
			icon: (
				<FontAwesomeIcon
					icon="times-circle"
					className="customizeSVG text-red-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Disabled Employers",
			mainText: "-",
		},
		searchErrors: "",
		storeUnsubscribe: "",
		toggleModalDetails: false,
		toggleModalFee: false,
		modalDetailsAction: "",
		employerDetails: "",
		isRejectionModalOpen: false,
		selectedEmployer: null,
		selectedComment: null,
		selectedOnDemandRejectionComment: null,

		isOdpRejectOpen: false,
		isOdpApprovalOpen: false,

		feeId: "",
		feeForm: {
			fee: "",
			employerId: "",
			minAmount: "",
			maxAmount: "",
		},
		showCreateForm: false,
		showDialogBox: false,
		errors: {},
		isEarningsModalOpen: false,
		hasMultiEmployees: false,
		selectedEmployerName: "",
		selectedEmployerId: "",
	};

	gotoUrlQuery = (params) => {
		const qs = queryString.parse(this.props.location.search);

		Object.keys(params).forEach((paramName) => {
			delete qs[paramName];
		});

		let qsToUrl = new URLSearchParams(qs).toString();
		qsToUrl =
			this.props.location.pathname +
			"?" +
			(qsToUrl !== "" ? qsToUrl + "&" : "");

		Object.keys(params).forEach((paramName) => {
			if (params[paramName] !== "") {
				qsToUrl += paramName + "=" + params[paramName] + "&";
			}
		});

		qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
		this.props.history.push(qsToUrl);
	};

	getAllQueryFilters = (params) => {
		const qs = queryString.parse(this.props.location.search);

		if (qs.keyword !== undefined && qs.keyword !== null) {
			params = { ...params, keyword: qs.keyword };
		} else {
			params = { ...params, keyword: "" };
		}

		if (qs.disabled !== undefined && qs.disabled !== null) {
			params = { ...params, disabled: qs.disabled === "true" ? true : false };
		} else {
			params = { ...params, disabled: "" };
		}

		if (qs.registeredFrom !== undefined && qs.registeredFrom !== null) {
			params = { ...params, registeredFrom: qs.registeredFrom };
		} else {
			params = { ...params, registeredFrom: "" };
		}

		if (qs.registeredTo !== undefined && qs.registeredTo !== null) {
			params = { ...params, registeredTo: qs.registeredTo };
		} else {
			params = { ...params, registeredTo: "" };
		}

		return params;
	};

	getAllEmployers = () => {
		const qs = queryString.parse(this.props.location.search);
		let getAllEmployersParams = { ...this.state.getAllEmployersParams };
		// let searchQuery = "";

		if (qs.page !== undefined && !isNaN(qs.page) && qs.page > 0) {
			getAllEmployersParams = { ...getAllEmployersParams, page: qs.page };
		} else {
			getAllEmployersParams = { ...getAllEmployersParams, page: 1 };
		}

		if (qs.limit !== undefined && !isNaN(qs.limit) && qs.limit > 0) {
			getAllEmployersParams = { ...getAllEmployersParams, limit: qs.limit };
		} else {
			getAllEmployersParams = { ...getAllEmployersParams, limit: 20 };
		}

		if (qs.status !== undefined && qs.status !== null) {
			getAllEmployersParams = { ...getAllEmployersParams, status: qs.status };
		} else {
			getAllEmployersParams = { ...getAllEmployersParams, status: "" };
		}

		if (qs.features !== undefined && qs.features !== null) {
			getAllEmployersParams = { ...getAllEmployersParams, features: qs.features };
		} else {
			getAllEmployersParams = { ...getAllEmployersParams, features: "" };
		}

		getAllEmployersParams = this.getAllQueryFilters(getAllEmployersParams);

		this.setState({ getAllEmployersParams });
		
		if(getAllEmployersParams?.status?.length || getAllEmployersParams?.disabled?.length || getAllEmployersParams?.keyword?.length || getAllEmployersParams?.registeredFrom?.length || getAllEmployersParams?.registeredTo?.length || getAllEmployersParams?.features?.length) {
			this.props.searchFilterEmployers(getAllEmployersParams)
		} else {
			this.props.getAllEmployers(getAllEmployersParams);
		}
	};

	getAllEmployersCount = () => {
		this.props.getAllEmployersCount();
	};

	downloadEmployers = () => {
		const {getAllEmployersParams: params} = this.state;
		if(params?.status?.length || params?.disabled?.length || params?.keyword?.length || params?.registeredFrom?.length || params?.registeredTo?.length || params?.features?.length) {
			this.props.downloadSearchFilterEmployers({...params, page: 1, limit: 10000, download: true}).then(() => {
				this.Download.processDownload();
			});
		} else {
			this.props.downloadGetAllEmployers({...params, page: 1, limit: 10000, download: true}).then(() => {
				this.Download.processDownload();
			});
		}
	}

	transformDataDownload = (data) => {
        const newData = data?.map((row) => {
			const newRow = {
				...row,
				firstName: nameToSentenceCase(row.firstName) ?? "",
				lastName: nameToSentenceCase(row.lastName) ?? "",
			}
			return newRow;
		})
		return newData;
	}

	formChange = (e) => {
		const formValue = { ...this.state.search };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ search: formValue });
	};

	validate = () => {
		const options = { abortEarly: false };
		const result = Joi.validate(this.state.search, this.schema, options);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};


	toggleViewDetails = (status, employer = {}, action = "view") => {
		this.setState({
			toggleModalDetails: status,
			employerDetails: employer,
			modalDetailsAction: action,
		});
		if (status === true) {
			this.props.getEmployer(employer._id);
		}
	};

	getEmployerDetails = () => {
		const { getEmployerLoading, employer } = this.props;

		if (getEmployerLoading === true) {
			return <FontAwesomeIcon icon="spinner" spin className="text-md" />;
		} else if (
			getEmployerLoading === false &&
			Object.keys(employer).length <= 0
		) {
			return <>-</>;
		} else if (
			getEmployerLoading === false &&
			Object.keys(employer).length > 0
		) {
			return true;
		}
	};

	toggleModalFee = (status, employer = {}) => {
		this.setState({ toggleModalFee: status, employerDetails: employer });
	};

	gotoEmployerDashboard = (employer_id) => {
		toast("Loading... Please wait");

		this.props.getEmployerToken({ employerId: employer_id }).then(() => {
			// const { employerToken } = this.props;
			const employerToken = store.getState().entities.employers.employerToken;

			if (employerToken.token && employerToken.token !== "") {
				const userData = {
					loggedIn: true,
					data: {
						token: employerToken.token,
						_id: employerToken.employer._id,
						firstName: employerToken.employer.firstName,
						lastName: employerToken.employer.lastName,
						name:
							employerToken.employer.firstName +
							" " +
							employerToken.employer.lastName,
						email: employerToken.employer.email,
						phoneNumber: employerToken.employer.phoneNumber,
						userType: employerToken.employer.userType,
						jobTitle: employerToken.employer.jobTitle,
						isActive: employerToken.employer.isActive,
						disabled: employerToken.employer.disabled,
						accountType: employerToken.employer.accountType,
					},
					signedUp: false,
				};

				const userDataString = JSON.stringify(userData);
				const userDataStringEncrypt = encrypt(userDataString);

				const url =
					process.env.REACT_APP_EMPLOYER_APP_BASE_URL +
					"/ep-admin/login/" +
					userDataStringEncrypt;
				window.open(url, "_blank").focus();
			}
			else{
				toast.error("Unable to load employer dashboard");
			}
		});
	};

	approveEmployer = async (employer_id) => {
		const result = await DialogBox();
		if (result) {
			this.props.approveEmployer(employer_id).then(() => {
				this.toggleViewDetails(false);
			});
		}
	};

	activateEmployer = async (employer_id) => {
		const result = await DialogBox();
		if (result) {
			this.props.activateEmployer(employer_id);
			this.toggleViewDetails(false);
		}
	};

	deactivateEmployer = async (employer_id) => {
		const result = await DialogBox({
			theme: "red",
		});
		if (result) {
			this.props.deactivateEmployer(employer_id);
			this.toggleViewDetails(false);
		}
	};

	stopEmployeeWithdrawals = async (employer_id) => {
		const result = await DialogBox({
			theme: "red",
		});
		if (result) {
			this.props.stopEmployeeWithdrawals(employer_id);
		}
	};

	resumeEmployeeWithdrawals = async (employer_id) => {
		const result = await DialogBox();
		if (result) {
			this.props.resumeEmployeeWithdrawals(employer_id);
		}
	};

	pokeEmployer = async (employer) => {
		const result = await DialogBox();
		if (result) {
			this.props.pokeEmployer(employer._id);
			toast("Poking employer");
		}
	};

	resendVerificationMail = async (email) => {
		const result = await DialogBox();
		if (result) {
			const resendVerificationMailParams = {
				email: email,
			};
			this.props.resendVerificationMail(resendVerificationMailParams);
			toast("Resending verification mail to " + email);
		}
	};

	resendCompanyInfoPrompt = async (email) => {
		const result = await DialogBox();
		if (result) {
			const resendCompanyInfoPromptParams = {
				email: email,
			};
			this.props.resendCompanyInfoPrompt(resendCompanyInfoPromptParams);
			toast("Resending company info prompt mail to " + email);
		}
	};

	resendUnenrolledEmployeesInvite = async (id) => {
		const result = await DialogBox({
			title: "Resend Invite",
			content: "Resend Invite to Unenrolled employees",
			buttonYes: "Resend",
		});
		if (result) {
			const params = {
				employerId: id,
			};
			this.props.resendUnenrolledEmployeesInvite(params);
			toast("Resending invites");
		}
	};

	generateERA = async (userId, type) => {
		const result = await DialogBox();
		if (result) {
			const params = {
				userId,
				type,
			};
			this.props.generateERA(params);
			toast("Generating Reconciliation Account");
		}
	};

	schemaFee = {
		fee: Joi.number()
			.required()
			.label("Fee")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "any.empty":
							err.message = "Please enter the fee";
							break;
						case "number.base":
							err.message = `Please enter the fee`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		minAmount: Joi.number()
			.required()
			.label("Min Amount")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "any.empty":
							err.message = "Please enter the minimum amount";
							break;
						case "number.base":
							err.message = `Please enter the minimum amount`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		maxAmount: Joi.number()
			.required()
			.label("Max Amount")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "any.empty":
							err.message = "Please enter the maximum amount";
							break;
						case "number.base":
							err.message = `Please enter the maximum amount`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
	};

	validateFee = () => {
		const options = { abortEarly: false, allowUnknown: true };
		const result = Joi.validate(this.state.feeForm, this.schemaFee, options);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	submitFormFee = (action) => {
		const errors = this.validateFee();
		this.setState({ errors: errors || {} });
		if (errors) {
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			const { feeId, feeForm } = this.state;

			if (action === "create") {
				this.props.createFeesSettings(feeForm).then(() => {
					if (this.props.feesSettingsCreateSuccess === true) {
						document.querySelector(".input-minAmount-create").value = "";
						document.querySelector(".input-maxAmount-create").value = "";
						document.querySelector(".input-fee-create").value = "";
						this.props.getFeesSettings();
						this.setState({ showCreateForm: false });
					}
				});
			} else if (action === "update") {
				this.props.updateFeesSettings(feeId, feeForm).then(() => {
					if (this.props.feesSettingsUpdateSuccess === true) {
						this.props.getFeesSettings();
					}
				});
			}
		}
	};

	showCreateForm = () => {
		this.setState({ showCreateForm: true });
	};
	
	toggleRejectionModal = () => this.setState({ isRejectionModalOpen: !this.state.isRejectionModalOpen });
	toggleOdpApprovalModal = () => this.setState({ isOdpApprovalOpen: !this.state.isOdpApprovalOpen });
	toggleOdpRejectionModal = () => this.setState({ isOdpRejectOpen: !this.state.isOdpRejectOpen });

	CreateFee = async (e) => {
		e.preventDefault();
		const { employerDetails } = this.state;

		const feeForm = {
			minAmount: document.querySelector(".input-minAmount-create").value,
			maxAmount: document.querySelector(".input-maxAmount-create").value,
			fee: document.querySelector(".input-fee-create").value,
			employerId: employerDetails._id,
		};

		this.setState({ feeForm }, () => {
			this.submitFormFee("create");
		});
	};

	UpdateFee = (feeId, index) => {
		const { employerDetails } = this.state;

		const feeForm = {
			minAmount: document.querySelector(".input-minAmount-" + index).value,
			maxAmount: document.querySelector(".input-maxAmount-" + index).value,
			fee: document.querySelector(".input-fee-" + index).value,
			employerId: employerDetails._id,
		};

		this.setState({ feeId, feeForm }, () => {
			this.submitFormFee("update");
		});
	};

	DeleteFee = (feeId) => {
		this.setState({ feeId });
		this.props.deleteFeesSettings(feeId);
		this.unsubscribe3 = store.subscribe(() => {
			if (this.props.feesSettingsDeleteSuccess === true) {
				this.unsubscribe3();
				this.props.getFeesSettings();
			}
		});
		this.setState({ storeUnsubscribe3: this.unsubscribe3 });
	};

	componentDidMount() {
		this.getAllEmployers();
		this.getAllEmployersCount();
		this.props.getFeesSettings();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.getAllEmployers();
			this.getAllEmployersCount();
		}
		if (this.props.reloadPage === true) {
			setTimeout(() => {
				this.props.resetReloadPage();
				this.props.history.replace(
					this.props.location.pathname + this.props.location.search
				);
			}, 1000);
		}
	}

	componentWillUnmount() {
		const storeUnsubscribe = this.state.storeUnsubscribe;
		if (
			storeUnsubscribe !== "" &&
			storeUnsubscribe !== undefined &&
			storeUnsubscribe !== null
		) {
			storeUnsubscribe();
		}
	}

	render() {
		customizeSVG();
		const { userType: userRole } = this.props.user;

		const {
			allEmployers: data,
			allEmployersCount,
			employerApproveLoading,
			allEmployersCountloading,
			employer,
			toggleRolloverLoading: rolloverLoading,
			getEmployerLoading,
			rejectEmployerOnboarding,
			rejectEmployerOnboardingLoading,
			resetOnboardingRejectionResponse,
			approveOdpLoading,
			approveEmployerOdpRequest,
			rejectOdpLoading,
			rejectEmployerOdpRequest,
		} = this.props;
		const {
			getAllEmployersParams,
			toggleModalDetails,
			employerDetails,
		} = this.state;

		const toggleRollOverStatus = (employer_id, rolloverStatus) => {
			const payload = {
				id: employer_id,
				rollOver: rolloverStatus,
			};
			this.props.toggleRollover(payload);
		};

		const toggleEarningsModal = (name, id) => {
			if (this.state.hasMultiEmployees === true) {
				this.setState({ hasMultiEmployees: false });
			}

			this.setState({
				isEarningsModalOpen: !this.state.isEarningsModalOpen,
				selectedEmployerName: name,
				selectedEmployerId: id,
			});
		};
		
		const detailKeyClass = "text-xs"
		const detailValueClass = "mt-1 font-medium capitalize text-sm"
		const documentContainerClass = "flex items-center w-full justify-between mt-4 flex-wrap"
		
		const businessIsPending = employer?.employer?.status === "pending"
		const businessIsInReview = employer?.employer?.status === "review"
		const businessIsActive = employer?.employer?.status === "approved"
		const businessIsRejected = employer?.employer?.status === "rejected"
		const businessIsRegistered = employer?.employer?.company && employer?.employer?.company?.companyType === "registered business"
		
		const employeeGroups = ["0", "1-10", "11-50", "51-200", "201-500", "500+"]
		
		const switchModals = () => {
			this.toggleRejectionModal();
			this.toggleViewDetails(true, this.state.selectedEmployer);
		}
		
		const refetchData = () => {
			this.toggleRejectionModal();
			this.toggleViewDetails(false);
			this.getAllEmployers();
		}
		
		const displayDetails = (details) => { 
			if (getEmployerLoading === true) {
				return <FontAwesomeIcon icon="spinner" spin className="text-md" />;
			}
			return details;
		}
		
		
		const getCompanyName = (employer) => {
			if(employer?.companyDetails?.length > 0) {
				return employer?.companyDetails[0]?.companyName;
			} else if(employer?.company?.companyName?.length) {
				return employer?.company?.companyName;
			}
			return "-"
		}

		return (
			<div>
				
				<OnboardingRejectionModal
					refetchData={refetchData}
					onClose={() => switchModals()}
					loading={rejectEmployerOnboardingLoading}
					control={this.state.isRejectionModalOpen}
					rejectOnboarding={rejectEmployerOnboarding}
					selectedEmployer={this.state.selectedEmployer}
				/>
				
				<OnDemandApprovalModal
					loading={approveOdpLoading}
					getCompanyName={getCompanyName}
					control={this.state.isOdpApprovalOpen}
					onClose={() => this.toggleOdpApprovalModal()}
					selectedEmployer={this.state.selectedEmployer}
					onProceed={() => {
						approveEmployerOdpRequest(this.state.selectedEmployer?._id)
						.then(() => {
							this.toggleOdpApprovalModal();
							this.toggleViewDetails(false);
							this.getAllEmployers();
						})
					}}
				/>
				
				<OnDemandRejectionModal
					loading={rejectOdpLoading}
					getCompanyName={getCompanyName}
					control={this.state.isOdpRejectOpen}
					onClose={() => this.toggleOdpRejectionModal()}
					selectedEmployer={this.state.selectedEmployer}
					onProceed={(comment) => {
						rejectEmployerOdpRequest({
							id:	this.state.selectedEmployer?._id,
							comment
						})
						.then(() => {
							this.toggleOdpRejectionModal();
							this.toggleViewDetails(false);
							this.getAllEmployers();
						})
					}}
				/>

				<div
					className={
						"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
						(toggleModalDetails ? "right-0" : "-right-full")
					}>
					<div className="w-full h-full p-5 pb-0 pl-0 relative overflow-auto overscroll-contain">
						{Object.keys(employerDetails).length > 0 && (
							<div>
								<div className="pl-5">
									<div className="flex justify-between">
										<div className="font-recoleta font-semibold text-2xl capitalize">
											{displayDetails(employer?.employer?.company?.companyName)}
										</div>
										<div
											onClick={() => this.toggleViewDetails(false)}
											className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
											<Xclose className="customizeSVG group-hover:text-white m-auto" />
										</div>
									</div>
									<div className="text-fade text-sm">
										{displayDetails(employer?.employer?.email)}
									</div>
									
									<div className="my-3 text-sm">
										Business Type: &nbsp;
										<span className="text-ep-primary-500 capitalize">
											{
												employer?.employer?.company?.companyType?.includes("freelance") ? 
												"Un-registered business" : 
												employer?.employer?.company?.companyType || "-"
											}
										</span>
									</div>
									
									<div className="mt-2 flex space-x-4">
										<div className="flex h-6">
											{employer?.employer?.status?.length && (
												<>
													<div
														className="w-2 h-2 my-auto mr-2 rounded-full"
														style={{ backgroundColor: businessIsActive ? "#398D94" : (businessIsPending || businessIsInReview) ? "#F49D1B" : businessIsRejected ? "#DC2626" : "" }}></div>
													<div className="my-auto text-xs">{businessIsActive ? "Active" : businessIsPending ? "Pending" : businessIsInReview ? "In Review" : businessIsRejected ? "Rejected" : "-"}</div>
												</>
											)}
										</div>
										
										<Maybe condition={getEmployerLoading === false}>
											<div className="flex items-center">
												<Link
													to={"/users/employees?employerId=" + employer?.employer?._id}
													className="btn btn-sm bg-gray-200 btn-gray-hover mr-3">
													View Employees
												</Link>
												
												
												<button onClick={() => {
													this.props.selectEmployer(this.state.selectedEmployer);
													this.props.history.push(`/businesses/transactions/${getCompanyName(this.state.selectedEmployer)}`);
												}} className="btn btn-sm bg-gray-200 btn-gray-hover">
													View Transactions
												</button>
											</div>
										</Maybe>
									</div>
								</div>

								<div className="w-full h-px my-7 bg-gray-200"></div>

								<div className="my-7 pb-5 pl-5">
										<div className="font-semibold text-xs mb-5">
											Business Details
										</div>
										
										<Maybe condition={getEmployerLoading === true}>
											<div className="h-20 flex flex-col items-center justify-center">
												<FontAwesomeIcon icon="spinner" spin className="text-md" />
												<div className={detailValueClass}>
													Fetching Business Details...
												</div>
											</div>
										</Maybe>
										
										<Maybe condition={businessIsRegistered === false}>
											<div className="grid grid-cols-2 gap-x-2">
												<div className="space-y-7">
													<div className="my-auto">
														<div className={detailKeyClass}>
															Business Name
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.company?.companyName || "-"}
														</div>
													</div>

													<div className="my-auto">
														<div className={detailKeyClass}>
															Industry Type
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.company?.industry || "-"}
														</div>
													</div>
													
													<div className="my-auto">
														<div className={detailKeyClass}>
															Business Address
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.company?.address || "-"}
														</div>
													</div>
												</div>
												
												<div className="space-y-7">
												<div className="my-auto">
														<div className={detailKeyClass}>
															Business Size
														</div>
														<div className={detailValueClass}>
															{employeeGroups[(employer?.employer?.company?.companySize)] || "-"}
														</div>
													</div>

													<div className="my-auto">
														<div className={detailKeyClass}>
															Business Phone Number
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.phoneNumber || "-"}
														</div>
													</div>

													
												</div>
											</div>
										</Maybe>
										
										<Maybe condition={businessIsRegistered}>
											<div className="grid grid-cols-2 gap-x-2">
												<div className="space-y-7">
													<div className="my-auto">
														<div className={detailKeyClass}>
															Business Name
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.company?.companyName || "-"}
														</div>
													</div>

													<div className="my-auto">
														<div className={detailKeyClass}>
															Trading Name
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.company?.businessAlias || "-"}
														</div>
													</div>

													<div className="my-auto">
														<div className={detailKeyClass}>
															Number of Employees
														</div>
														<div className={detailValueClass}>
															{employeeGroups[(employer?.employer?.company?.companySize)] || "-"}
														</div>
													</div>

													<div className="my-auto">
														<div className={detailKeyClass}>
															Business Description
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.company?.companyDescription || "-"}
														</div>
													</div>

													<div className="my-auto">
														<div className={detailKeyClass}>
															Business Address
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.company?.address || "-"}
														</div>
													</div>
												</div>
												
												<div className="space-y-7">
													<div className="my-auto">
														<div className={detailKeyClass}>
															Business Registration Number
														</div>
														<div className={detailValueClass + "text-ep-primary-500"}>
															{employer?.employer?.company?.registrationNumber || "-"}
														</div>
													</div>

													<div className="my-auto">
														<div className={detailKeyClass}>
															Business Phone Number
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.phoneNumber || "-"}
														</div>
													</div>

													<div className="my-auto">
														<div className={detailKeyClass}>
															Registration Type
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.company?.registrationType || "-"}
														</div>
													</div>
												</div>
											</div>
										</Maybe>
										
										<Maybe condition={employer?.employer?.company?.businessDocuments?.length > 0 && businessIsRegistered === false}>
											<div className={documentContainerClass}>
												{employer?.employer?.company?.businessDocuments?.map((document, index) => document?.fileUrl?.length && (
													<div key={index} style={{width: "48%", marginBottom: "10px"}}>
														<div className="text-xs mb-1 capitalize">
															{document?.name> 18 ? document?.name?.substring(0, 18) + "..." : document?.name}
														</div>
														<DocumentWrapper documentTitle={truncate(document?.name)} documentUrl={document?.fileUrl} />
													</div>
												))}
											</div>
										</Maybe>

									<div className="w-full h-px my-5 bg-gray-200"></div>
										<div className="font-semibold text-xs mb-5">
											Personal Information
										</div>
										<div className="grid grid-cols-2 gap-x-2">
											<div className="space-y-7">
												<div className="my-auto">
													<div className={detailKeyClass}>
														Full Name
													</div>
													<div className={detailValueClass}>
														{displayDetails(`${employer?.employer?.firstName + " " + employer?.employer?.lastName}`)}
													</div>
												</div>

												<div className="my-auto">
													<div className={detailKeyClass}>
														Country
													</div>
													<div className={detailValueClass}>
														{displayDetails(employer?.employer?.company?.country || "-")}
													</div>
												</div>
											</div>
											
											<Maybe condition={businessIsRegistered === false}>
												<div className="space-y-7">
													<div className="my-auto">
														<div className={detailKeyClass}>
															Date of Birth
														</div>
														<div className={detailValueClass}>
															{displayDetails(employer?.employer?.company?.dob || "-")}
														</div>
													</div>

													<div className="my-auto">
														<div className={detailKeyClass}>
															BVN
														</div>
														<div className={detailValueClass}>
															{employer?.employer?.company?.bvn || "-"}
														</div>
													</div>
												</div>
											</Maybe>
										</div>
										
										<Maybe condition={employer?.employer?.company?.businessDocuments?.length > 0 && businessIsRegistered === true}>
											<div className={documentContainerClass}>
												{employer?.employer?.company?.businessDocuments?.map((document, index) => document?.fileUrl?.length && (
													<div key={index} style={{width: "48%", marginBottom: "10px"}}>
														<div className="text-xs mb-1 capitalize">
															{document?.name> 18 ? document?.name?.substring(0, 18) + "..." : document?.name}
														</div>
														<DocumentWrapper documentTitle={truncate(document?.name)} documentUrl={document?.fileUrl} />
													</div>
												))}
											</div>
										</Maybe>
										
										<Maybe condition={employer?.employer?.company?.businessDirectors?.length > 0}>
												{employer?.employer?.company?.businessDirectors?.map((director, index) => (
													<Fragment key={index}>
													<div className="w-full h-px my-5 bg-gray-200"></div>
														<div className="font-semibold text-xs mb-5">
															Director {index + 1}
														</div>
														<div className="grid grid-cols-2 gap-x-2">
															<div className="space-y-7">
																<div className="my-auto">
																	<div className={detailKeyClass}>
																		Full Name
																	</div>
																	<div className={detailValueClass}>
																		{director?.directorFirstName || "-"} {director?.directorLastName || "-"}
																	</div>
																</div>

																<div className="my-auto">
																	<div className={detailKeyClass}>
																		Country
																	</div>
																	<div className={detailValueClass}>
																		{director?.nationality || "-"}
																	</div>
																</div>
															</div>
															
															<div className="space-y-7">
																<div className="my-auto">
																	<div className={detailKeyClass}>
																		Date of Birth
																	</div>
																	<div className={detailValueClass}>
																		{director?.directorDob || "-"}
																	</div>
																</div>

																<div className="my-auto">
																	<div className={detailKeyClass}>
																		BVN
																	</div>
																	<div className={detailValueClass}>
																		{director?.bvn || "-"}
																	</div>
																</div>
															</div>
														</div>
														<div style={{width: "48%", marginBottom: "10px", marginTop: "15px"}}>
															<div className="text-xs mb-1 capitalize">
																{director?.documentType > 18 ? director?.documentType?.substring(0, 18) + "..." : director?.documentType}
															</div>
															<DocumentWrapper documentTitle={truncate(director?.documentType)} documentUrl={director?.documentFile} />
														</div>
													</Fragment>
												))}
										</Maybe>
										
										
										<div className="w-full h-px my-5 bg-gray-200"></div>
										<div className="font-semibold text-xs mb-5">
											Social Media Links
										</div>
										<div className="grid grid-cols-2 gap-x-2">
											<div className="space-y-4">
												<div className="my-auto">
													<div className={detailKeyClass}>
														Facebook
													</div>
													<div className="mt-1">
														{displayDetails(employer?.employer?.company?.socials?.facebook || "-")}
													</div>
												</div>
												<div className="my-auto">
													<div className={detailKeyClass}>
														Twitter
													</div>
													<div className="mt-1">
														{displayDetails(employer?.employer?.company?.socials?.twitter || "-")}
													</div>
												</div>
												<div className="my-auto">
													<div className={detailKeyClass}>
														Others
													</div>
													<div className="mt-1">
														{displayDetails(employer?.employer?.company?.socials?.others || "-")}
													</div>
												</div>
											</div>
										</div>
										
										<Maybe condition={employer?.employer?.company?.bankStatementFile?.length > 0}>
											<div className="w-full h-px my-5 bg-gray-200"></div>
											<div className="font-semibold text-xs mb-5">
												On-Demand Details
											</div>
											<div className="grid grid-cols-2 gap-x-2 pb-8">
												<div className="space-y-4">
													<div className="my-auto">
														<div className={detailKeyClass}>
															Salary Details
														</div>
														<div className="mt-1">
															{numberOrdinalSuffix(employer?.employer?.company?.payrollCutOff) || "-"}&nbsp;of every month
														</div>
													</div>
													<div className="my-auto">
														<div className={detailKeyClass}>
															Withdrawal Fee paid by
														</div>
														<div className="mt-1 capitalize">
															{employer?.employer?.company?.paymentFee || "-"}
														</div>
													</div>
													<div className="my-auto">
														<div className="text-xs mb-1 capitalize">
															Bank Statement File
														</div>
														<DocumentWrapper
															documentTitle="Bank Statement" 
															documentUrl={employer?.employer?.company?.bankStatementFile}
														/>
													</div>
												</div>
											</div>
											<Maybe condition={employer?.employer?.odpStatus === "pending"}>
													<div className="flex items-center w-full">
														<button 
															type="button" 
															onClick={() => { 
																console.log('employer', employer)
																this.props.selectEmployer(this.state.selectedEmployer);
																this.toggleOdpRejectionModal();
															}}
															className="btn btn-md border border-red-500 text-red-500 mr-6">
															Reject
														</button>
														
														<button
															type="button"
															onClick={() => { 
																this.props.selectEmployer(this.state.selectedEmployer);
																this.toggleOdpApprovalModal();
															}}
															className="btn btn-md bg-ep-primary-500 text-white ml-3">
															Approve
														</button>
													</div>
											</Maybe>
										</Maybe>
										
										<div className="w-full h-px my-5 bg-gray-200"></div>
											<div className="font-semibold text-xs mb-5">
												Business Audits
											</div>
										<Maybe condition={employer?.employer?.rejectComment?.length > 0}>
											<div className="w-full flex flex-col pr-4">
												{employer?.employer?.rejectComment?.map((comment) => {
													const isActive = this.state.selectedComment?._id === comment?._id;
													
													const toggleCommentView = () => isActive ? this.setState({selectedComment: null}) : this.setState({selectedComment: comment});
													return (
														<StatusTimeline
															email={comment?.commentBy}
															text={comment?.comment}
															timestamp={comment?.timestamp}
															key={comment?._id}
															isActive={isActive}
															toggleCommentView={toggleCommentView}
														/>
													)
												})}
											</div>
										</Maybe>
										
										<Maybe condition={employer?.employer?.approvedAt?.length > 0}>
											<StatusTimeline
												isApproved
												email={employer?.employer?.approvedBy}
												timestamp={employer?.employer?.approvedAt}
											/>
										</Maybe>

											
										<Maybe condition={employer?.employer?.rejectODPComment?.length > 0}>
											<div className="w-full h-px my-5 bg-gray-200"></div>
												<div className="font-semibold text-xs mb-5">On-Demand Rejection Audits</div>

												<div className="w-full flex flex-col pr-4">
													{employer?.employer?.rejectODPComment?.map((comment) => {
														const isActive = this.state.selectedOnDemandRejectionComment?._id === comment?._id;
														
														const toggleCommentView = () => isActive ? this.setState({selectedOnDemandRejectionComment: null}) : this.setState({selectedOnDemandRejectionComment: comment});
														return (
															<StatusTimeline
																email={comment?.commentBy}
																text={comment?.comment}
																timestamp={comment?.timestamp}
																key={comment?._id}
																isActive={isActive}
																toggleCommentView={toggleCommentView}
															/>
														)
													})}
											</div>
										</Maybe>
								</div>

								<>
									<div className="h-20"></div>
									<Maybe condition={employer?.employer?.company?.companyName?.length > 0 && businessIsInReview === true}>
										<div className="py-4 fixed bottom-0 bg-white border-t flex justify-start" style={{ width: "25.5rem" }}>
											<div className="flex sm:justify-end space-x-2 w-full">
												<button 
													type="button" 
													onClick={() => {
														resetOnboardingRejectionResponse();
														this.toggleRejectionModal();
														this.toggleViewDetails();
													}}
													className="btn btn-md border border-red-500 text-red-500">
													Reject
												</button>
												
												<button
												type="button"
												onClick={() => this.approveEmployer(employer?.employer?._id)}
												className="btn btn-md bg-ep-primary-500 text-white"
												disabled={employerApproveLoading}>
													Approve
												</button>
											</div>
										</div>
									</Maybe>
								</>
							</div>
						)}
					</div>
				</div>

				<div>
					<div className="lg:flex">
						<div className="w-full lg:w-1/2">
							<div className="page-title">Businesses</div>
						</div>
						<div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
							<div>
								<Download
									tooltip="Download businesses"
									format="csv"
									filename="All Businesses.csv"
									columns={columns}
									ref={(instance) => {
										this.Download = instance;
									}}
									click={this.downloadEmployers}
									loading={this.props.downloadLoading}
									response={this.transformDataDownload(this.props.downloadAllEmployers)}
								/>
							</div>

							<div>
								<SearchFilter
									search={{
										name: "keyword",
										placeholder: "Keyword search",
									}}
									filters={[
										{
											title: "Status",
											name: "status",
											dataType: "radio",
											options: [
												{
													display: "All",
													payload: "",
												},
												{
													display: "Approved",
													payload: "approved",
												},
												{
													display: "Pending",
													payload: "pending",
												},
												{
													display: "Review",
													payload: "review",
												},
											],
										},
										{
											title: "Active Status",
											name: "status",
											dataType: "radio",
											options: [
												{
													display: "All Statuses",
													payload: "",
												},
												{
													display: "Active",
													payload: "approved",
												},
												{
													display: "Inactive",
													payload: "pending",
												},
											],
										},
										{
											title: "Registration Date",
											dataType: "date-range",
											options: {
												from: {
													name: "registeredFrom",
													placeholder: "Start Date",
												},
												to: {
													name: "registeredTo",
													placeholder: "End Date",
												},
											},
										},
									]}
								/>
							</div>
						</div>
					</div>

					<div className="mt-6">
						<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
							<DashboardBoxes
								data={this.state.dashboardProps1}
								loading={allEmployersCountloading}
								result={
									allEmployersCount?.totalBusinesses ?? "0"
								}
							/>
							<DashboardBoxes
								data={this.state.dashboardProps2}
								loading={allEmployersCountloading}
								result={
									allEmployersCount?.activeBusinesses ?? "0"
								}
							/>
							<DashboardBoxes
								data={this.state.dashboardProps4}
								loading={allEmployersCountloading}
								result={
									allEmployersCount?.inActiveBusinesses ?? "0"
								}
							/>
						</div>
					</div>

					<div className="mt-10">
						<div className="lg:flex">
							<div className="w-full lg:w-1/2 flex">
								<div className="page-title-mini my-auto">
									Businesses Data
									<CenterModal />
								</div>
							</div>
							<div className="w-full lg:w-1/2 lg:flex lg:justify-end mt-1 lg:mt-0">
								<div className="w-full lg:w-auto inline-block box-border-only overflow-auto">
									<div className="h-10 flex">
										<div
											onClick={() =>
												this.gotoUrlQuery({ status: "", disabled: "" })
											}
											className={
												"cursor-pointer page-nav " +
												(getAllEmployersParams.status === "" ? "active" : "")
											}>
											<div className="whitespace-nowrap">All Businesses</div>
										</div>
										<div
											onClick={() =>
												this.gotoUrlQuery({ status: "approved", disabled: "" })
											}
											className={
												"cursor-pointer page-nav " +
												(getAllEmployersParams.status === "approved" &&
												getAllEmployersParams.disabled === ""
													? "active"
													: "")
											}>
											<div className="whitespace-nowrap">
												Active Businesses
											</div>
										</div>
										<div
											onClick={() =>
												this.gotoUrlQuery({ status: "pending", disabled: "" })
											}
											className={
												"cursor-pointer page-nav " +
												(getAllEmployersParams.status === "pending"
													? "active"
													: "")
											}>
											<div className="whitespace-nowrap">
												Inactive Businesses
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="mt-3">
							<div className="table-container">
								<table className="table table-auto table-rounded table-border">
									<thead>
										<tr>
											<th>Date Joined</th>
											<th className="whitespace-nowrap">Business Name</th>
											<th>Email</th>
											<th>Business Phone</th>
											<th>Company Status</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{this.props.loading === true && (
											<tr>
												<td colSpan="6">
													<div className="table-info">
														<FontAwesomeIcon icon="spinner" spin />
														<div className="font-bold uppercase">Loading</div>
													</div>
												</td>
											</tr>
										)}

										{this.props.loading === false && !data.data?.employers && (
											<tr>
												<td colSpan="6">
													<div className="table-info">
														<FontAwesomeIcon icon="unlink" />
														<div className="font-bold uppercase">
															An error occurred
															<br />
															Please try again later.
														</div>
													</div>
												</td>
											</tr>
										)}

										{this.props.loading === false &&
											data.data?.employers &&
											data.data?.employers.length <= 0 && (
												<tr>
													<td colSpan="6">
														{(data.page <= data.totalPages || data?.data?.employers?.length < 1) && (
															<div className="table-info">
																<FontAwesomeIcon icon="list" />
																<div className="font-bold uppercase">
																	No results found
																</div>
															</div>
														)}

														{data.page > data.totalPages && (
															<div className="table-info">
																<FontAwesomeIcon icon="exclamation-triangle" />
																<div className="font-bold uppercase">
																	Invalid Page Number
																</div>
																<div className="mt-2">
																	<Link
																		to="?page=1"
																		className="btn btn-ep-blue btn-sm mx-auto">
																		Goto Page 1
																	</Link>
																</div>
															</div>
														)}
													</td>
												</tr>
											)}

										{this.props.loading === false &&
											data.data?.employers &&
											data.data?.employers.length > 0 &&
											data.data?.employers.map((employer, index) => {
												const hasBulkPay = employer?.products?.includes("BULK_PAY")
												const hasOnDemandPay = employer?.products?.includes("ON_DEMAND_PAY")
												const hasPayroll = employer?.products?.includes("PAYROLL")
												
												const getCompanyName = (employer) => {
													if(employer?.companyDetails?.length > 0) {
														return employer?.companyDetails[0]?.companyName;
													} else if(employer?.company?.companyName?.length) {
														return employer?.company?.companyName;
													} else if(employer?.companyName?.length) {
														return employer?.companyName;
													}
													return "-"
												}
												
												return (
													<tr key={index}>
														<td>{DateFormat(employer.createdAt?.split("T")[0])}</td>
														<td className="w-4">
															<span onClick={() => {
																	this.setState({selectedEmployer: employer})
																	this.toggleViewDetails(true, employer)
																	}} className="hover:underline cursor-pointer capitalize">
																{getCompanyName(employer)}
															</span>
														</td>
														<td>
															{employer?.email}
														</td>
														<td>
															{employer?.phoneNumber}
														</td>
														<td>
															<div className={"label uppercase " + renderStatusColor(employer?.status === "rejected" ? "failed" : employer?.status === "review" ? "pending" : employer?.status)}>
																{employer?.status}
															</div>
														</td>
														<td>
															<div>
																<Dropdown
																	icon={dropdownTableIcon}
																	menu={[
																		{
																			type: "div",
																			text: "View Details",
																			click: () => {
																				this.setState({selectedEmployer: employer});
																				this.toggleViewDetails(true, employer);
																			}
																		},
																		
																		{
																			type: "div",
																			text: "View Transactions",
																			click: () => {
																				this.setState({selectedEmployer: employer});
																				this.props.selectEmployer(employer);
																				this.props.history.push(`/businesses/transactions/${getCompanyName(employer)}`);
																			}
																		},
																		
																		((hasBulkPay || hasPayroll) && 
																			{
																				type: "link",
																				text: "View Admins",
																				link: `/businesses/${employer._id}/admins`,
																			}
																		),
																		
																		{
																			type: "link",
																			text: "View Employees",
																			link: `/users/employees?employerId=${employer._id}`,
																		},
																		
																		((userRole === "super-admin" && !employer.isActive && hasOnDemandPay) && 
																			{
																				type: "div",
																				text: "Approve Employer",
																				click: () =>
																					this.toggleViewDetails(
																						true,
																						employer,
																						"approve"
																					),
																			}
																		),
																		
																		(hasOnDemandPay && 
																			{
																				type: "link",
																				text: "View Employer Performance",
																				link: `/businesses/performance?employerId=${employer._id}`,
																			}
																		),
																		
																		// userRole === "super-admin"
																		// 	? {
																		// 			type: "div",
																		// 			text: "Goto Employer Dashboard",
																		// 			click: () =>
																		// 				this.gotoEmployerDashboard(
																		// 					employer._id
																		// 				),
																		// 	}
																		// 	: "",
																		userRole === "super-admin" ||
																		userRole === "customer-success-admin"
																			? employer.disabled
																				? {
																						type: "div",
																						text: "Activate",
																						click: () =>
																							this.activateEmployer(employer._id),
																				}
																				: {
																						type: "div",
																						text: "Deactivate",
																						click: () =>
																							this.deactivateEmployer(
																								employer._id
																							),
																				}
																			: "",

																		((userRole === "super-admin" && employer?.company && employer?.company?.stopWithdrawals && hasOnDemandPay) && 
																			{
																				type: "div",
																				text: "Resume employee withdrawals",
																				click: () =>
																					this.resumeEmployeeWithdrawals(
																						employer._id
																					),
																			}
																		),
																		
																		((userRole === "super-admin" && employer?.company && !employer?.company?.stopWithdrawals && hasOnDemandPay) && 
																			{
																				type: "div",
																				text: "Stop employee withdrawals",
																				click: () => 
																					this.stopEmployeeWithdrawals(
																						employer._id
																					),
																			}
																		),
																		
																		!employer.isActive
																			? {
																					type: "div",
																					text: "Poke Employer",
																					click: () =>
																						this.pokeEmployer(employer),
																			}
																			: "",
																		!employer.isActive
																			? {
																					type: "div",
																					text: "Resend Verification Mail",
																					click: () =>
																						this.resendVerificationMail(
																							employer.email
																						),
																			}
																			: "",
																		
																		((!employer.isActive && hasOnDemandPay) && 
																			{
																				type: "div",
																				text: "Resend Info Reminder Mail",
																				click: () =>
																					this.resendCompanyInfoPrompt(
																						employer.email
																					),
																			}
																		),
																			
																		userRole === "super-admin"
																			? {
																					type: "div",
																					text: "Resend Invite to Unenrolled employees",
																					click: () =>
																						this.resendUnenrolledEmployeesInvite(
																							employer._id
																						),
																			}
																			: "",
																			
																			((userRole === "super-admin" && hasOnDemandPay) &&
																				{
																					type: "div",
																					text: "Generate EVA",
																					click: () =>
																						this.generateERA(
																							employer._id,
																							"employer"
																						),
																				}
																			),
																			
																		userRole === "super-admin"
																			? {
																					type: "div",
																					text: rolloverLoading
																						? "Loading..."
																						: employer?.company?.rollOver ===
																						false
																						? "Activate Roll Over"
																						: "Deactivate Roll Over",
																					click: () =>
																						rolloverLoading
																							? null
																							: toggleRollOverStatus(
																									employer._id,
																									employer?.company?.rollOver
																							),
																			}
																			: "",
																		userRole === "super-admin"
																			? {
																					type: "div",
																					text: "Set all Employee earnings",
																					click: () =>
																						toggleEarningsModal(
																							employer.company.companyName,
																							employer._id
																						),
																			}
																			: "",
																		userRole === "super-admin"
																			? {
																					type: "div",
																					text: "Set multiple Employee earnings",
																					click: () => {
																						this.setState({
																							hasMultiEmployees: true,
																						});
																						toggleEarningsModal(
																							employer.companyName,
																							employer._id
																						);
																					},
																			}
																			: "",
																	]}
																/>
															</div>
														</td>
													</tr>
												)
										})}
									</tbody>
								</table>
							</div>

							<SetEarningsModal
								hasMultiple={this.state.hasMultiEmployees}
								control={this.state.isEarningsModalOpen}
								onClose={toggleEarningsModal}
								companyName={this.state.selectedEmployerName}
								employerId={this.state.selectedEmployerId}
							/>

							{this.props.loading === false &&
								data.data?.employers &&
								data.data?.employers.length > 0 && (
									<div>
										<Pagination
											data={data?.data}
											url={this.props.location.search}
											limit={getAllEmployersParams.limit}
										/>
									</div>
								)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const OnDemandApprovalModal = ({control, onClose, onProceed, loading, selectedEmployer, getCompanyName}) => {

    return (
        <CenterModal control={control} onClose={onClose}>
            <div className='flex items-center justify-center flex-col py-4'>
                <IconCheck />
                <div className="text-center pt-5">
                    <p className="mb-2 font-semibold">Approve On-Demand Pay Request</p>
                    <p className="text-gray-500">Are you sure you want to approve On-Demand Pay for&nbsp;
					<span className="text-black font-medium">{getCompanyName(selectedEmployer)}</span>?</p>
                </div>
                
                <div className="flex items-center justify-center pt-6 w-full">
                    <button type="button" className="btn btn-md btn-transparent-black lg:px-14" onClick={onClose}>
                        Cancel
                    </button>
                    
                    <button disabled={loading} type="button" className="btn btn-md bg-ep-primary text-white ml-3 lg:px-14" onClick={onProceed}>
                        {loading ? "Submitting..." : "Approve"}
                    </button>
                </div>
            </div>
        </CenterModal>
    )
}

const OnDemandRejectionModal = ({control, onClose, onProceed, loading}) => {
	const [reason, setReason] = useState("");

	const rejectOnDemandRequest = () => {
		onProceed(reason);
		setReason("");
	}
	
	return (
		<CenterModal control={control} onClose={onClose}>
		<h1 className="lg:text-lg font-semibold">Reject On-Demand Pay request</h1>
		
		<div className="form-group mt-8">
			<label>
				Rejection Reasons
			</label>
			<textarea style={{ height: "120px", resize: "none" }} className="p-2 w-full border border-[#D1D5DB] rounded-md focus:ring-0 outline-none" value={reason} onChange={(e) => setReason(e.target.value)} />
        </div>
		
		<div className="flex items-center justify-center lg:flex-row lg:space-x-2">
			
			<button
				type="button"
				onClick={onClose}
				className="btn btn-md border border-ep-primary-500 text-ep-primary-500 mb-4 lg:mb-0 w-full lg:w-2/4 focus:ring-0"
				disabled={loading}>
				Cancel
			</button>
			
			<button
				disabled={!reason?.length || loading}
				type="button" 
				onClick={rejectOnDemandRequest}
				className="btn btn-md border bg-red-500 text-white w-full lg:w-2/4 focus:ring-0">
				{loading ? "Submitting..." : "Reject"}
			</button>
		</div>
	</CenterModal>
	)
}


const DocumentWrapper = ({documentTitle, documentUrl}) => (
	<Link to={{ pathname: documentUrl }} target="_blank" className="w-2/4">
		<div className="w-full bg-gray-100 rounded-lg px-2 py-1.5 flex items-center justify-between text-ep-primary-500">
			<div className="w-3/4 flex items-center">
				<IconFileUpload />
				<span className="text-sm ml-1 whitespace-nowrap capitalize">{truncate(documentTitle, 20)}</span>
			</div>
			<IconDownload width={25} />
		</div>
	</Link>
)


const OnboardingRejectionModal = ({control, onClose, selectedEmployer, rejectOnboarding, loading, refetchData}) => {
	const [reason, setReason] = useState("");
	
	const onReject = () => {
		rejectOnboarding({
			id: selectedEmployer?._id,
			payload: {rejectComment: reason}
		}).then(() => {
			setReason("");
			refetchData();
		})
	}
	
	return (
		<CenterModal control={control} onClose={onClose}>
		<h1 className="lg:text-lg font-semibold">Reason For Rejection</h1>
		
		<div className="form-group mt-8">
			<label>
				Rejection Reasons
			</label>
			<textarea style={{ height: "120px", resize: "none" }} className="p-2 w-full border border-[#D1D5DB] rounded-md focus:ring-0 outline-none" value={reason} onChange={(e) => setReason(e.target.value)} />
        </div>
		
		<div className="flex items-center justify-center lg:flex-row lg:space-x-2">
			
			<button
				type="button"
				onClick={onClose}
				className="btn btn-md border border-ep-primary-500 text-ep-primary-500 mb-4 lg:mb-0 w-full lg:w-2/4 focus:ring-0"
				disabled={loading}>
				Cancel
			</button>
			
			<button
				disabled={!reason?.length || loading}
				type="button" 
				onClick={onReject}
				className="btn btn-md border bg-red-500 text-white w-full lg:w-2/4 focus:ring-0">
				{loading ? "Submitting..." : "Reject"}
			</button>
		</div>
	</CenterModal>
	)
}


const StatusTimeline = ({email, timestamp, text, isApproved, isActive, toggleCommentView}) => {
	
	return (
		<div className={`w-full mb-5 pl-5 relative ${!isApproved && "border-l-2 border-[#E5E7EB]"}`}>
			<div className="absolute -top-1 -left-3 text-xs">
				{isApproved ? <IconApproved className="text-xs" width={20} /> : <IconRejected className="text-xs" width={20} />}
			</div>
			
			<div className="flex items-center">
				<span className="font-semibold text-sm">{isApproved ? "Approved" : "Rejected"} by&nbsp;</span>
				<span className="text-ep-primary-500">{email}</span>
			</div>
			<div className="text-sm my-0.5">
				{DateTimeFormat(timestamp)}
			</div>
			
			<Maybe condition={text?.length > 0}>
				<div onClick={toggleCommentView}   className="w-max text-xs mt-1 text-ep-primary-500 cursor-pointer flex items-center">
					<span className="mr-1.5 text-md">
						<IconComment />
					</span>
					{isActive ? "Hide" : "See"} Comment
				</div>
				
				<Maybe condition={isActive}>
					<div className="w-full bg-gray-50 rounded-sm text-[#374151] text-justify text-xs p-2 mt-2">
						{text}
					</div>
				</Maybe>
			</Maybe>
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth.userPersist.data,

	loading: state.entities.employers.loading,
	toggleRolloverLoading: state.entities.employers.isRolloverLoading,
	toggleAutoWdlLoading: state.entities.employers.isAutoWdlLoading,
	downloadLoading: state.entities.employers.downloadLoading,
	allEmployers: state.entities.employers.allEmployers,
	downloadAllEmployers: state.entities.employers.downloadAllEmployers,
	allEmployersCountloading: state.entities.employers.allEmployersCountloading,
	allEmployersCount: state.entities.employers.allEmployersCount,
	employerToken: state.entities.employers.employerToken,
	getEmployerLoading: state.entities.employers.getEmployerLoading,
	employer: state.entities.employers.getEmployer,

	employerApproveLoading: state.entities.employers.employerApproveLoading,
	reloadPage: state.entities.employers.reloadPage,
	
	rejectEmployerOnboardingLoading: state.entities.employers.rejectEmployerOnboardingLoading,
	rejectEmployerOnboardingData: state.entities.employers.rejectEmployerOnboardingData,

	approveOdpLoading: state.entities.employers.approveOdpLoading,
	rejectOdpLoading: state.entities.employers.rejectOdpLoading,

	feesSettingsLoading: state.entities.settings.feesSettingsLoading,
	feesSettings: state.entities.settings.feesSettings,
	feesSettingsCreateLoading: state.entities.settings.feesSettingsCreateLoading,
	feesSettingsCreateSuccess: state.entities.settings.feesSettingsCreateSuccess,
	feesSettingsUpdateLoading: state.entities.settings.feesSettingsUpdateLoading,
	feesSettingsUpdateSuccess: state.entities.settings.feesSettingsUpdateSuccess,
	feesSettingsDeleteLoading: state.entities.settings.feesSettingsDeleteLoading,
	feesSettingsDeleteSuccess: state.entities.settings.feesSettingsDeleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
	getAllEmployers: (params) => dispatch(employers.getAllEmployers(params)),
	searchFilterEmployers: (params) => dispatch(employers.searchFilterEmployers(params)),
	getAllEmployersCount: () => dispatch(employers.getAllEmployersCount()),
	downloadGetAllEmployers: (params) => dispatch(employers.downloadGetAllEmployers(params)),
	downloadSearchFilterEmployers: (params) => dispatch(employers.downloadSearchFilterEmployers(params)),
	getEmployerToken: (params) => dispatch(employers.getEmployerToken(params)),
	getEmployer: (employer_id) => dispatch(employers.getEmployer(employer_id)),

	approveEmployer: (employer_id) =>
		dispatch(employers.approveEmployer(employer_id)),
	activateEmployer: (employer_id) =>
		dispatch(employers.activateEmployer(employer_id)),
	deactivateEmployer: (employer_id) =>
		dispatch(employers.deactivateEmployer(employer_id)),
	stopEmployeeWithdrawals: (employer_id) =>
		dispatch(employers.stopEmployeeWithdrawals(employer_id)),
	resumeEmployeeWithdrawals: (employer_id) =>
		dispatch(employers.resumeEmployeeWithdrawals(employer_id)),
	pokeEmployer: (id) => dispatch(employers.pokeEmployer(id)),
	resendVerificationMail: (params) =>
		dispatch(employers.resendVerificationMail(params)),
	resendCompanyInfoPrompt: (params) =>
		dispatch(employers.resendCompanyInfoPrompt(params)),
	resendUnenrolledEmployeesInvite: (params) =>
		dispatch(employers.resendUnenrolledEmployeesInvite(params)),
	generateERA: (params) => dispatch(employers.generateERA(params)),
	resetReloadPage: () => dispatch(employers.resetReloadPage()),

	getFeesSettings: (params) => dispatch(settings.getFeesSettings(params)),
	createFeesSettings: (params) => dispatch(settings.createFeesSettings(params)),
	updateFeesSettings: (id, params) =>
		dispatch(settings.updateFeesSettings(id, params)),
	deleteFeesSettings: (id) => dispatch(settings.deleteFeesSettings(id)),

	toggleRollover: (params) =>
		dispatch(employers.toggleEmployerRollover(params)),

	rejectEmployerOnboarding: (params) =>
		dispatch(employers.rejectEmployerOnboarding(params)),

	resetOnboardingRejectionResponse: () =>
		dispatch(employers.resetOnboardingRejectionResponse()),

	selectEmployer: (params) =>
		dispatch(employers.selectEmployer(params)),

	approveEmployerOdpRequest: (params) =>
		dispatch(employers.approveEmployerOdpRequest(params)),

	rejectEmployerOdpRequest: (params) =>
		dispatch(employers.rejectEmployerOdpRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessesList);
