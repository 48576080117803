import Maybe from 'components/__new/common/Maybe';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import NewTermLoanFee from './personal/NewTermLoanFee';

const PersonalCreditSettings = () => {
    const [isViewing, setIsViewing] = useState(true);
    const [isNewFeeOpen, setIsNewFeeOpen] = useState(false);
    
    // eslint-disable-next-line
    const [currentTab, setCurrentTab] = useState(0);

    const toggleViewMode = () => setIsViewing(!isViewing);

    // eslint-disable-next-line
    const [termLoanFees, setTermLoanFees] = useState([
        {   id: 1,
            feeName: "Interest rate (p/m)",
            reference: "interestRate",
            value: "25",
            isCustom: false,
        },
        {   id: 2,
            feeName: "Max Tenor (Months)",
            reference: "maxTenor",
            value: "6",
            isCustom: false,
        },
        {   id: 3,
            feeName: "Applied Fees",
            feeType: "percentage",
            reference: "appliedFees",
            value: "0.25",
            isCustom: false,
        },
        {   id: 4,
            feeName: "Penalty Charge per day",
            feeType: "percentage",
            reference: "penaltyCharge",
            value: "1.25",
            isCustom: false,
        },
    ]);

     // eslint-disable-next-line
    const [creditTypes, setCreditTypes] = useState([
        {
            title: "Term Loan",
            isActive: true,
        },
        {
            title: "Overdraft",
            isActive: false
        },
        {
            title: "Asset Finance",
            isActive: false
        },
    ]);

    const onProceed = () => { 
        if(isViewing) {
            toggleViewMode();
        } else {
            toast("Saving changes...")
        }
    }

    return (
        <>

        <NewTermLoanFee 
            isViewing={isViewing}
            isOpen={isNewFeeOpen}
            termLoanFees={termLoanFees}
            setTermLoanFees={setTermLoanFees}
            setIsOpen={setIsNewFeeOpen}
        />

        <div className='w-full'>
            <div className="w-full flex items-center">
                {creditTypes?.map(({title, isActive}, index) => (
                    <div key={title} className={`py-1 px-3 mr-3 text-sm rounded-full ${currentTab === index ? "bg-ep-primary-500 text-white" : "bg-gray-200"} ${isActive ? "cursor-pointer" : "cursor-not-allowed"}`}>
                        {title}
                    </div>
                ))}
            </div>
            
            <div className="w-full pt-8">
                <TermLoan
                    isViewing={isViewing}
                    termLoanFees={termLoanFees}
                    setTermLoanFees={setTermLoanFees}
                />
            </div>

            <button onClick={() => setIsNewFeeOpen(true)} className="text-ep-primary-500 font-semibold mb-6 text-sm">
                + Add a new fee
            </button>

            <div className="w-full flex items-center">
                <button onClick={onProceed} className="btn mr-3 bg-ep-primary text-white rounded-[4px] px-6 lg:px-10">
                    {isViewing ? "Edit" : "Save Changes"}
                </button>

                <Maybe condition={isViewing}>
                    <button onClick={() => {}} className="btn btn-transparent-primary rounded-[4px] px-6 lg:px-10">
                        Reset
                    </button>
                </Maybe>
            </div>
        </div>

        </>
    )
}

const TermLoan = ({isViewing, termLoanFees, setTermLoanFees}) => {

    const onDelete = (id) => setTermLoanFees([...termLoanFees?.filter((fee) => fee.id !== id)]);
    const updateValue = (id, value) => setTermLoanFees(termLoanFees?.map((fee) => fee.id === id ? {...fee, value} : fee));

    return (
        <div className='w-full flex items-center justify-start flex-wrap'>
            {termLoanFees?.map(({id, feeName, reference, feeType, value, isCustom}) => (
            <div key={feeName} className="w-full md:w-[48%] md:mr-4 mb-6 space-y-1">

                <div className={`${isCustom && "flex items-center justify-between"}`}>
                    <label>{feeName} {feeType === "percentage" && "(%)"}</label>
                    <Maybe condition={isCustom}>
                        <button onClick={() => onDelete(id)} className="text-ep-danger font-semibold">
                            Delete
                        </button>
                    </Maybe>
                </div>

                <input
                    type="number"
                    id="number-input"
                    name={reference}
                    value={value || ""}
                    disabled={isViewing}
                    placeholder={value || ""}
                    onChange={(e) => updateValue(id, e.target.value)}
                    className={`form-input mb-3 ${isViewing ? "cursor-not-allowed" : "cursor-auto"}`}
                />
            </div>
            ))}
        </div>
    )
}

export default PersonalCreditSettings