import * as actions from "./actionTypes";
import * as config from "config";

export function getReferrals(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReferrals,
            method: "post",
            data: params,
            onStart: actions.GET_ALL_REFERRALS_START,
            onSuccess: actions.GET_ALL_REFERRALS,
            onError: actions.GET_ALL_REFERRALS_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function downloadReferrals(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReferrals,
            method: "post",
            data: params,
            onStart: actions.DOWNLOAD_GET_ALL_REFERRALS_START,
            onSuccess: actions.DOWNLOAD_GET_ALL_REFERRALS,
            onError: actions.DOWNLOAD_GET_ALL_REFERRALS_FAILED,
        },
    }
}

export function getUnfilteredReferrals(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReferrals,
            method: "post",
            data: params,
            onStart: actions.GET_UNFILTERED_REFERRALS_START,
            onSuccess: actions.GET_UNFILTERED_REFERRALS,
            onError: actions.GET_UNFILTERED_REFERRALS_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getCompletedReferrals(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReferrals,
            method: "post",
            data: params,
            onStart: actions.GET_COMPLETED_REFERRALS_START,
            onSuccess: actions.GET_COMPLETED_REFERRALS,
            onError: actions.GET_COMPLETED_REFERRALS_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getPendingReferrals(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReferrals,
            method: "post",
            data: params,
            onStart: actions.GET_PENDING_REFERRALS_START,
            onSuccess: actions.GET_PENDING_REFERRALS,
            onError: actions.GET_PENDING_REFERRALS_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function resendInvite(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiResendReferralsInvite + id,
            method: "post",
            data: {},
            onStart: actions.REFERRALS_RESEND_INVITE_START,
            onSuccess: actions.REFERRALS_RESEND_INVITE,
            onError: actions.REFERRALS_RESEND_INVITE_FAILED,
        },
    }
}
