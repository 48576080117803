import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../common/form";
import FormDrawer from "../common/form-drawer--new";
import { toggleLoanRejectionConfirmation, bulkUpdateCreditLimit } from "store/entities/credit/action";
import { encryptPayload } from "utils/encrypt_decrypt";


const LoanRejectionConfirmation = () => {
	
	const dispatch = useDispatch();
	const [reason, setReason] = useState("");

	const { bulkUpdateCreditLimitLoading: loading, creditLimitIds, isRejectionConfirmationOpen, bulkUpdateCreditLimitPayload } = useSelector((state) => state.entities.credit);

	const toggleFn = () => dispatch(toggleLoanRejectionConfirmation());

	const onProceed = () => { 
		const payload = {
			limitIds: creditLimitIds,
			limitStatus: "rejected",
			note: reason
		}
		const encryptedPayload = encryptPayload(payload);
		dispatch(bulkUpdateCreditLimit(encryptedPayload))
	}

	useEffect(() => {
		if (bulkUpdateCreditLimitPayload?.data?.unresolvedLimits?.length === 0 && isRejectionConfirmationOpen) {
			toggleFn();
		}
	}, [bulkUpdateCreditLimitPayload]);
	
	return (
		<FormDrawer display="center" size="md" isOpen={isRejectionConfirmationOpen} setIsOpen={() => {}}>
			<div className="p-5 max-w-md">
				<h1 className="lg:text-lg font-semibold">Reject Loan Limit</h1>
				
				<div className="form-group mt-8">
					<label>Rejection Reasons</label>

					<textarea 
						className="form-input"
						placeholder="Enter Reasons"
						style={{ height: "120px", resize: "none" }}
						value={reason} onChange={(e) => setReason(e.target.value)}
					/>
				</div>
				
				<div className="flex items-center justify-center lg:flex-row pb-2">
					
					<button
						onClick={toggleFn}
						className="btn btn-md border border-ep-primary-500 text-ep-primary-500 focus:ring-0 w-full lg:w-2/4 mr-4"
						disabled={loading}>
						Cancel
					</button>
					
					<ButtonLoader
						loading={loading}
						disabled={reason === ""}
						onClick={onProceed}
						className="z-5 btn btn-md border bg-red-500 text-white focus:ring-0 w-full lg:w-2/4">
						{loading ? "Submitting..." : "Reject"}
					</ButtonLoader>
				</div>
			</div>
		</FormDrawer>
	)
}

export default LoanRejectionConfirmation