import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// import useLoginInfo from "hooks/useLoginInfo";
import { DateMiniFormat } from "utils/dateFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "components/__new/common/Table";
import { Menu } from "@headlessui/react";
import processParams from "utils/processParams";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import useUrlNavigation from "hooks/useUrlNavigation";
import { TableContent } from "components/__new/common/TableContent";
import DashboardBox from "components/__new/common/DashboardBox";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { ReactComponent as IconPlus } from "assets/images/icons/project-icons/Plus-bordered.svg";
import SearchFilter from "components/common/filter";
import DownloadAsCSV from "components/__new/common/DownloadAsCSV";
import Pagination from "components/common/pagination";
import { toast } from 'react-toastify';
import { renderStatusColor } from "utils/statusColor";
import DialogBox from 'utils/dialogBox';
import FormDrawer from "components/__new/common/form-drawer";
import ModalDetailsContent from "components/__new/common/ModalDetailsContent";
import * as referralsActions from "store/entities/referrals/action";


const List = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    // const {userInfo} = useLoginInfo();
    const {urlQueryParams} = useUrlQueryParams();
    const {urlQuery} = useUrlNavigation();
    const { referralsLoading, referrals: {referrals}, referralsDownloadLoading, referralsDownload,
    referralsUnfilteredLoading, referralsUnfiltered, referralsCompletedLoading, referralsCompleted, referralsPendingLoading, referralsPending } = useSelector((s) => s.entities.referrals);
    
    const downloadAsCSVRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [referral, setReferral] = useState({});
    const [listParams, setListParams] = useState({
        page: 1,
        limit: 20,
        status: "",
    });


    const getReferrals = () => {
        let params = {...listParams};

        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 20, "limit");
        params = processParams(urlQueryParams, params, "status", "string", "", "status");
        params = processParams(urlQueryParams, params, "search", "string", "", "keyword");
        params = processParams(urlQueryParams, params, "from", "string", "", "from");
        params = processParams(urlQueryParams, params, "to", "string", "", "to");
        
        setListParams(params);
        dispatch(referralsActions.getReferrals(params));

        dispatch(referralsActions.getUnfilteredReferrals({...params, status: ""}));
        dispatch(referralsActions.getPendingReferrals({...params, status: "pending"}));
        dispatch(referralsActions.getCompletedReferrals({...params, status: "completed"}));
    }


    const viewDetails = (referral) => {
        setReferral(referral);
        setIsOpen(true);
    }
    

    const resendInvite = async (referral) => {
        const {_id, managersName} = referral;
        const result = await DialogBox();
        if (result) {
            dispatch(referralsActions.resendInvite(_id));
            toast("Resending invite to " + managersName);
            setIsOpen(false);
        }
    }


    useEffect(() => {
        getReferrals();
        // eslint-disable-next-line
    }, [location]);
    

    customizeSVG();


    return (
        <div>

            <ModalDetails
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                referral={referral}
                resendInvite={resendInvite}
            />

            <div>
                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title capitalize">
                            Referrals
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        <div>
                            <DownloadAsCSV
                                ref={downloadAsCSVRef}
                                tooltip="Download referrals list"
                                filename="All Referrals.csv"
                                columns={[
                                    { label: "Date Referred", key: "createdAt" },
                                    { label: "Company Referred", key: "companyName" },
                                    { label: "Manager's Name", key: "managersName" },
                                    { label: "Manager's Email Address", key: "managersEmail" },
                                    { label: "Referred By (Name)", key: "name" },
                                    { label: "Referred By (Email Address)", key: "email" },
                                    { label: "Status", key: "status" },
                                ]}
                                click={() => {
                                    dispatch(referralsActions.downloadReferrals({...listParams, page: 1, limit: 10000}))
                                    .then(() => {
                                        downloadAsCSVRef.current?.downloadData();
                                    });
                                }}
                                loading={referralsDownloadLoading}
                                response={referralsDownload?.referrals}
                            />
                        </div>
                        <div>
                            
                            <SearchFilter
                                filters={[
                                    {
                                        title: "Status",
                                        name: "status",
                                        dataType: "radio",
                                        options: [
                                            {
                                                display: "All",
                                                payload: "",
                                            },
                                            {
                                                display: "Completed",
                                                payload: "completed",
                                            },
                                            {
                                                display: "Pending",
                                                payload: "pending",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Date Referred",
                                        dataType: "date-range",
                                        options: {
                                            from: {
                                                name: "from",
                                                placeholder: "Start Date",
                                            },
                                            to: {
                                                name: "to",
                                                placeholder: "End Date",
                                            },
                                        },
                                    },
                                ]}
                            />
                            
                        </div>
                    </div>
                </div>


                <div className="mt-6">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        <DashboardBox
                            title="Number of Referrals"
                            value={referralsUnfiltered?.referrals?.totalDocs}
                            loading={referralsUnfilteredLoading}
                            icon={<IconPlus className="customizeSVG text-blue-600 m-auto w-7 h-7" />}
                            iconBackground="bg-blue-200"
                        />
                        <DashboardBox
                            title="Enrolled Referrals"
                            value={referralsCompleted?.referrals?.totalDocs}
                            loading={referralsCompletedLoading}
                            icon={<IconPlus className="customizeSVG text-green-600 m-auto w-7 h-7" />}
                            iconBackground="bg-green-200"
                        />
                        <DashboardBox
                            title="Unenrolled Referrals"
                            value={referralsPending?.referrals?.totalDocs}
                            loading={referralsPendingLoading}
                            icon={<IconPlus className="customizeSVG text-yellow-600 m-auto w-7 h-7" />}
                            iconBackground="bg-yellow-200"
                        />
                    </div>
                </div>


                <div className="mt-6 w-full lg:w-auto inline-block box-border-only overflow-auto">
                    <div className="h-10 flex">
                        
                        <Link to={urlQuery("status=")} className={"cursor-pointer page-nav " + ((listParams.status === "") ? "active" : "")}>
                            <div className="whitespace-nowrap">
                                All Referrals
                            </div>
                        </Link>
                        <Link to={urlQuery("status=completed")} className={"cursor-pointer page-nav " + ((listParams.status === "completed") ? "active" : "")}>
                            <div className="whitespace-nowrap">
                                Enrolled Referrals
                            </div>
                        </Link>
                        <Link to={urlQuery("status=pending")} className={"cursor-pointer page-nav " + ((listParams.status === "pending") ? "active" : "")}>
                            <div className="whitespace-nowrap">
                                Unenrolled Referrals
                            </div>
                        </Link>

                    </div>
                </div>


                <div className="mt-2">
                    <div className="">

                        <Table>
                            <Table.Head>
                                <th>Date Referred</th>
                                <th>Referred Employer</th>
                                <th>Employer Email (contact)</th>
                                <th>Employee Name (referrer)</th>
                                <th>Employee Email (referrer)</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </Table.Head>
                            <Table.Body>

                                <TableContent
                                    loading={referralsLoading}
                                    loadingText="Loading referrals"
                                    data={referrals}
                                    colspan={7}
                                />

                                {((referralsLoading === false) && (referrals?.docs) && (referrals?.docs.length > 0)) && referrals?.docs.map((referral, index) =>
                                    <tr key={index}>
                                        <td>
                                            <div>
                                                {DateMiniFormat(referral.createdAt)}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="font-bold text-ep-blue whitespace-pre-wrap" style={{"minWidth":"150px"}}>
                                                {referral.companyName}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <Link to={{pathname:"mailto:" + referral.managersEmail}} target="_blank" onClick={(e) => {window.open(e.target.getAttribute("href"), '_blank'); e.preventDefault(); }} className="hover:underline">{referral.managersEmail}</Link>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {referral.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <Link to={{pathname:"mailto:" + referral.email}} target="_blank" onClick={(e) => {window.open(e.target.getAttribute("href"), '_blank'); e.preventDefault(); }} className="hover:underline">{referral.email}</Link>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={"label uppercase " + renderStatusColor(referral.status)}>
                                                {referral.status}
                                            </div>
                                        </td>
                                        <td>
                                            <Menu>
                                                <Menu.Button className="w-7 h-7 rounded-sm flex bg-white hover:bg-gray-200">
                                                    <FontAwesomeIcon icon="ellipsis-h" className="m-auto" />
                                                </Menu.Button>
                                                <Menu.Items className="dropdown">
                                                    <Menu.Item>
                                                        <div onClick={() => {viewDetails(referral)}} className="dropdown-item flex">
                                                            View details
                                                        </div>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <div onClick={() => {resendInvite(referral)}} className="dropdown-item flex">
                                                            Resend Invite
                                                        </div>
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Menu>
                                        </td>
                                    </tr>
                                )}

                            </Table.Body>
                        </Table>

                        {((referralsLoading === false) && (referrals?.docs) && (referrals?.docs.length > 0)) && 
                            <div>
                                <Pagination data={referrals} limit={listParams.limit} />
                            </div>
                        }

                    </div>
                </div>


            </div>
            
        </div>
    )
}


export const ModalDetails = (props) => {
    const { referral, isOpen, setIsOpen, resendInvite } = props;

    return (
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
            <div>
                <div className="p-6">
                    <div className="flex justify-between">
                        <div className="font-recoleta font-semibold text-2xl">
                            Referral Details
                        </div>
                        <div onClick={() => setIsOpen(false)} className="modal-close group">
                            <FontAwesomeIcon icon="times" className="customizeSVG group-hover:text-white m-auto" />
                        </div>
                    </div>
                    <div className="text-fade text-sm">
                        {referral.companyName}
                    </div>
                    <div className="mt-2 flex space-x-4">
                        <div className={"label uppercase " + renderStatusColor(referral.status)}>
                            {referral.status}
                        </div>
                    </div>
                </div>

                <div className="w-full h-px my-2 bg-gray-200"></div>

                <div className="p-6 flex flex-row flex-wrap">

                    <div className="w-full mb-6 font-semibold text-sm">
                        Contact Person (Employer)
                    </div>

                    <ModalDetailsContent label="Name" value={referral.managersName} />
                    <ModalDetailsContent label="Company" value={referral.companyName} />
                    <ModalDetailsContent label="Email" value={<Link to={{pathname:"mailto:" + referral.managersEmail}} target="_blank" onClick={(e) => {window.open(e.target.getAttribute("href"), '_blank'); e.preventDefault(); }} className="hover:underline">{referral.managersEmail}</Link>} />
                    
                    <div className="w-full h-px mt-4 mb-8 bg-gray-200"></div>

                    <div className="w-full mb-6 font-semibold text-sm">
                        Employee (Referrer)
                    </div>

                    <ModalDetailsContent label="Name" value={referral.name} />
                    <ModalDetailsContent label="Email" value={<Link to={{pathname:"mailto:" + referral.email}} target="_blank" onClick={(e) => {window.open(e.target.getAttribute("href"), '_blank'); e.preventDefault(); }} className="hover:underline">{referral.email}</Link>} />
                    
                    <div className="w-full h-px mt-4 bg-gray-200"></div>

                </div>

                <div className="px-6 mt-2 flex sm:justify-end space-x-2">
                    <Link to={{pathname:"mailto:" + referral.managersEmail}} target="_blank" onClick={(e) => {window.open(e.target.getAttribute("href"), '_blank'); e.preventDefault(); }} className="btn btn-md btn-transparent-black">
                        Follow Up Email
                    </Link>
                    <button type="button" onClick={() => resendInvite(referral)} className="btn btn-md btn-ep-blue">
                        Resend Invite
                    </button>
                </div>
            </div>
        </FormDrawer>
    )
}

    
    

export default List;