import React from 'react'
import Maybe from '../common/Maybe'
import { Link } from 'react-router-dom'
import { ReactComponent as IconDownloadAlt } from "assets/images/icons/project-icons/download-alt.svg";


const AddressVerificationView = ({isAddressValidationManual, kycIsPdf, PdfPlaceholder, openKycDocument, toggleManualAddressValidation, toggleAddressReject, Upload, displayDetails, user, detailKeyClass, detailValueClass}) => {
    return (
        <div className='w-full'>
            <Maybe condition={(user?.kycAddressVerificationFile?.length > 0 || !user?.kycAddressVerificationFile)}>
                <div className="w-full h-px mt-8 bg-gray-200"></div>
                <div className="font-semibold text-sm mt-10">Address Verification</div>
            </Maybe>
            
            <Maybe condition={(user?.kycAddressVerificationFile?.length > 0 || !user?.kycAddressVerificationFile) && isAddressValidationManual === false}>
                <div className="mt-5 grid grid-cols-2 gap-x-4">
                    <div className="space-y-5">
                    <div className="w-full mb-2">
                        <div className="my-auto">
                            <div className={detailKeyClass}>Document Type</div>
                            <div className={detailValueClass}>
                                {displayDetails(user?.kycProofOfAddressType)}
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="space-y-5">
                        <div className="my-auto">
                            <div className={detailKeyClass}>Address</div>
                            <div className={detailValueClass}>
                                {displayDetails(user?.address)}
                            </div>
                        </div>
                    </div>
                </div>
                
                <Maybe condition={user?.kycAddressVerificationFile?.length > 0}>
                    <div className="w-4/5 border-2 border-[##C7C7C7] mt-8 rounded-lg">
                        <div className="w-full h-[120px]">
                            <Maybe condition={kycIsPdf === true}>
                                <PdfPlaceholder alt="Address Verification Document" className="w-full max-h-full object-cover" />
                            </Maybe>
                            
                            <Maybe condition={kycIsPdf === false}>
                                <img src={user?.kycAddressVerificationFile} alt="Address Verification Document" className="w-full max-h-full object-cover" />
                            </Maybe>
                        </div>
                        <div className="p-4 bg-white w-full flex items-center justify-center">
                            <button disabled={kycIsPdf === true} onClick={() => openKycDocument(user?.kycAddressVerificationFile)} className={`btn btn-md btn-transparent-primary rounded-lg mr-3 ${kycIsPdf && "cursor-not-allowed bg-gray-200"}`}>
                                View Document
                            </button>
                            
                            <Link to={{ pathname: user?.kycAddressVerificationFile }} target="_blank" className="btn btn-md btn-transparent-primary rounded-lg flex items-start">
                                Download
                                <span className="ml-1">
                                    <IconDownloadAlt width={15} />
                                </span>
                            </Link>
                        </div>
                    </div>
                </Maybe>
                
                <Maybe condition={user?.accountTier !== 3}>
                    <div className="mt-7 pb-5 grid grid-cols-2 gap-x-4">
                        <Maybe condition={user?.kycAddressVerificationFile?.length > 0}>
                            <button onClick={toggleAddressReject} className="btn btn-md border border-ep-danger text-ep-danger rounded-[4px] w-3/5">
                                Reject
                            </button>
                        </Maybe>
                        
                        <button onClick={toggleManualAddressValidation} className="btn btn-md btn-transparent-primary rounded-[4px] flex items-center whitespace-nowrap">
                            Manual Upload<span className="ml-2"><Upload width={15} /></span>
                        </button>
                    </div>
                </Maybe>
            </Maybe>
        </div>
    )
}

export default AddressVerificationView